import { inject, Navigator } from "fw";
import { DialogController } from "fw-dialog";
import { dispatch } from "fw-state";

import { LogoutAction } from "state/actions";

@inject
export class Unauthorized {
  constructor(private controller: DialogController<void>, private nav: Navigator) {}

  login() {
    dispatch(new LogoutAction());
    this.nav.navigate("/login");
    this.controller.close();
  }
}
