import { inject, Route, Navigator } from "fw";

import { CurrentUserStore } from "state/current-user";
import { PrincipalType } from "models/principal";

@inject
export class RequireGlobal {
  constructor(private userStore: CurrentUserStore, private nav: Navigator) {}

  navigating(r: Route, fullRoute: string) {
    const requiresGlobalAuth = r.data && r.data.global;
    const requiresGlobalAdminAuth = r.data && r.data.globalAdmin;

    const { loggedIn, isGlobalPrincipal, principal } = this.userStore.state;

    if (
      (requiresGlobalAuth && (!loggedIn || !isGlobalPrincipal))
      || (requiresGlobalAdminAuth && (!loggedIn || principal.Type != PrincipalType.GlobalAdmin))
    ) {
      this.nav.navigate("/login", { redirect: fullRoute });
      return false;
    }

    return true;
  }
}
