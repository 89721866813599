import { inject, prop, ComponentEventBus } from "@derekpitt/fw";

import { Question, FormAnswer } from "../../models";
import { calcColumnWidth } from "../../helpers";

@inject
export class TableType {
  @prop(null)
  question: Question;

  @prop(null)
  answer: FormAnswer;

  @prop(false)
  readonly: boolean;

  @prop(false)
  displayonly: boolean;

  @prop(null)
  ariaLabelledBy;

  private didInit = false;
  private columns: { label: string; percentWidth: string }[] = [];
  private answers: { answer: string }[][] = [];

  constructor(private ecb: ComponentEventBus) {}

  attached() {
    const options = this.question.Options.Table;

    this.answer.Rows = this.answer.Rows || [];

    this.mapAnswers();

    const frontPercent = options.RowNames.length > 0 ? 25 : 0;

    options.Columns.forEach(c => {
      this.columns.push({
        label: c.Label,
        percentWidth: calcColumnWidth(options, c.Width, frontPercent),
      });
    });

    this.didInit = true;
  }

  mapAnswers() {
    if (this.answer != null && this.answer.Rows != null) {
      this.answers = this.answer.Rows.map(a => a.map(a2 => ({ answer: a2 })));
    } else {
      this.answers = [];
    }

    const options = this.question.Options.Table;

    if (options.RowNames.length > 0) {
      for (let i = this.answers.length; i < options.RowNames.length; i++) {
        this.addRow(false);
      }
    } else {
      for (
        let i = this.answers.length;
        i < Math.max(options.MinRowCount, 1);
        i++
      ) {
        this.addRow(false);
      }
    }
  }

  answerChanged() {
    this.didInit = false;
    this.mapAnswers();
    this.didInit = true;
  }

  get canDelete() {
    if (this.question == null) return false;
    const options = this.question.Options.Table;
    return this.answers.length > Math.max(options.MinRowCount, 1);
  }

  get canAdd() {
    return (
      !this.readonly &&
      this.didInit &&
      this.answers != null &&
      this.answers.length < this.question.Options.Table.MaxRowCount &&
      this.question.Options.Table.RowNames.length == 0
    );
  }

  change() {
    this.answer.Rows = this.answers.map(a => a.map(a2 => a2.answer));
    this.ecb.dispatch("answer-changed");
  }

  addRow(change = true) {
    const options = this.question.Options.Table;
    this.answers.push(options.Columns.map(c => ({ answer: "" })));

    if (change) this.change();
  }

  removeRow(idx: number) {
    this.answers.splice(idx, 1);
    this.change();
  }

  ordinalize(num: number) {
    const suffix = ["th","st","nd","rd"][Math.abs(~[1,2,3].indexOf(+(+num).toFixed().substr(-1)))];
    return num + suffix;
  }

  cellLabel(rowIndex: number, colIndex: number) {
    return `${this.ordinalize(rowIndex + 1)} row. ${this.columns[colIndex].label}`;
  }
}
