import { inject } from "fw";
import { createFrom, createFromArray, field } from "fw-model";

import { Operation, compare } from "fast-json-patch";

import {
  ATS,
  ICountResult,
  IEntitySelection,
  IListResults
} from "./ats";
import {
  Contact,
  IDuplicateContactResult,
  DuplicateContactResult,
  IAttachmentPolicyResult,
  ContactMetaData,
  DecryptedValue,
  UnrestrictedContact
} from "models/contact";

import {
  EntitySelectionPatches
} from "models/application-client-model"

export interface AddNewContactPostArgs {
  type: string;
  email_address: string;
  first_name?: string;
  last_name?: string;
  company_name?: string;
}

@inject
export class ContactRepository {
  constructor(private s: ATS) { }

  public async list(q: string = null, f: string = null, aggs: string = null, sort: string = null, page = 1, limit = 10, contactType: string = null
    ) : Promise<IListResults<Contact>>
  {
    return await (contactType?.length > 0
      ? this.s.contacts.list(`contacts/type/${contactType}`, Contact, q, f, aggs, sort, page, limit)
      : this.s.contacts.list("contacts", Contact, q, f, aggs, sort, page, limit));
  }

  public async getClientModels(q: string = null, f: string = null, sort: string = null, page = 1, limit = 10, contactType: string = null
    ) : Promise<any>
  {
    const result = await (contactType?.length > 0
      ? this.s.contacts.get(`contacts/${contactType}/client-models`, { q, f, sort, page, limit })
      : this.s.contacts.get("contacts/client-models", { q, f, sort, page, limit }));
    return result.body
  }

  public async getClientModelById(id: string) : Promise<any> {
    const result = await this.s.contacts.get(`contacts/client-models/${id}`);
    return result.body
  }

  public async count(selection: IEntitySelection = null, f: string = null, aggs: string = null, contactType: string = null): Promise<ICountResult> {
    if (selection){
      const res =await this.s.contacts.post<ICountResult>("contacts/count", selection, {aggs});
      return res.body;
    }

    return await (contactType?.length > 0
      ? this.s.contacts.count(`contacts/${contactType}/count`, f, aggs)
      : this.s.contacts.count("contacts/count", f, aggs));
  }

  public async getById(id: string): Promise<Contact> {
    const res = await this.s.contacts.get<Contact>(`contacts/${id}`);
    return createFrom(Contact, res.body);
  }

  public async patch(id: string, version: string, data: Operation[]): Promise<Contact> {
    const res = await this.s.contacts.patch<Contact>(`contacts/${id}`, data, { version: version });
    return createFrom(Contact, res.body);
  }

  public async patchWithRetries(contact: Contact, data: Operation[]): Promise<Contact> {
    let attempts: number = 0;
    let version: string = contact.version;
    while (attempts++ < 3) {
      console.log(`patching contact properties... Attempt: ${attempts}`, data);
      try {
        // TODO: Get smarter about this when we get access to response status codes.
        var result = await this.patch(contact.id, version, data);
        if (!result.id) {
          throw new Error("Error while patching contact.");
        }

        return result;
      } catch (err) {
        console.log("Error patching:", err);
      }

      const latest: Contact = await this.getById(contact.id);
      if (contact.version === latest.version) {
        throw new Error("Error while patching contact.");
      }

      const potentialConflictedOperations = compare({ value: contact }, { value: latest });
      potentialConflictedOperations.forEach(o => {
        let potentialConflictedPath = o.path.replace("/value", "");
        if (data.find(o => o.path === potentialConflictedPath)) {
          throw new Error("There was a version conflict when updating the contact, must reload.");
        }
      });

      console.log(`Updating contact version to ${latest.version}`, potentialConflictedOperations);
      version = latest.version;
      console.log("Version conflict but with a different property, retrying with version:", version);
    }
  }

  public async bulkEdit(selectionPatches: EntitySelectionPatches) {
    if (selectionPatches == null) {
      return;
    }
    return await this.s.contacts.post<void>("contacts/bulk", selectionPatches);
  }

  public async removeManualEntry(id: string, fieldId: string): Promise<Contact> {
    const res = await this.s.contacts.delete<Contact>(`contacts/${id}/fields/${fieldId}/manual-entry`);
    return createFrom(Contact, res.body);
  }

  public async post(args: AddNewContactPostArgs) {
    const res = await this.s.contacts.post<Contact>('contacts', args);
    return createFrom(Contact, res.body);
  }

  public async selectUnrestricted(selection: IEntitySelection, limit: number = 20) : Promise<IListResults<UnrestrictedContact>> {
    if (selection == null) {
      return;
    }
    const res = await this.s.contacts.post<UnrestrictedContact[]>(`contacts/select-unrestricted`, selection, {
      limit: limit
    });
    return { results: createFromArray(UnrestrictedContact, res.body), total: parseInt(res.headers["x-result-count"], 10) };
  }

  public async deleteSelection(selection: IEntitySelection) {
    if (selection == null) {
      return;
    }
    return await this.s.contacts.post<void>("contacts/delete", selection);
  }

  public async mergeSelection(selection: IEntitySelection) {
    if (selection == null) {
      return;
    }
    return await this.s.contacts.post<void>("contacts/merge", selection);
  }

  public async listDuplicates(contactType: string): Promise<IDuplicateContactResult[]> {
    const res = await this.s.contacts.get<IDuplicateContactResult[]>(`contacts/${contactType}/find-duplicates`, DuplicateContactResult);
    return res.body;
  }

  public async undoDelete(selection: IEntitySelection) {
    return await this.s.contacts.post<void>('contacts/undelete', selection);
  }

  public async attachmentPolicy(contactId: string, attachmentId?: string) {
    if (attachmentId) {
      const res = await this.s.contacts.get<IAttachmentPolicyResult>(`contacts/${contactId}/attachment/${attachmentId}/policy`);
      return res.body;
    }

    const res = await this.s.contacts.get<IAttachmentPolicyResult>(`contacts/${contactId}/attachment/policy`);
    return res.body;
  }

  public async getMetaByContactId(id: string): Promise<ContactMetaData> {
    const res = await this.s.contacts.get<ContactMetaData>(`contacts/${id}/meta`);
    return createFrom(ContactMetaData, res.body);
  }


  public async postAttachmentField(id: string, fieldName: string, files: any) {
    await this.s.contacts.post<any>(`contacts/${id}/attachment/${fieldName}`, files);
  }

  public async getAttachmentResult(id: string, fieldName:string, fullDetails: boolean = false): Promise<{ rendered: string, raw: string }> {
    const res = await this.s.contacts.get<{ rendered: string, raw: string }>(`contacts/${id}/attachments/${fieldName}/result`, { fullDetails });
    return res.body;
  }

  public async getAttachment(id: string, fieldName:string): Promise<any> {
    const res = await this.s.contacts.get<any>(`contacts/${id}/attachments/${fieldName}`);
    return res.body;
  }

  public async getRenderedAttachment(id: string, fieldName:string): Promise<string> {
    const res = await this.s.contacts.get<string>(`contacts/${id}/attachments/${fieldName}/rendered`);
    return res.body;
  }

  public async decrypt(contactId: string, fieldId: string, contactMetaValueId?: string): Promise<DecryptedValue> {
    const url: string = contactMetaValueId
      ? `contacts/${contactId}/field/${fieldId}/${contactMetaValueId}/decrypt`
      : `contacts/${contactId}/field/${fieldId}/decrypt`;
    const res = await this.s.contacts.get<DecryptedValue>(url);
    return createFrom(DecryptedValue, res.body);
  }

  public async reindex(organizationId: string) {
    return await this.s.contacts.post<void>(`organizations/${organizationId}/recalculate`, null);
  }

  public async recalculateNestedSearchDocuments(organizationId: string) {
    return await this.s.contacts.get(`organizations/${organizationId}/recalculate-nested-search`);
  }

  public async deleteCustomFieldValue(contactId: string, fieldName: string) {
    const res = await this.s.contacts.delete(`contacts/${contactId}/data/${fieldName}`);
    return createFrom(Contact, res.body);
  }

  public async postProfilePicture(id: string, file: File): Promise<Contact> {
    const formData = new FormData();
    formData.append('file', file);
    const res = await this.s.contacts.post<Contact>(`contacts/${id}/profile-image`, formData);
    return createFrom(Contact, res.body);
  }

  public async deleteProfilePicture(id: string): Promise<Contact> {
    const res = await this.s.contacts.delete<Contact>(`contacts/${id}/profile-image`);
    return createFrom(Contact, res.body);
  }

  public async delete(id: string): Promise<Contact> {
    const res = await this.s.contacts.delete<Contact>(`contacts/${id}`);
    return createFrom(Contact, res.body);
  }
}