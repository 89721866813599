import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { EntitySelection } from "models/application-client-model";
import { CalendarEventInstanceStats } from "models/calendar-event";
import {
  CalendarEventRegistration,
  CalendarEventRegistrationStatus,
  ContactRegistrationResponse
} from "models/calendar-event-registration";
import { UnrestrictedContact } from "models/contact";
import { InquiryResponse } from "models/inquiry-response";
import { ATS, IListResults, getParams } from "network/ats";

@inject
export class CalendarEventRegistrationRepository {
  constructor(private network: ATS) { }

  async post(
    calendarEventSeriesId: string,
    instanceKey: string,
    contactId: string,
    guestCount: number,
    response: InquiryResponse,
    seenQuestionKeys: string[] = null
  ) {
    const args = {
      contact_id: contactId,
      guest_count: guestCount,
      registration_response: response,
      seen_question_keys: seenQuestionKeys
    }
    var path = `calendar-event-registrations/series/${calendarEventSeriesId}/instance/${instanceKey}`;
    const res = await this.network.contacts.post<ContactRegistrationResponse>(path, args);
    return createFrom(ContactRegistrationResponse, res.body);
  }

  async listByEvent(
    calendarEventSeriesId: string,
    instanceKey: string = null,
    status: CalendarEventRegistrationStatus = null,
    q: string = null,
    f: string = null,
    aggs: string = null,
    sort: string = null,
    page: number = 1,
    limit: number = 10,
  ): Promise<IListResults<CalendarEventRegistration>> {
    const params = getParams(q, f, aggs, sort, page, limit);
    if (instanceKey != null) params.instance = instanceKey;
    if (status != null) params.status = status;

    const path = `calendar-event-registrations/series/${calendarEventSeriesId}`;
    const res = await this.network.contacts.get<IListResults<CalendarEventRegistration>>(path, params);

    const results: IListResults<CalendarEventRegistration> = {
      aggregations: res.body.aggregations,
      results: createFromArray(CalendarEventRegistration, res.body.results),
      total: parseInt(res.headers["x-result-count"], 10),
    };
    return results;
  }

  async get(id: string) {
    const res = await this.network.contacts.get<CalendarEventRegistration>(`calendar-event-registrations/${id}`);
    return createFrom(CalendarEventRegistration, res.body);
  }

  async getByContactId(contactId: string, pageSize: number): Promise<IListResults<CalendarEventRegistration>> {
    let params = {
      f: `contact_id:${contactId}`,
      limit: pageSize
    };
    const res = await this.network.contacts.get<IListResults<CalendarEventRegistration>>(`calendar-event-registrations`, params);

    const results: IListResults<CalendarEventRegistration> = {
      aggregations: res.body.aggregations,
      results: createFromArray(CalendarEventRegistration, res.body.results),
      total: parseInt(res.headers["x-result-count"], 10),
    };

    return results;
  }

  async getByToken(token: string) {
    const res = await this.network.contacts.get<CalendarEventRegistration>(`calendar-event-registrations/token/${token}`);
    return createFrom(CalendarEventRegistration, res.body);
  }

  async getExcludedContacts(
    seriesId: string,
    instanceKey: string,
    statuses: CalendarEventRegistrationStatus[],
    q: string = null,
    sort: string = null,
    limit: number = 20,
    before: string = null,
    after: string = null
  ): Promise<IListResults<UnrestrictedContact>> {
    const res = await this.network.contacts.get<UnrestrictedContact[]>(
      `calendar-event-registrations/series/${seriesId}/instance/${instanceKey}/excluded-contacts`,
      { statuses, q, sort, limit, before, after }
    );
    return { results: createFromArray(UnrestrictedContact, res.body), total: parseInt(res.headers["x-result-count"], 10) };
  }

  async updateStatus(seriesId: string, instanceKey: string, status: CalendarEventRegistrationStatus, contactId: string) {
    const path = `calendar-event-registrations/series/${seriesId}/instance/${instanceKey}/set-status/${status}`;
    const res = await this.network.contacts.put<CalendarEventRegistration>(path, null, { contactId });

    return res.body;
  }

  async updateStatusBulk(seriesId: string, instanceKey: string, status: CalendarEventRegistrationStatus, contactIds: string[]) {
    const path = `calendar-event-registrations/series/${seriesId}/instance/${instanceKey}/bulk-set-status/${status}`;
    const selection = new EntitySelection();
    selection.ids = contactIds.length === 1 && contactIds[0] === 'all' ? [] : contactIds;

    return await this.network.contacts.put(path, selection);
  }

  async getInstanceCounts(seriesId: string, instanceKey: string) {
    const path =`calendar-event-registrations/series/${seriesId}/instance/${instanceKey}/counts`;
    const res = await this.network.contacts.get<CalendarEventInstanceStats>(path);

    return res.body;
  }
}
