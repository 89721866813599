import { inject, prop } from "fw";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { TimingFilterType } from "models/filter-timing";
import { DateFilter as DateFilterModel } from "models/date-filter";
import { CurrentOrganizationStore } from "state/current-organization";
import moment from "moment";
import "moment-timezone";

export type Range = {
  startDate: Date;
  endDate: Date;
}

export type DaysRange = {
  daysFrom: number;
  daysTo: number;
}

export type StringRange = {
  startDate: string;
  endDate: string;
}

export const formatDate = (date: Date) => {
  return moment(date).format("YYYY-MM-DD");
}

type DefaultRange = {
  label: string;
  rangeType?: Array<RangeTypes>;
  value: TimingFilterType;
  getRange?: (formatter: (date: Date) => string, timezone) => string | [string, string];
  timing?: string;
}

export type RangeStamp = {
  timing: TimingFilterType;
  marker: Array<string>,
  range: StringRange
}

export type RangeTypes = "application" | "due-date";

export const DEFAULT_RANGES: DefaultRange[] = [
  {
    label: "Any Dates",
    value: TimingFilterType.Any,
    rangeType: ["application", "due-date"],
    getRange: (formatter) => {
      return null;
    }
  },
  {
    label: "Empty",
    value: TimingFilterType.Empty,
    rangeType: ["application", "due-date"],
    getRange: (formatter) => {
      return null;
    }
  },
  {
    label: "Overdue",
    value: TimingFilterType.Overdue,
    rangeType: ["due-date"],
    getRange: (formatter) => {
      return null;
    }
  },
  {
    label: "Due Today",
    value: TimingFilterType.DueToday,
    rangeType: ["due-date"],
    getRange: (formatter) => {
      return null;
    }
  },
  {
    label: "Due Soon",
    value: TimingFilterType.DueSoon,
    rangeType: ["due-date"],
    getRange: (formatter) => {
      return null;
    }
  },
  {
    label: "Today",
    value: TimingFilterType.Today,
    rangeType: ["application", "due-date"],
    getRange: (formatter, timezone) => {
      const date = new Date();
      const zone = date.toLocaleString("en-US", { timeZone: timezone });
      return [`:[${formatter(new Date(zone))}`, `${formatter(date)}]`]
    }
  },
  {
    label: "Tomorrow",
    value: TimingFilterType.Tomorrow,
    rangeType: ["application", "due-date"],
    getRange: (formatter, timezone) => {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      const zone = date.toLocaleString("en-US", { timeZone: timezone });
      return [`:[${formatter(new Date(zone))}`, `${formatter(date)}]`]
    }
  },
  {
    label: "Yesterday",
    value: TimingFilterType.Yesterday,
    rangeType: ["application", "due-date"],
    getRange: (formatter, timezone) => {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      const zone = date.toLocaleString("en-US", { timeZone: timezone });
      return [`:[${formatter(new Date(zone))}`, `${formatter(date)}]`]
    }
  },
  {
    label: "Last 7 days",
    value: TimingFilterType.Last7Days,
    rangeType: ["application", "due-date"],
    getRange: (formatter, timezone) => {
      const date = new Date();
      date.setDate(date.getDate() - 7);

      const currentDate = new Date();

      const startOfDay = new Date(date.getTime());
      startOfDay.setHours(0, 0, 0, 0);
      const startZone = startOfDay.toLocaleString("en-US", { timeZone: timezone });

      const endOfDay = new Date(currentDate.getTime());
      endOfDay.setHours(23, 59, 59, 999);
      const endZone = endOfDay.toLocaleString("en-US", { timeZone: timezone });

      return [`:[${formatter(new Date(startZone))}`, `${formatter(new Date(endZone))}]`]
    }
  },
  {
    label: "Last X days",
    value: TimingFilterType.LastXDays,
    rangeType: ["application", "due-date"],
  },
  {
    label: "Last X days",
    value: TimingFilterType.LastX,
    rangeType: ["application", "due-date"],
  },
  {
    label: "This month",
    value: TimingFilterType.Month,
    rangeType: ["application", "due-date"],
    getRange: (formatter, timezone) => {
      const currentDate = new Date();
      const dateStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

      const startOfDay = new Date(dateStart.getTime());
      startOfDay.setHours(0, 0, 0, 0);
      const startZone = startOfDay.toLocaleString("en-US", { timeZone: timezone });

      const endOfDay = new Date(currentDate.getTime());
      endOfDay.setHours(23, 59, 59, 999);
      const endZone = endOfDay.toLocaleString("en-US", { timeZone: timezone });

      return [`:[${formatter(new Date(startZone))}`, `${formatter(new Date(endZone))}]`]
    }
  },
  {
    label: "This year",
    value: TimingFilterType.Year,
    rangeType: ["application", "due-date"],
    getRange: (formatter, timezone) => {
      const currentDate = new Date();
      const dateStart = new Date(currentDate.getFullYear(), 0, 1);

      const startOfDay = new Date(dateStart.getTime());
      startOfDay.setHours(0, 0, 0, 0);
      const startZone = startOfDay.toLocaleString("en-US", { timeZone: timezone });

      const endOfDay = new Date(currentDate.getTime());
      endOfDay.setHours(23, 59, 59, 999);
      const endZone = endOfDay.toLocaleString("en-US", { timeZone: timezone });

      return [`:[${formatter(new Date(startZone))}`, `${formatter(new Date(endZone))}]`]
    }
  },
  {
    label: "Sliding Range",
    rangeType: ["application", "due-date"],
    value: TimingFilterType.DayRange
  },
  {
    label: "Custom dates",
    rangeType: ["application", "due-date"],
    value: TimingFilterType.DateRange
  }
];

const INITIAL_RANGE: Range = {
  startDate: null,
  endDate: null
};

@inject
export class DateRange {
  @prop(() => {}) public callbackFunction: Function;
  @prop(null) filter!: DateFilterModel;
  @prop("right") opens: "center" | "left" | "right" | "inline";
  @prop("application") rangeType!: RangeTypes;

  public range: Range = { ...INITIAL_RANGE };
  public showLastX: boolean = false;
  public showDaysRange: boolean = false;
  private $refs: any;
  private $slots: any;
  private timezone: string = "America/Chicago";
  public timingFilterType = TimingFilterType;

  constructor(
    private currentOrganizationStore: CurrentOrganizationStore
  ) {
    this.timezone = this.currentOrganizationStore.state.organization.Timezone || this.timezone;
  }

  public resetPicker(): void {
    this.$refs.picker.start = null;
    this.$refs.picker.end = null;
  }

  public setPicker({startDate, endDate}: {startDate: string, endDate: string}): void {
    this.$refs.picker.start = new Date(`${startDate}T12:00:00`);
    this.$refs.picker.end = new Date(`${endDate}T12:00:00`);
  }

  private setPredefinedRange(data: any): void {
    this.marker = data;

    switch(data) {
      case TimingFilterType.LastX:
        this.showLastX = true;
        this.showDaysRange = false;
        break;

      case TimingFilterType.DateRange:
        this.showLastX = false;
        this.showDaysRange = true;
        break;

      default:
        this.callbackFunction({
          timing: this.selectedRangeTiming,
          marker: DEFAULT_RANGES.find(i => i.value === data).getRange(formatDate, this.timezone),
        });
        this.$refs.picker.togglePicker(false);
        this.range = { ...INITIAL_RANGE };
        break;
    }
  }

  public updateRanges() {
    // Handle Last X days, Sliding range, Selected range

    switch (this.marker) {
      case TimingFilterType.LastX:
        this.callbackFunction({
          timing: this.selectedRangeTiming,
          marker: this.sinceLastXZoneRange(formatDate, this.timezone, this.filter.sinceDays)
        });
        break;
      case TimingFilterType.DayRange:
        this.callbackFunction({
          timing: this.selectedRangeTiming,
          marker: this.sinceDaysZoneRange(formatDate, this.timezone, this.filter.daysFrom, this.filter.daysTo)
        });
        break;
      default:
        this.callbackFunction({
          timing: this.selectedRangeTiming,
          marker: null,
          range: {
            startDate: this.range?.startDate ? this.formatRangeDate(formatDate, this.range?.startDate, this.timezone) : null,
            endDate: this.range?.endDate ? this.formatRangeDate(formatDate, this.range?.endDate, this.timezone) : null,
          }
        });
        break;
    }

    this.$refs.picker.togglePicker(false);
    this.showDaysRange = false;
    this.showLastX = false;
  }

  private customRange({ startDate, endDate }: Range): void {
    this.range = { startDate, endDate };
    this.marker = TimingFilterType.DateRange;
    this.updateRanges();
  }

  get selectedDefaultRange() {
    return this.marker;
  }
  set selectedDefaultRange(range) {
    this.setPredefinedRange(range);
  }

  public get defaultRangeOptions() {
    return DEFAULT_RANGES
      .map(({ label: text, value }) => ({ text, value }))
      .filter(({ value }) => value !== TimingFilterType.DateRange && value !== TimingFilterType.LastXDays && this.allowedToShowByType(value));
  }

  private handleResize(event) {
    const dateRangePicker = document.querySelector(".daterangepicker.show-calendar.openscenter.show-ranges");

    if (!dateRangePicker) {
      return;
    }

    const dateRangePickerButton = document.querySelector("[data-id=\"filter-date-range\"]");
    const buttonDomRect = dateRangePickerButton.getBoundingClientRect();
    // @ts-ignore
    dateRangePicker.style.top = `${buttonDomRect.top + buttonDomRect.height + window.scrollY}px`;

    if (event.currentTarget.innerWidth > 800) {
      dateRangePicker.classList.remove("correct");
      // @ts-ignore
      dateRangePicker.style.left = `${buttonDomRect.left + buttonDomRect.width / 2}px`;
    } else {
      dateRangePicker.classList.add("correct");
      const dateRangePickerWidth = dateRangePicker.clientWidth;
      const padding = 8;
      // @ts-ignore
      dateRangePicker.style.left = `${dateRangePickerWidth / 2 + padding}px`;
    }
  }

  public onToggle(isOpened) {
    if (isOpened) {
      window.addEventListener("resize", this.handleResize);
      setTimeout(() => window.dispatchEvent(new Event("resize")));
    } else {
      window.removeEventListener("resize", this.handleResize);
    }

    if (isOpened && this.marker === TimingFilterType.DayRange) {
      this.showLastX = false;
      this.showDaysRange = true;
    } else if (isOpened && this.marker === TimingFilterType.LastX) {
      this.showLastX = true;
      this.showDaysRange = false;
    } else {
      this.showLastX = false;
      this.showDaysRange = false;
    }
  }

  public allowedToShowByType(key: TimingFilterType) {
    return DEFAULT_RANGES.find(item => item.value === key)?.rangeType.includes(this.rangeType);
  }

  get hasDefaultSlot() {
    return !!this.$slots.default
  }

  get selectedRangeName(): string {
    return DEFAULT_RANGES.find(item => item.value === this.marker)?.label || "Any Dates";
  }

  get selectedRangeTiming(): string {
    return DEFAULT_RANGES.find(item => item.value === this.marker)?.value;
  }

  get selectedRangeDates(): string {
    return `${this.filter?.startDate} - ${this.filter?.endDate}`;
  }

  get ranges() {
    const ranges = {};

    DEFAULT_RANGES.forEach(({ label, value }) => ranges[label] = value);

    return ranges;
  }

  get selectedRange() {
    return this.marker === TimingFilterType.DateRange && this.filter.startDate ? this.selectedRangeDates : this.selectedRangeName;
  }

  set marker(value) {
    this.filter.timing = value;
  }

  get marker() {
    return this.filter?.timing;
  }

  get showFooter() {
    return this.showLastX || this.showDaysRange;
  }

  get enableUpdateButton() {
    return this.marker === TimingFilterType.LastX && this.filter.sinceDays ||
      this.marker === TimingFilterType.DayRange && this.filter.daysFrom && this.filter.daysTo;
  }

  sinceLastXZoneRange(formatter, timezone, sinceDays) {
    const date = new Date();

    date.setDate(date.getDate() - sinceDays);

    const currentDate = new Date();

    const startOfDay = new Date(date.getTime());
    startOfDay.setHours(0, 0, 0, 0);
    const startZone = startOfDay.toLocaleString("en-US", { timeZone: timezone });

    const endOfDay = new Date(currentDate.getTime());
    endOfDay.setHours(23, 59, 59, 999);
    const endZone = endOfDay.toLocaleString("en-US", { timeZone: timezone });

    return [`:[${formatter(new Date(startZone))}`, `${formatter(new Date(endZone))}]`]
  }

  sinceDaysZoneRange(formatter, timezone, daysFrom, daysTo) {
    const date = new Date();

    date.setDate(date.getDate() - daysTo);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - daysFrom);

    const startOfDay = new Date(date.getTime());
    startOfDay.setHours(0, 0, 0, 0);
    const startZone = startOfDay.toLocaleString("en-US", { timeZone: timezone });

    const endOfDay = new Date(currentDate.getTime());
    endOfDay.setHours(23, 59, 59, 999);
    const endZone = endOfDay.toLocaleString("en-US", { timeZone: timezone });

    return [`:[${formatter(new Date(startZone))}`, `${formatter(new Date(endZone))}]`]
  }

  formatRangeDate(formatter, rangeDate, timezone) {
    const date = new Date(rangeDate);
    const zone = date.toLocaleString("en-US", { timeZone: timezone });
    return formatter(new Date(zone));
  }
}

