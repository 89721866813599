import { fromClass, fromClassArray } from "fw-model";
import { EmailContact } from "./email-contact";
import { TrackConfiguration } from "./organization";

export class SystemConfig {
  @fromClass DefaultSmtpFrom: EmailContact;
  @fromClass DefaultSmtpReplyTo: EmailContact;
  @fromClassArray(TrackConfiguration) CurrentTracks: TrackConfiguration[];
  ChameleonAccountToken: string;
  ContactsAppScope: string;
  ContactsBaseApiUrl: string;
  ExceptionlessApiKey: string;
  ExceptionlessOrganizationPortalApiKey: string;
  HeapAppId: string;
  MarketingBaseApiUrl: string;
  MarketingTrackApiUrl: string;
  MapsApiKey: string;
  FileStackApiKey: string;
  FileSourceManagedS3Bucket: string;
  FileSourceManagedS3BucketRegion: string;
  WebSocketUrl: string;
}
