import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { LoginResult } from "models/misc";

import { User, PostUserArgs } from "models/user";
import { UserSeasonSettings, UserSeasonNotificationSettings } from "models/user-season-settings";
import { PortalType } from "models/app-organization-portal-context";

@inject
export class UserRepository {
  constructor(private s: ATS) {}

  // returns the token
  public async login(args: LoginArgs) {
    const q = this.s.tokenLifespan ? { tokenLifespan: this.s.tokenLifespan } : null;
    const res = await this.s.post<LoginResult>("user/login", args, q);
    this.s.setToken(res.body.BearerToken);
    this.s.deleteImpersonationToken();

    return res.body.BearerToken;
  }

  async impersonate(organizationUserId: string, portalId?: string, isCurrentPortal = true) {
    const q = this.s.tokenLifespan ? { tokenLifespan: this.s.tokenLifespan } : null;
    let url = `user/impersonate/${organizationUserId}`;
    if (portalId) url += `?portalId=${portalId}`;
    const res = await this.s.post<LoginResult>(url, undefined, q);

    if (isCurrentPortal) this.s.setImpersonationToken(res.body.BearerToken);

    return res.body.BearerToken;
  }

  public async switchOrganization(organizationId: string) {
    const q = {
      organizationId,
      tokenLifespan: this.s.tokenLifespan || null,
      PortalType: PortalType.Organization,
    };
    const res = await this.s.get<LoginResult>("user/switch-organization", q);
    this.s.setToken(res.body.BearerToken);

    return res.body.BearerToken;
  }

  public async forgotPassword(EmailAddress: string, OrganizationId: string) {
    await this.s.post("user/request-password-reset", {
      EmailAddress,
      PortalType: PortalType.Organization,
      OrganizationId,
    });
  }

  public async resetPassword(NewPassword: string, Token: string) {
    await this.s.post("user/apply-password-reset", { NewPassword, Token });
  }

  public async refreshToken() {
    const q = this.s.tokenLifespan ? { tokenLifespan: this.s.tokenLifespan } : null;

    // always refresh the main token, even if impersonating
    this.s.setOverrideToken(this.s.token);
    const res = await this.s.get<LoginResult>("user/refresh-token", q);
    this.s.setToken(res.body.BearerToken);

    // if impersonating, refresh impersonation token as well
    if (this.s.impersonationToken != null) {
      this.s.setOverrideToken(this.s.impersonationToken);
      const impersonationRes = await this.s.get<LoginResult>("user/refresh-token", q);
      this.s.setImpersonationToken(impersonationRes.body.BearerToken);
    }
    this.s.setOverrideToken(null);
  }

  public logout() {
    this.s.deleteToken();
    this.s.deleteImpersonationToken();
  }

  async list(f = null, s = null, sort = null, page = 1, pageSize = 20) {
    const res = await this.s.get<any[]>("user", { f, s, sort, page, pageSize });
    return {
      list: createFromArray(User, res.body),
      total: parseInt(res.headers["x-ats-total"], 10),
    };
  }

  async getChameleonVerification() {
    const res = await this.s.get<string>("app/organization-portal/get-chameleon-verification");
    return res.body;
  }

  public async getIds(ids: string[]) {
    const res = await this.s.post<any[]>("user/batch", ids, {
      skipMissing: true,
    });
    return createFromArray(User, res.body);
  }

  public async get(id: string) {
    const res = await this.s.get(`user/${id}`);
    return createFrom(User, res.body);
  }

  public async put(user: User) {
    const q = this.s.tokenLifespan ? { tokenLifespan: this.s.tokenLifespan } : null;
    const res = await this.s.put(`user/${user.Id}`, user, q);
    return createFrom(User, res.body);
  }

  public async post(user: User, token: string) {
    const res = await this.s.post("user", user, { token });
    return createFrom(User, res.body);
  }

  // this will replace the post above when the UsersBeforeInvites FF is done.
  async addUser(args: PostUserArgs, organizationId: string) {
    const res = await this.s.post("user", args, { organizationId });
    return createFrom(User, res.body);
  }

  async putUser(userId: string, seasonId: string, args: PostUserArgs) {
    const res = await this.s.put(`user/${userId}/season/${seasonId}`, args);
    return createFrom(User, res.body);
  }

  public async updatePassword(userId: string, updatePasswordArgs: UpdatePasswordArgs) {
    await this.s.post(`user/${userId}/update-password`, updatePasswordArgs);
  }

  public async updateEmail(userId: string, updateEmailArgs: UpdateEmailArgs) {
    await this.s.post(`user/${userId}/update-email`, updateEmailArgs);
  }

  public async confirmEmailChange(token: string) {
    await this.s.post(`user/confirm-email-change`, null, { token });
  }

  public async putMembership(
    userId: string,
    organizationId: string,
    inviteToken?: string,
    roleId?: string,
    collaboratorRoleIds?: string[],
    maxAssignmentsPerModule?: number,
    isAvailableForAssignment?: boolean,
    applicationMatchingProperties?: { [key: string]: any }
  ) {
    await this.s.put(`user/${userId}/membership/${organizationId}`, {
      Token: inviteToken,
      RoleId: roleId,
      CollaboratorRoleIds: collaboratorRoleIds,
      MaxAssignmentsPerModule: maxAssignmentsPerModule,
      IsAvailableForAssignment: isAvailableForAssignment,
      ApplicationMatchingProperties: applicationMatchingProperties,
    });
  }

  public async deleteMembership(userIds: string[], organizationId: string, collaborationOnly = false) {
    await this.s.post(`user/membership/${organizationId}/delete`, userIds, {
      collaborationOnly,
    });
  }

  async generateMFASecret(userId: string) {
    const res = await this.s.get<{ Secret: string; QR: string }>(`user/${userId}/mfa`);
    return res.body;
  }

  async activateMfa(userId: string, secret: string, code: string) {
    const res = await this.s.post(`user/${userId}/mfa/activate`, {
      Secret: secret,
      Code: code,
    });
    return res.body;
  }

  async disableMfa(userId: string) {
    const res = await this.s.post(`user/${userId}/mfa/deactivate`, null);
    return res.body;
  }

  async setSeasonSetting(seasonId: string, key: string, value: any) {
    let patchSet: { [key: string]: any } = {};
    patchSet[key] = value;
    return await this.setSeasonSettings(seasonId, patchSet);
  }

  async setSeasonSettings(seasonId: string, patchSet: { [key: string]: any }) {
    const res = await this.s.post<UserSeasonSettings>(`user/set-season-settings/${seasonId}`, patchSet);
    return res.body;
  }

  async putUserSeasonNotificationSettings(
    seasonId: string,
    userSeasonNotificationSettings: UserSeasonNotificationSettings
  ) {
    const res = await this.s.put<UserSeasonNotificationSettings>(
      `user/season-settings/${seasonId}/notification`,
      userSeasonNotificationSettings
    );
    return res.body;
  }

  async webSocketKey() {
    const res = await this.s.post<string>("user/websocket-key", null);
    return res.body;
  }

  public async getUserClientModel(id: string) {
    const res = await this.s.get(`user/client-model/${id}`);
    return res.body;
  }

  public async revokeAccess(data: RevokeAccessArgs) {
    const res = await this.s.post<RevokeAccessArgs>("user/revoke-access", data);
    return res.body;
  }

  public async invite(data: InviteArgs) {
    const res = await this.s.post<InviteArgs>("user/invite", data);
    return res.body;
  }
}

interface RevokeAccessArgs {
  userId: string;
  portalId: string;
}

interface InviteArgs {
  userId: string;
  portalIds: string[];
  sendNow: boolean;
}

interface UpdateEmailArgs {
  OrganizationId: string;
  EmailAddress: string;
  Password: string;
  PortalType: PortalType;
}

interface UpdatePasswordArgs {
  NewPassword: string;
  Password: string;
}

interface LoginArgs {
  OrganizationId?: string;
  EmailAddress: string;
  Password: string;
  Token?: string;
  Code?: string;
  PortalId?: string;
  PortalType: PortalType;
}
