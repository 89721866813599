export enum TimingFilterType {
  Any = "any",
  Overdue = "overdue",
  DueToday = "dueToday",
  DueSoon = "dueSoon",
  Empty = "empty",
  Today = "today",
  Tomorrow = "tomorrow",
  Yesterday = "yesterday",
  Last7 = "last7",
  Last7Days = "week",
  LastX = "lastX",
  LastXDays = "lastXDays",
  Month = "month",
  CurrentMonth = "currentMonth",
  Year = "year",
  CurrentYear = "currentYear",
  DayRange = "day",
  DateRange = "date"
}

export type DateTiming = TimingFilterType;

export const OccurrenceOptions = [
  { value: "", text: "Did occur" },
  { value: "-", text: "Did not occur" }
]

export const TimingOptions = [
  { value: TimingFilterType.Any, text: "Any" },
  { value: TimingFilterType.Empty, text: "Empty" },
  { value: TimingFilterType.Overdue, text: "Overdue" },
  { value: TimingFilterType.DueToday, text: "Due Today" },
  { value: TimingFilterType.DueSoon, text: "Due Soon" },
  { value: TimingFilterType.Today, text: "Today" },
  { value: TimingFilterType.Tomorrow, text: "Tomorrow" },
  { value: TimingFilterType.Yesterday, text: "Yesterday" },
  { value: TimingFilterType.Last7, text: "In last 7 days" },
  { value: TimingFilterType.Last7Days, text: "In last 7 days" },
  { value: TimingFilterType.LastX, text: "In last X days" },
  { value: TimingFilterType.LastXDays, text: "In last X days" },
  { value: TimingFilterType.Month, text: "In current month" },
  { value: TimingFilterType.CurrentMonth, text: "In current month" },
  { value: TimingFilterType.CurrentYear, text: "In current calendar year" },
  { value: TimingFilterType.Year, text: "In current calendar year" },
  { value: TimingFilterType.DayRange, text: "Sliding Range" },
  { value: TimingFilterType.DateRange, text: "Date Range" },
];

export const ShortTimingOptions = [
  { value: TimingFilterType.Today, text: "Today" },
  { value: TimingFilterType.Yesterday, text: "Yesterday" },
  { value: TimingFilterType.Last7, text: "In last 7 days" },
  { value: TimingFilterType.LastXDays, text: "In last X days" },
  { value: TimingFilterType.CurrentMonth, text: "In current month" },
  { value: TimingFilterType.CurrentYear, text: "In current calendar year" },
]
