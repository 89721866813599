import { inject } from "fw";
import { get, findKey, orderBy, groupBy } from "lodash-es";

import {
  Completer,
  CompletionItem,
  getItems,
  toCompleterStructure,
  getCompletionItems,
} from "helpers/auto-complete";
import { fillOutContactProperties } from "helpers/client-model";
import { ApplicationSettingsStore } from "state/application-settings";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { FormStore } from "state/forms";
import { EvaluationPhasesStore } from "state/evaluation-phases";
import { getFormItems } from "./form-keys";
import { CollaborationModulesStore } from "state/collaboration-modules";
import { ProgramStore } from "state/program";
import { CustomFieldType } from "models/contact-organization";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryStore } from "state/data-dictionary";
import { DataDictionaryField, DataDictionaryFieldDataSource } from "models/data-dictionary";
import { QuestionType } from "../../../../form-runtime/src/enums";
import { divideWords, getFieldsFromProperties } from "helpers/email-builder";
import { CategoryDataDictionaryFields } from "views/components/data-dictionary-field-selector-popover";

const buildPhaseItems = (): CompletionItem[] => {
  return [
    { token: "evaluation_complete" },
    { token: "assigned_team_id" },
    { token: "assigned_users" },
    { token: "directly_assigned_users" },
    { token: "evals_completed" },
    { token: "evals_completed_by" },
    { token: "assigned_team" },
    { token: "evaluations" },
  ];
};

@inject
export class ApplicationClientModelCompleter implements Completer {
  private contactSpecials = [
    { contact: "id", pretty: "id", type: CustomFieldType.string },
    { contact: "name", pretty: "name", type: CustomFieldType.string },
    { contact: "FirstName", pretty: "givenName", type: CustomFieldType.string },
    { contact: "LastName", pretty: "familyName", type: CustomFieldType.string },
    { contact: "EmailAddress", pretty: "emailAddress", type: CustomFieldType.email },
  ];

  private prefilteredFields: DataDictionaryField[] = null;

  constructor(
    private evaluationPhasesStore: EvaluationPhasesStore,
    private applicationSettingsStore: ApplicationSettingsStore,
    private currentContactsOrgStore: CurrentContactOrganizationStore,
    private formsStore: FormStore,
    private collaborationModulesStore: CollaborationModulesStore,
    private programStore: ProgramStore,
    private ffs: FeatureFlagService,
    private dataDictionary: DataDictionaryStore
  ) {}

  private getApplicationCalculatedItems(): CompletionItem[] {
    const settings = this.applicationSettingsStore.state.applicationSettings;
    if (!settings) return [];

    return settings.ApplicationProperties.map((i) => {
      const dataType = get(CalculatedFieldDataTypeCode, i.DataType, "").toLowerCase();
      const fieldType = findKey(CustomFieldType, (f) => String(f).toLowerCase() == dataType);

      return { token: i.Key, type: CustomFieldType[fieldType] };
    });
  }

  private getPhasesItems(): CompletionItem[] {
    return [
      { token: "current", properties: buildPhaseItems() },
      ...this.evaluationPhasesStore.state.phases.map((p) => {
        return { token: p.Key, properties: buildPhaseItems() };
      }),
    ];
  }

  private getApplicantCalculatedItems(): CompletionItem[] {
    const settings = this.applicationSettingsStore.state.applicantSettings;
    if (!settings) return [];

    return settings.ApplicantProperties.map((i) => {
      const dataType = get(CalculatedFieldDataTypeCode, i.DataType, "").toLowerCase();
      const fieldType = findKey(CustomFieldType, (f) => String(f).toLowerCase() == dataType);

      return { token: i.Key, type: CustomFieldType[fieldType] };
    });
  }

  private getApplicantContactItems(): CompletionItem[] {
    return this.currentContactsOrgStore.state.organization.fields
      .filter((f) => f.contact_type == "applicant" && !this.contactSpecials.find((s) => s.contact == f.name))
      .map((f) => fillOutContactProperties(f));
  }

  private getProgramProperties(): CompletionItem[] {
    return this.programStore.state.settings.ProgramProperties.map((i) => {
      const dataType = get(CalculatedFieldDataTypeCode, i.DataType, "").toLowerCase();
      const fieldType = findKey(CustomFieldType, (f) => String(f).toLowerCase() == dataType);

      return { token: i.Key, type: Number(fieldType) };
    });
  }

  public usePrefilteredFields(fields: DataDictionaryField[]) {
    this.prefilteredFields = fields;
  }

  public getProperties(types: CustomFieldType[]) {
    const programProperties = [
      { token: "name", type: CustomFieldType.string },
      { token: "id", type: CustomFieldType.string },
      { token: "properties", properties: this.getProgramProperties() },
      { token: "publicName", type: CustomFieldType.string },
    ];

    const decisionLetterProperties = [{ token: "publicLink", type: CustomFieldType.string }];

    const p: CompletionItem[] = [
      { token: "dateLastApplicantChanged", type: CustomFieldType.date },
      { token: "dateStarted", type: CustomFieldType.date },
      { token: "phase", type: CustomFieldType.string },
      { token: "publicId", type: CustomFieldType.string },
      { token: "id", type: CustomFieldType.string },
      { token: "season", type: CustomFieldType.string },
      { token: "stage", type: CustomFieldType.string },
      {
        token: "applicant",
        properties: [
          ...this.contactSpecials.map((f) => ({ token: f.pretty, type: f.type })),
          ...this.getApplicantContactItems(),
          ...this.getApplicantCalculatedItems(),
        ],
      },
      {
        token: "program",
        properties: programProperties,
      },
      {
        token: "forms",
        properties: this.formsStore.state.forms.map((f) => ({
          token: f.Key,
          properties: getFormItems(f),
        })),
      },
      {
        token: "phases",
        properties: this.getPhasesItems(),
      },
      ...this.getApplicationCalculatedItems(),
      { token: "tags" },
    ];

    if (this.ffs.isFeatureFlagEnabled("ApplicationOrigin")) {
      p.push({ token: "origin", type: CustomFieldType.string });
    }

    p.push({
      token: "decisionLetter",
      properties: decisionLetterProperties,
    });

    const modules = this.collaborationModulesStore.state.modules.filter((m) => m.CalculatedFields.length > 0);

    if (modules.length > 0) {
      p.push({
        token: "collaborations",
        properties: [
          {
            token: "forms",
            properties: modules.map((m) => ({
              token: m.Key,
              properties: [
                { token: "evaluation_complete" },
                { token: "evals_completed" },
                ...m.CalculatedFields.map((cf) => ({ token: cf.Key })),
              ],
            })),
          },
        ],
      });
    }

    const filterEmptyPropsAndTypes = (a: CompletionItem) => {
      if (a.hasOwnProperty("properties")) {
        a.properties = a.properties.filter((b) => filterEmptyPropsAndTypes(b));
        return a.properties.length > 0;
      } else if (types) return types.indexOf(get(a, "type", null)) !== -1;
      else return true;
    };

    return p.filter((f) => filterEmptyPropsAndTypes(f));
  }

  private isConcealedField(field: DataDictionaryField) {
    return (
      (field.DataSource === DataDictionaryFieldDataSource.QuestionTypeCode &&
        field.DataType === QuestionType.Encrypted) ||
      (field.DataSource === DataDictionaryFieldDataSource.ContactsFieldType &&
        field.DataType === CustomFieldType.concealed)
    );
  }

  private get fields() {
    return (
      this.prefilteredFields || this.dataDictionary.state.fields.filter((f) => !this.isConcealedField(f))
    );
  }

  getFilteredFields(types: CustomFieldType[] = null) {
    const typesSet = new Set<CustomFieldType>(types || []);
    return typesSet.size ? this.fields.filter((x) => typesSet.has(x.DataType)) : this.fields;
  }

  getFieldsForInsertingExpression(types: CustomFieldType[]) {
    let fields = [];
    if (this.ffs.isFeatureFlagEnabled("AutoCompleterBasedOnDataDictionary") || this.prefilteredFields) {
      fields = this.getFilteredFields(types);
    } else {
      const appProperties = this.getProperties(types);

      fields = appProperties.reduce((previousValue, currentValue) => {
        const field = new DataDictionaryField();
        field.Category = divideWords(currentValue.token);
        field.Path = currentValue.token;
        field.Label = divideWords(currentValue.token);

        if (currentValue.properties) {
          return [...previousValue, ...getFieldsFromProperties(currentValue.properties, field)];
        }

        return [...previousValue, field];
      }, []);
    }

    const orderedFields = orderBy(fields, (f) => f.Category?.toLowerCase());

    const fieldsByCategory = groupBy(orderedFields, (f) => f.Category);
    const fieldOptions: CategoryDataDictionaryFields[] = [];

    for (const [category, fields] of Object.entries(fieldsByCategory)) {
      fieldOptions.push({ category, fields });
    }

    return fieldOptions;
  }

  async getCompletions(token: string, word: string, types: CustomFieldType[] = null) {
    if (this.ffs.isFeatureFlagEnabled("AutoCompleterBasedOnDataDictionary") || this.prefilteredFields) {
      const filteredFields = this.getFilteredFields(types);
      const completerStructure = toCompleterStructure(filteredFields);

      return getCompletionItems({ token, word }, completerStructure);
    } else {
      return getItems(this.getProperties(types), token, word);
    }
  }

  getCompletionsWithoutPromise(token: string, word: string, types: CustomFieldType[] = null) {
    if (this.ffs.isFeatureFlagEnabled("AutoCompleterBasedOnDataDictionary") || this.prefilteredFields) {
      const filteredFields = this.getFilteredFields(types);
      const completerStructure = toCompleterStructure(filteredFields);

      return getCompletionItems({ token, word }, completerStructure);
    } else {
      return getItems(this.getProperties(types), token, word);
    }
  }
}
