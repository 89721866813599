import { fromClass, fromClassArray } from "fw-model";
import { EmailContact } from "./email-contact";

export enum CustomEmailTemplateTypeCode {
  Application = 0,
  Contact = 1,
  System = 2,
}

export enum EmailTemplateSortKey {
  Subject = "Subject",
  Name = "Name",
  Type = "Type",
}

export class EmailTemplate {
  Key: string = null;
  IsCustomKey = true;
  Name: string = null;

  @fromClass From: EmailContact;
  @fromClass ReplyTo: EmailContact;

  Subject: string = null;
  Text: string = null;
  Html: string = null;
  Cc: string = null;
  Bcc: string = null;

  Template: string = null;
  AttachmentFileIds: string[] = [];
  CustomEmailTemplateTypeCode?: CustomEmailTemplateTypeCode = null;

  get Type(): string {
    return this.IsCustomKey ? "Design Layout" : "System Default";
  }
}

export class EmailTemplatePreview {
  Subject: string = null;
  Html: string = null;
  Text: string = null;
  To: string = null;
  RecipientReplacement: string = null;
  From: string = null;
  ReplyTo: string = null;
  Cc: string = null;
  Bcc: string = null;
  Tokens: object = null;
}

export class EmailTemplateTestEmail {
  EmailAddress: string = null;
}
export class EmailAttachment {
  Name: string = null;
  File: string = null;
  Type: string = null;
  Size: number = null;
  Data: string = null;
}

export class EmailTemplateSendEmail {
  To: string = null;
  FromName: string = null;
  FromAddress: string = null;
  ReplyToName: string = null;
  ReplyToAddress: string = null;
  Subject: string = null;
  Text: string = null;
  Html: string = null;
  Cc: string = null;
  Bcc: string = null;
  Tokens: object = null;
  EmailAttachments: Array<EmailAttachment> = [];
  AttachmentFileIds: string[] = [];
  @fromClassArray(EmailTemplateTestEmail) TestEmails: EmailTemplateTestEmail[];
}
