import { inject } from "fw";

import { CurrentOrganizationStore } from "state/current-organization";

import moment from "moment";
import "moment-timezone";

export const DEFAULT_TIMEZONE = "America/Chicago";

@inject
export class DateService {
  constructor(private currentOrganizationStore: CurrentOrganizationStore) { }

  public formatDate(date: number | number[] | string | Date | moment.Moment | Object, format: string = "MMM D, YYYY"): string {
    if (date == null || date == "") return "";
    const timeZone = this.currentOrganizationStore.state.organization.Timezone || DEFAULT_TIMEZONE;
    let m = moment.utc(date).tz(timeZone);
    return m.format(format);
  }

  public formatUtcDate(date: number | number[] | string | Date | moment.Moment | Object, format: string = "MMM D, YYYY"): string {
    if (date == null || date == "") return "";
    let m = moment.utc(date);
    return m.format(format);
  }

  public formatDateFromNow(date: number | number[] | string | Date | moment.Moment | Object, withSuffix: boolean = true): string {
    if (date == null || date == "") return "";
    const timeZone = this.currentOrganizationStore.state.organization.Timezone || DEFAULT_TIMEZONE;
    let m = moment.utc(date).tz(timeZone);
    return m.fromNow(!withSuffix);
  }

  public formatDateWithTimeZone(date: number | number[] | string | Date | moment.Moment | Object, format: string = "MMM D, YYYY") {
    if (date == null || date == "") return "";
    return moment(date).format(format);
  }

  public parseDate(date: number | string | Date): Date {
    if (!date) return null;

    let parsedDate = null;
    if (typeof date === 'string' || date instanceof String) {
      // moment js deprecated date parsing without explicitly predefined list of formats 
      // https://momentjs.com/guides/#/warnings/js-date/
      const momentDate = moment(date, [moment.ISO_8601, moment.RFC_2822, "MM-DD-YYYY HH:mm:ss.SSS ZZ", "MMM D YYYY HH:mm:ss.SSS ZZ", "LL"]);
      if (momentDate.isValid()) {
        parsedDate = momentDate.toDate();
      } else {
        console.log(`Unsupported date format detected '${date}'. Date construction falls back to js Date.`)
        parsedDate = new Date(date);
      }
    } else {
      parsedDate = moment(date).toDate();
    }

    return parsedDate;
  }

  public parseDateOnly(date: number | string | Date): string {
    if (!date) return null;

    let parsedDate = null;
    if (typeof date === 'string' || date instanceof String) {
      // moment js deprecated date parsing without explicitly predefined list of formats 
      // https://momentjs.com/guides/#/warnings/js-date/
      const dateWithoutConversionToLocalZone = moment.parseZone(date, [moment.ISO_8601, moment.RFC_2822, "MM-DD-YYYY", "MMM D YYYY", "LL"]);
      if (dateWithoutConversionToLocalZone.isValid()) {
        // used "YYYY-MM-DD" instead of toISOString() to avoid to UTC timezone convertion which can cause date change
        parsedDate = dateWithoutConversionToLocalZone.format("YYYY-MM-DD");
      } else {
        // Fall back for formats not listed in call to "parseZone" 
        // Once new format detected we should add them to list of supported formats.
        console.log(`Unsupported date format detected '${date}'. Date construction falls back to js Date.`)
        parsedDate = new Date(date).toISOString();
      }
    } else {
      parsedDate = moment(date).toISOString();
    }

    return parsedDate?.substring(0, 10);
  }

  public extractDateOnly(date: number | string | Date): Date | null {
    const isoDatePart = this.parseDateOnly(date);
    if (!isoDatePart) {
       return null;
    }
    
    const onlyDateISO: RegExp = /(\d{4})-(\d{2})-(\d{2})$/s;
    const match = onlyDateISO.exec(isoDatePart);
    return match
      ? new Date(parseInt(match[1]), parseInt(match[2]) - 1, parseInt(match[3]))
      : null;
  }

  public getDateParts(date: number | string | Date, part: string): number {
    const timeZone = this.currentOrganizationStore.state.organization.Timezone || DEFAULT_TIMEZONE;
    let m = moment.utc(date).tz(timeZone);
    return m.get(part as moment.unitOfTime.All);
  }
}
