import { inject } from "fw";
import { DialogService } from "fw-dialog";
import { NotificationDialog, NotificationDialogArgs, NotificationType } from "views/components/notification-dialog";

interface NotificationMessage {
  message: string;
  dom: HTMLElement;
  order: number;
}

@inject
export class Notification {
  private messages: NotificationMessage[] = [];

  constructor(
    private dialogService: DialogService
  ) { }

  public notify(message: string) {
    this.pushMessage(message, "success");
  }

  public notifyWarning(message: string) {
    this.pushMessage(message, "warning");
  }

  public async error(message: string | string[], title: string = "Something went wrong.") {    
    this.errorDialog(message, title);
  }

  public async successDialog(message: string | string[],  title: string = null) {
    const notificationArgs: NotificationDialogArgs = {
      title,
      text: message,
      type: NotificationType.success
    };

    await this.dialogService.open<void>(
      NotificationDialog,
      notificationArgs,
      {cssClass: 'c-notification-dialog'}
    );
  }

  public async errorDialog(message: string | string[],  title: string = null) {
    const notificationArgs: NotificationDialogArgs = {
      title,
      text: message,
      type: NotificationType.error
    };

    await this.dialogService.open<void>(
      NotificationDialog,
      notificationArgs,
      {cssClass: 'c-notification-dialog'}
    );
  }

  public async warningDialog(message: string | string[], title: string = null) {
    const notificationArgs: NotificationDialogArgs = {
      title,
      text: message,
      type: NotificationType.warning
    };

    await this.dialogService.open<void>(
      NotificationDialog,
      notificationArgs,
      {cssClass: 'c-notification-dialog'}
    );
  }

  private pushMessage(message: string, cssClass: string) {
    const element = document.createElement("div");
    element.classList.add("notification", cssClass);
    element.innerText = message;
    document.body.appendChild(element);

    this.moveUpMessages();

    const theMessage = {
      message,
      dom: element,
      order: 0,
    };

    this.messages.push(theMessage);

    setTimeout(() => {
      element.classList.add("open-0");
    }, 10);

    setTimeout(() => {
      element.classList.add(`close-${theMessage.order}`);
      element.classList.remove(`open-${theMessage.order}`);
      setTimeout(() => {
        element.remove();
        const idx = this.messages.indexOf(theMessage);
        if (idx != -1) this.messages.splice(idx, 1);
      }, 500);
    }, 5000);
  }

  private moveUpMessages() {
    this.messages.forEach(m => {
      m.dom.classList.remove(`open-${m.order}`);
      m.order += 1;
      m.dom.classList.add(`open-${m.order}`);
    });
  }
}
