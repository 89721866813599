import { ComponentEventBus, inject, prop } from "fw";
import { dispatch } from "fw-state";
import { DataDictionaryField, DataDictionaryFieldCategory } from "models/data-dictionary";
import { ApplicationRestriction, getCategoryFromApplicationRestrictionType, getStepCode } from "models/role";
import { DataDictionaryService } from "service/data-dictionary";
import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";

@inject
export class ProgramStepRestriction {
  @prop(null) restriction!: ApplicationRestriction;

  public result: { name: string, isValid: boolean } = { name: "", isValid: false };
  private fields: DataDictionaryField[] = [];

  constructor(
    private ceb: ComponentEventBus,
    private dataDictionaryStore: DataDictionaryStore,
    private dataDictionaryService: DataDictionaryService,    
    private featureFlagService: FeatureFlagService
  ) { }

  public async attached() {

    await dispatch(new EnsureDataDictionaryFieldsAction(false));
    this.fields = this.dataDictionaryStore.state.fields;
    this.restrictionChanged();
  }

  get name() {
    switch (this.restriction.Type) {
      case "DocumentStep": return "Document";
      case "PortfolioStep": return "Portfolio";
      default: return this.restriction.Type;
    }
  }

  deletePath(index) {
    this.ceb.dispatch("delete-path", { restriction: this.restriction, index: index });
  }

  private restrictionChanged() {
    const result: { name: string, isValid: boolean } = { name: "", isValid: false };
    if (!this.restriction || !this.restriction.StepKey) {
      this.result = result;
      return;
    }

    const category: DataDictionaryFieldCategory = getCategoryFromApplicationRestrictionType(this.restriction.Type);
    const step = this.dataDictionaryService.resolveStep(this.fields, category, this.restriction.StepKey);
    if (step) {
      result.name = step.Label;
      result.isValid = true;
    } else {
      result.name = this.restriction.StepKey;
    }

    this.result = result;
  }

}
