import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { encodeEmailTemplate } from "./encode";

import { EmailTemplate, CustomEmailTemplateTypeCode } from "models/email-template";

@inject
export class EmailTemplateRepository {
  constructor(private s: ATS) {}

  async list() {
    const res = await this.s.get<any[]>("email-template");
    return createFromArray(EmailTemplate, res.body);
  }

  async applicationEmailTemplatesList() {
    const res = await this.s.get<any[]>("email-template", {
      type: CustomEmailTemplateTypeCode.Application,
    });
    return createFromArray(EmailTemplate, res.body);
  }

  async put(template: EmailTemplate) {
    const encoded = encodeEmailTemplate(template);
    const res = await this.s.put<EmailTemplate>(`email-template/${template.Key}`, encoded);

    return createFrom(EmailTemplate, res.body);
  }

  async deleteCustom(template: EmailTemplate) {
    await this.s.delete(`email-template/${template.Key}`);
  }

  async sendEmail(args: EmailTemplateArgs, seasonId: string = null) {
    const { FromName, FromAddress, ReplyToName, ReplyToAddress, ...originalArgs } = args;
    const sendEmailArgs: SendEmailArgs = {
      ...originalArgs,
      From: {
        Name: FromName,
        Address: FromAddress,
      },
      ReplyTo: {
        Name: ReplyToName,
        Address: ReplyToAddress,
      },
    };
    await this.s.post("email-template/send-email", sendEmailArgs, { seasonId });
  }
}

interface EmailAddressInfo {
  Name: string;
  Address: string;
}

interface SendEmailArgs {
  To: string;
  From: EmailAddressInfo;
  ReplyTo: EmailAddressInfo;
  Cc: string;
  Bcc: string;
  Subject: string;
  Text: string;
  Html: string;
}

interface EmailTemplateArgs {
  To: string;
  FromName: string;
  FromAddress: string;
  ReplyToName: string;
  ReplyToAddress: string;
  Cc: string;
  Bcc: string;
  Subject: string;
  Text: string;
  Html: string;
}
