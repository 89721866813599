import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";

import type { DecryptData } from "../../models";

@inject
export class EncryptedType {
  @prop(null)
  question: Question;

  @prop(null)
  answer: FormAnswer;

  @prop(false)
  readonly: boolean;

  @prop(false)
  displayonly: boolean;

  @prop(null)
  decryptData: DecryptData;

  @prop(null)
  ariaLabelledby;

  private maxLength = 80;
  private revealing = false;
  private remasked = false;
  private isChanged = false;

  constructor(private ceb: ComponentEventBus) {
  }

  attached() {
    this.maxLength = this.question.Options.MaxLength;
  }

  change() {
    this.answer.Data = null;
    this.ceb.dispatch("answer-changed");
  }

  changeInput() {
    this.isChanged = true;
  }

  get hasText() {
    return !!this.answer?.Text?.trim().length
  }

  get isEncrypted() {
    return this.answer?.IsEncrypted;
  }

  get showDecrypt() {
    return (
      this.hasText &&
      this.decryptData != null &&
      this.isEncrypted
    );
  }

  get showMasked() {
    return this.isEncrypted || this.remasked;
  }

  remask() {
    this.isChanged = false;
    this.remasked = !this.remasked
  }


  async reveal() {
    this.revealing = true;
    this.remasked = false;
    this.isChanged = false;

    try {
      const data = await this.decryptData(this.answer.Text);
      this.answer.Text = data;
      this.answer.IsEncrypted = false;
    } catch (err) {
    }

    this.revealing = false;
  }
}
