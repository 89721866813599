import { fromClass, fromClassArray } from "fw-model";

import { Application } from "./application";
import { Applicant } from "./applicant";
import { File } from "./file";
import { ApplicationReference } from "./application-reference";
import { ApplicationEvaluation } from "./application-evaluation";
import { Document } from "./document";
import { ApplicationClientModel } from "./application-client-model";
import { ApplicantAccount } from "./applicant-account";
import { WorkflowRun } from "./application-workflow-run";
import { PaymentTransactionClientModel } from 'state/payment-journal';
import { PaymentDetails } from './payments';

export class AppApplicationContext {
  @fromClass Application: Application;
  @fromClass Applicant: Applicant;
  @fromClass ApplicantAccount: ApplicantAccount;
  @fromClassArray(File) Files: File[];
  @fromClassArray(ApplicationReference) References: ApplicationReference[];
  @fromClassArray(ApplicationEvaluation) Evaluations: ApplicationEvaluation[];
  @fromClassArray(Document) Documents: Document[];
  @fromClassArray(WorkflowRun) WorkflowRuns: WorkflowRun[];
  @fromClassArray(PaymentDetails) Payments: PaymentDetails[];
}
