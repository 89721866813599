import { prop, ComponentEventBus, inject } from "fw";

let uniqueId = 0;

@inject
export class SwitchField {
  @prop(null) private label;
  @prop(null) private value;
  @prop(null) private valueList;
  @prop(null) private validation;
  @prop(null) private flippedLabel;
  @prop(false) private hideLabel;
  @prop(false) private disabled;
  @prop('') private ariaLabel;
  @prop(false) public loading;
  @prop(false) public valueIsControlledOutside;

  private uniqueId = uniqueId++;
  private isFocused = false;

  constructor(private ceb: ComponentEventBus) {}

  public get ariaLabelAttribute(): string {
    return (!!this.ariaLabel)
      ? this.ariaLabel
      : !!this.value ? 'Active' : 'Inactive'
  }

  public makeId() {
    return `${this.label ? this.label.replace(/\s/g, "") : ""}-cb-${
      this.uniqueId
    }`;
  }

  public onInput(value) {
    if (!this.valueIsControlledOutside) this.ceb.updateModel(value);
    this.ceb.dispatch("change");
  }

  public onFocus(e) {
    this.isFocused = true;
    this.ceb.dispatch("focus", e);
  }

  public onBlur(e) {
    this.isFocused = false;
    this.ceb.dispatch("blur", e);
  }

  get pValueList() {
    return this.valueList;
  }

  set pValueList(m) {
    this.ceb.dispatch("update:valueList", m);
  }

  get isChecked() {
    let isChecked = null;
    if (this.valueList != null) {
      isChecked = this.valueList.includes(this.value);
    } else {
      isChecked = this.value;
    }
    return isChecked;
  }
}
