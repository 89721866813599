import { Operation } from 'fast-json-patch';
import { ExportDefinition } from "models/export-definition";

export class EntitySelection {
  ids: string[] = [];
  excludedIds: string[] = [];
  filter: string = null;
  contact_type: string = null;
}

export class EntitySelectionWithExportDefinition {
  ids: string[] = [];
  excludedIds: string[] = [];
  filter: string = null;
  exportDefinition: ExportDefinition = null;
}
export class EntitySelectionPatches extends EntitySelection {
   patch: Operation[];

   constructor(
    public selection: EntitySelection,
    public operations: Operation[]){
      super();
      this.excludedIds = selection.excludedIds;
      this.filter = selection.filter;
      this.ids = selection.ids;
      this.patch = operations;
    }
}



export interface ApplicationClientModelApplicant {
  id: string;
  emailAddress: string;
  givenName: string;
  familyName: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
}

export interface ApplicationDecisionLetter {
  dateUpdatedUtc: Date;
  fileId: string;
  templateSetId: string;
  revealedAtUtc: Date;
}

export interface ApplicationClientModelProgram {
  name: string;
  id: string;
}

export interface ApplicationClientModelReferences {
  completed: number;
  pending: number;
  status: string;
}

export interface ApplicationClientModelPhaseData {
  evals_completed: number;
  evals_completed_by: string[];
  assigned_team_id: string;
  assigned_users: string[];
  directly_assigned_users: string[];
  evaluation_complete: boolean;
}

export interface ApplicationClientModel {
  id: string;
  //[calculatedValueKey: string]: any ;
  collaborations: { forms: { [formKey: string]: ApplicationClientModelPhaseData } };
  applicant: ApplicationClientModelApplicant;
  forms: { [formKey: string]: { [questionKey: string]: any } };
  program: ApplicationClientModelProgram;
  references: { [stepKey: string]: ApplicationClientModelReferences };
  stage: string;
  phase: string;
  phases: { [phaseKey: string]: ApplicationClientModelPhaseData };
  tags: string[];
  decision: string;
  decisionLetter: ApplicationDecisionLetter;
}
