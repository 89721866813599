import { fromClass, fromClassArray, fromCustom, createFrom } from "fw-model";

export enum ChartDataSource {
  Admissions,
  Contacts,
  Tasks,
  WeightScoreSettings
}

export interface WidgetDefinition {
  WidgetType: string;
  Name: string;
  ChartFilter: string;
  ChartType?: ReportChartType;
  ClientData: string;
  DataSource: ChartDataSource;
  ContactType: string;
  AllowFilter: boolean;

  clean(): void;
}

export class ShareToken {
  Token: string = null;
  AccessCode: string = null;
}


export enum ReportChartType {
  Bar = 0,
  Line = 1,
  Pie = 2,

  Percentiles = 10,
  Stack = 11,
  Table = 12,
  Progress = 13,
  Pipeline = 14,
  Count = 15,
  Chevron = 18,
  Funnel = 19
}

export enum AggregationType {
  Terms = 0,
  Histogram = 1,
  Dates = 2,
}

export enum TermsOrderBy {
  Counts = 0,
  Alpha = 1,
  OptionsOrder = 2,
}

export class ReportAxisDefinition {
  Label: string = null;
  Path: string = null;
  AggregationType = AggregationType.Terms;
  Interval?: number | string = null;
  ClientData: string;
  LabelTransform: string;
  BucketLimit = 100;
  TermsOrderBy = TermsOrderBy.Counts;
  HasOptionValues: boolean = false; //only used client-side
}

export class ReportDefinitionMetaData {
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  OrganizationId: string;
  SeasonId: string;
  IsDashboard: boolean;
  IsOrgDefaultDashboard: boolean;
  DashboardUserId: string;

  @fromClass ShareToken: ShareToken;
}

export interface TableChartOptions {
  heatmap: boolean;
}

export interface ReportChartDefinitionClientData {
  filterContainer: any; // Group Filter
  tableOptions?: TableChartOptions;
}

export class ReportChartDefinition implements WidgetDefinition {
  WidgetType = "chart";
  Name: string = null;
  ChartType = ReportChartType.Bar;
  ChartFilter: string = null;
  AllowFilter: boolean = true;

  ClientData = "{}";
  DataSource: ChartDataSource = ChartDataSource.Admissions;
  ContactType: string;

  @fromClass PrimaryIndependentVariable: ReportAxisDefinition;
  @fromClass SecondaryIndependentVariable: ReportAxisDefinition;

  clean() {
    if (
      this.SecondaryIndependentVariable != null &&
      this.SecondaryIndependentVariable.Path == null
    ) {
      this.SecondaryIndependentVariable = null;
    }
  }
}

export class AssignmentDefinition implements WidgetDefinition {
  WidgetType = "assignment";
  Name: string = null;
  ChartFilter: string = null;
  ClientData: string = null;
  DataSource = ChartDataSource.Admissions;
  ContactType: string = null;
  AllowFilter: boolean = true;

  Id: string = null;
  Mode: "team" | "user" = "user";
  PhaseKey = "current";

  clean() { }
}

export class GoalsDefinition implements WidgetDefinition {
  WidgetType = 'goals';
  Name: string = null;
  ChartFilter: string = null;
  ClientData: string = null;
  DataSource = ChartDataSource.Admissions;
  ContactType: string = null;
  AllowFilter: boolean = false;

  GoalIds: string[] = [];

  public clean() {
    // NoOp
  }
}

export class FunnelDefinition implements WidgetDefinition {
  WidgetType = 'funnel';
  Name: string = null;
  ChartType = ReportChartType.Funnel;
  ChartFilter: string = null;
  AllowFilter: boolean = true;

  ClientData = "{}";
  DataSource: ChartDataSource = ChartDataSource.Contacts;
  ContactType: string;

  Path: string;
  Label: string;

  @fromClass PrimaryIndependentVariable?: ReportAxisDefinition;

  clean() {}
}

export class ReportPreviewDefinition implements WidgetDefinition {
  WidgetType = "score-distribution";
  Name: string = null;
  ChartFilter: string = null;
  ClientData: string = null;
  DataSource = ChartDataSource.Admissions;
  ContactType: string = null;
  AllowFilter: boolean = true;
  ChartType: ReportChartType = ReportChartType.Bar;
  Path: string = null;
  clean() { }
}

export class TasksDefinition implements WidgetDefinition {
  WidgetType = "tasks";
  Name: string = null;
  ChartFilter: string = null;
  ClientData: string = null;
  DataSource = ChartDataSource.Tasks;
  ContactType: string = null;
  AllowFilter: boolean = true;

  Id: string = null;

  clean() { }
}

export class GridColumn { Label: string; Path: string; Sort: string; }

export class GridViewDefinition implements WidgetDefinition {
  WidgetType = "grid-view";
  Name: string = null;
  ChartFilter: string = null;
  AllowFilter: boolean = true;

  ClientData: string = null;
  DataSource: ChartDataSource = ChartDataSource.Tasks;
  ContactType: string = null;

  UserId: string = null;
  ListLimit: number = 5;
  Sort: string = null;
  @fromClassArray(GridColumn) Columns: GridColumn[] = [];

  clean() { }
}

const buildWidget = (data: WidgetDefinition[]) => {
  if (data == null) return [];

  const widgets: WidgetDefinition[] = [];

  for (const widget of data) {
    switch (widget.WidgetType) {
      case "chart":
        widgets.push(createFrom(ReportChartDefinition, widget));
        break;

      case "assignment":
        widgets.push(createFrom(AssignmentDefinition, widget));
        break;

      case "goals":
        widgets.push(createFrom(GoalsDefinition, widget));
        break;

      case "funnel":
        widgets.push(createFrom(FunnelDefinition, widget));
        break;

      case "tasks":
        widgets.push(createFrom(TasksDefinition, widget));
        break;

      case "grid-view":
        widgets.push(createFrom(GridViewDefinition, widget));
        break;
    }
  }

  return widgets;
};

export class FieldGroup {
  ColumnSpan = 1;
  Label: string = null;
  @fromCustom(buildWidget) List: WidgetDefinition[] = [];
}

export class ReportDefinition {
  Id: string;
  Name = "";
  Filter = "";
  Category = "";
  IsDeleted: boolean;

  @fromClassArray(FieldGroup) FieldGroups: FieldGroup[];
  @fromCustom(buildWidget) Widgets: WidgetDefinition[] = [];
  @fromClass MetaData: ReportDefinitionMetaData;

  clean() {
    if (this.Widgets != null) {
      this.Widgets.forEach(c => c.clean());
    }

    if (this.FieldGroups != null) {
      for (const fg of this.FieldGroups) {
        fg.List.forEach(c => c.clean());
      }
    }
  }
}

export interface ReportResultModel {
  ReportId: string,
  FieldGroups: { fieldGroup: FieldGroup; list: WidgetResults[]; }[],
  Results: WidgetResults[],
  IndexingTasks: string[],
}

export type WidgetResults = {
  widgetData: WidgetData;
  dataLoading: boolean;
};

// since this is a simple recursive data structure, it is easier to declare it as an interface
export class ChartData {
  Label: string;
  LabelData?: any;
  Count: number;
  Data?: ChartData[];
  Bounds?: {
    Min: string;
    Max: string;
  };
  ErrorResult: string;
}

export type WidgetDataChartData = ChartData | GoalChartViewItem
export interface WidgetData {
  WidgetType: string;
  Definition: WidgetDefinition;
  Data?: WidgetDataChartData[];
  Timezone?: string;
  UnavailablePendingIndex?: boolean;
}

export class ReportChartData implements WidgetData {
  WidgetType = "chart";
  @fromClass Definition: ReportChartDefinition;

  Data: ChartData[];
}

export class ReportResultMetaData {
  @fromClass ShareToken: ShareToken;
  Filter: string;
}

export class AssignmentData implements WidgetData {
  WidgetType = "assignment";
  @fromClass Definition: AssignmentDefinition;

  Total: number;
  Completed: number;
  ObjectName: string;
}

export class GoalChartViewItem {
  Id?: string;
  Name: string;
  CompletionPercentage: number;
  CurrentValue: number;
  TargetNumber: number;
  IsComplete: boolean;
  DaysRemaining: number;
  Color?: string;
}

export class GoalChartData implements WidgetData {
  WidgetType = "goals";
  @fromClass Definition: GoalsDefinition;

  Data: GoalChartViewItem[];
}

export class FunnelChartData implements WidgetData {
  WidgetType = "funnel";
  @fromClass Definition: FunnelDefinition;

  Data: ChartData[];
}

export class TasksWidgetData implements WidgetData {
  WidgetType = "tasks";
  @fromClass Definition: TasksDefinition;

  Overdue: number;
  DueToday: number;
  DueSoon: string;
}

export class GridViewColumnData {
  Type: "string" | "boolean" | "number" | "array" | "object" | "date" | "datetime" | "tags" = "string";
  Value: any = null;
}

export class GridViewRowData {
  Data: object = null;
  @fromClassArray(GridViewColumnData) Columns: GridViewColumnData[] = [];
}

export class GridViewWidgetData implements WidgetData {
  WidgetType = "grid-view";
  Timezone: string;
  UnavailablePendingIndex: boolean;
  @fromClass Definition: GridViewDefinition;
  @fromClassArray(GridViewRowData) Rows: GridViewRowData[] = [];
}

const buildWidgetData = (data: WidgetData[]) => {
  if (data == null) return [];

  const widgetData: WidgetData[] = [];
  for (const d of data) {
    switch (d.WidgetType) {
      case "chart":
        widgetData.push(createFrom(ReportChartData, d));
        break;

      case "assignment":
        widgetData.push(createFrom(AssignmentData, d));
        break;

      case "goals":
        widgetData.push(createFrom(GoalChartData, d));
        break;

      case "funnel":
        widgetData.push(createFrom(FunnelChartData, d));
        break;

      case "tasks":
        widgetData.push(createFrom(TasksWidgetData, d));
        break;

      case "grid-view":
        widgetData.push(createFrom(GridViewWidgetData, d));
        break;
    }
  }
  return widgetData;
};

export class FieldGroupWithData {
  ColumnSpan = 1;
  Label: string = null;
  @fromCustom(buildWidgetData) List: WidgetData[] = [];
}

export class ReportResult {
  Id: string;
  Name: string;

  @fromClassArray(FieldGroupWithData) FieldGroups: FieldGroupWithData[];
  @fromClass MetaData: ReportResultMetaData;
  @fromCustom(buildWidgetData) Widgets: WidgetData[] = [];
}

// some functions to detect types
export const isReportChartData = (data: WidgetData): data is ReportChartData => data.WidgetType == "chart";
export const isReportChartDefinition = (data: WidgetDefinition): data is ReportChartDefinition => data.WidgetType == "chart";

export interface DataClickEvent {
  WidgetData: WidgetData;
  Data: any;
}

export interface WidgetContext {
  CurrentUserId: string;
}

export enum ReportPageMode {
  New,
  Edit,
  Copy
}
