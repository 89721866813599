import { fromClass } from "fw-model";

export enum VoiceMessageType {
  recorded = 'recorded',
  typed = 'typed',
}

export class MarketingOutreachSentStats {
  sent?: number;
  error?: number;
  invalid_emails?: number;
  item_already_received?: number;
  opted_out_emails?: number;
  invalid_phones?: number;
  opted_out_phones?: number;
}

export class MarketingOutreachSent {
  Id: number = null;
  AtsOrgId: string = null;
  AtsUserId: string = null;
  MediaType: 'email' | 'print' | 'text' | 'voice' = null;
  Type: 'outreach' | 'one-off' = null;
  OutreachId: number = null;
  PrintTemplateId: number = null;
  AtsTemplateId: string = null;
  Html: string = null;
  Text: string = null;
  HtmlFile: string = null;
  TextFile: string = null;
  DeliveryType: 'applications' | 'contacts' | 'users' = null;
  DeliveryAddress: string = null;
  FilterType: 'filter' | 'ids' = null;
  Filter: string = null;
  SentRating: number = 0;
  OpenRating: number = 0;
  ClickRating: number = 0;
  ReplyRating: number = 0;
  Subject: string = null;
  FromName: string = null;
  FromAddress: string = null;
  ReplyToName: string = null;
  ReplyToAddress: string = null;
  Schedule: Date = null;
  SentAt: Date = null;
  Stats: MarketingOutreachSentStats = null;
  VoiceMessageType: VoiceMessageType = null;
  VoiceMessageVoice: string = null;
  VoiceMessageGender: 'male' | 'female' = null;
  VoiceMessageUrl: string = null;
  Status: 'in-queue' | 'sending' | 'sent' | 'error' = null;
}
