import { ComponentEventBus, inject, prop } from "fw";
import { PopoverService } from "service/popover";
import { ChartContactTypeSelectorPopover } from "./chart-contact-type-selector-popover";
import { ContactTypeDefinition } from 'models/contact-organization';
import { CurrentContactOrganizationStore } from 'state/current-contact-organization';

let inputIdIterator: number = 0;

@inject
export class ChartContactTypeSelector {
  @prop(null) defaultSelectedContactTypeKey!: string;

  private chartContactTypeSelectorBaseInputId: string = "chart-contact-type-selector-input";
  private chartContactTypeSelectorPopoverId: string = "chart-contact-type-selector-popover";
  private focusedOptionId: string = '';
  private open = false;
  private selectedContactType: ContactTypeDefinition;

  constructor(
    private componentEventBus: ComponentEventBus,
    private popoverService: PopoverService,
    private currentContactOrganizationStore: CurrentContactOrganizationStore
  ) { }

  get chartContactTypeSelectorInputId() {
    inputIdIterator++;
    return `${this.chartContactTypeSelectorBaseInputId}-${inputIdIterator}`;
  }

  private getSelectedContactTypeName() {
    if (this.selectedContactType != null) {
      return this.selectedContactType.plural_name;
    }
    else if (this.defaultSelectedContactTypeKey != null) {
      this.selectedContactType = this.currentContactOrganizationStore.state.organization.contact_types.find(contactType => contactType.key == this.defaultSelectedContactTypeKey);
      return this.selectedContactType.plural_name;
    }

    return 'Choose Contact Type';
  }

  public updateOptionFocus(optionId: string) {
    this.focusedOptionId = optionId;
  }

  private async openChartContactTypeSelector() {
    this.open = true;
    console.log('openChartContactTypeSelector open=true');
    const res = await this.popoverService.open<ContactTypeDefinition>(
      ChartContactTypeSelectorPopover,
      {
        selectedContactType: this.selectedContactType,
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.chartContactTypeSelectorPopoverId },
    );
    console.log('openChartContactTypeSelector res=', res);
    if (res.canceled == false) {
      this.selectedContactType = res.result;
      this.componentEventBus.dispatch("chart-contact-type-changed", res.result);
      console.log('openChartContactTypeSelector dispatched event');
    }
    this.open = false;
    console.log('openChartContactTypeSelector open=false');
  }
}
