import { inject } from "fw";
import { DataDictionaryField } from "models/data-dictionary";

import { PopoverController } from "service/popover";
import { DataDictionaryStore } from "state/data-dictionary";

export type CategoryDataDictionaryFields = {
  category: string;
  fields: DataDictionaryField[];
  filteredFields?: DataDictionaryField[];
};

export interface DataDictionaryFieldSelectorOptions {
  options: CategoryDataDictionaryFields[];
  filter?: (field: DataDictionaryField) => boolean;
  selectedOption?: DataDictionaryField;
  updateOptionFocus?: Function;
  closeCallback?: Function;
}

@inject
export class DataDictionaryFieldSelectorPopover {
  public hideSearch: boolean = true;
  public showAll: boolean = false;
  public filter: (field: DataDictionaryField) => boolean = null;
  public options: CategoryDataDictionaryFields[] = [];
  public selectedField: DataDictionaryField = null;
  public searchTerm: string = "";
  public focusedOptionId: string = '';
  public updateOptionFocus: Function = () => {};

  constructor(
    private controller: PopoverController<DataDictionaryField>,
    private dataDictionaryStore: DataDictionaryStore
  ) { }

  public activate(options: DataDictionaryFieldSelectorOptions) {
    this.filter = options.filter;
    this.selectedField = options.selectedOption;
    if (options?.updateOptionFocus) {
      this.updateOptionFocus = options?.updateOptionFocus;
    }
    if (options?.closeCallback) {
      options?.closeCallback(() => this.close());
    }
    this.updateOptionFocus(this.makeOptionId(this.selectedField));

    this.options = options.options;
    this.options.forEach(o => this.categorySearch(o));
    const optionCount = this.options.reduce((previousValue, currentValue) => previousValue + currentValue.fields.length, 0);
    const filteredOptionCount = this.options.reduce((previousValue, currentValue) => previousValue + currentValue.filteredFields.length, 0);

    this.showAll = this.filter === null || filteredOptionCount === 0 || filteredOptionCount === optionCount;
    this.hideSearch = filteredOptionCount < 10;
  }

  public makeOptionId(field: DataDictionaryField): string {
    const pathAsId = field?.Path?.replace(/\./g, "");
    return !!pathAsId ? `${pathAsId}-ddfs` : null;
  }

  public categorySearch(category: CategoryDataDictionaryFields): boolean {
    const term = this.searchTerm.toLowerCase();

    category.filteredFields = category.fields.filter(f => {
      if (!term.length && this.selectedField?.Path === f.Path) {
        return true;
      }

      if (!this.showAll && this.filter && !this.filter(f)) {
        return false;
      }

      if (!term.length) {
        return true;
      }

      return f.Label?.toLowerCase().includes(term) || false;
    });

    return category.filteredFields.length > 0;
  }

  public showAllFields() {
    this.showAll = true;
    this.hideSearch = this.options.reduce((previousValue, currentValue) => previousValue + currentValue.fields.length, 0) < 10;
  }

  public close() {
    this.controller.cancel();
  }

  public selectField(field: DataDictionaryField) {
    this.updateOptionFocus(this.makeOptionId(field));
    this.controller.ok(field);
  }

  public get filteredOptions() {
    return this.options.filter(this.categorySearch);
  }

  public get loading(): boolean {
    return this.dataDictionaryStore.state.status === "loading";
  }
}
