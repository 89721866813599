import { inject, needs, Navigator } from "fw";
import { DialogService } from "fw-dialog";
import { dispatch } from "fw-state";
import { hashOn } from "hashing";
import { isEqual, cloneDeep, isEmpty, keys, intersection, toPath, uniq, result } from "lodash-es";

import { RoleSettings } from "models/contact-organization";
import {
  ApplicationRestriction,
  ApplicationRestrictionType,
  getStepCode,
  getFormName,
  getCategoryFromApplicationRestrictionType
} from "models/role";

import {
  ContactRoleSettingsFormType,
  contactRoleSettingsFormCreator,
  contactTypeDataPolicyFormCreator,
} from "forms/contact-organization";
import {
  DeleteRoleAction,
  AddRoleAction,
  RoleFormType,
  UpdateRoleAction,
  roleFormCreator,
  dataPolicyFormCreator,
  applicationRestrictionFormCreator,
  ApplicationRestrictionFormType,
  seasonalRoleSettingsFormCreator
} from "forms/role";

import { Confirm } from "service/confirm";
import { FeatureFlagService } from "service/feature-flag";
import { Notification } from "service/notification";
import { OrganizationModuleService } from "service/organization-module";
import { PopoverService } from "service/popover";
import { RoleService } from "service/role";
import { Permissions } from "service/permission";

import { ContactOrganizationRepository } from "network/contact-organization-repository";

import { RefreshContactOrganizationModelAction } from "state/actions";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { RolesStore } from "state/roles";
import { ReportDefinitionsStore } from "state/report-definitions";
import { ApplicationSegmentStore } from "state/application-segments";

import { ActionHeader } from "views/header/action-header";
import { ApplicationSegmentSelectorPopover, ApplicationSegmentSelectorPopoverResponse } from "views/applicants/application-segment-selector-popover";
import { RestrictionSelectorPopover } from "views/settings/components/data-restrictions/restriction-selector-popover";

import {
  restrictionComponents,
  getRestrictionComponentType
} from "./restriction-forms/components";

import { SetFilterAction } from "state/users";
import { ApplicationSettingsService } from "service/application-settings";
import { FormService } from "service/form";
import { DataDictionaryField, DataDictionaryFieldCategory } from "models/data-dictionary";
import { DataDictionaryService } from "service/data-dictionary";
import { PermissionTooltip } from "./permission-tooltip";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";
import { ContactRestrictions } from "./contact-restrictions";
import { UserRepository } from "network/user-repository";

export const CUSTOM_PERMISSIONS_PROPERTY = "__custom_permissions";
export const SPLIT_CHAR = ",";

@inject
@needs(ActionHeader, ...restrictionComponents, PermissionTooltip, ContactRestrictions)
export class EditRole {
  public restrictionNames = {
    ApplicantIdentity: "Applicant Identity",
    ApplicationProperty: "Application Properties"
  };

  public roleForm: RoleFormType = null;
  public newRole: boolean = true;

  public isAdministrator: boolean = false;
  public isDeleting: boolean = false;
  public isSaving: boolean = false;
  public isLoading: boolean = true;
  private originalModel = null;
  public invalidRestrictions: string[] = [];

  private originalContactRole: RoleSettings = null;
  private contactRoleForm: ContactRoleSettingsFormType = null;
  private numRoleUsers: number = 0;

  constructor(
    public organizationModuleService: OrganizationModuleService,
    private applicationSettingsService: ApplicationSettingsService,
    private dataDictionaryStore: DataDictionaryStore,
    private dataDictionaryService: DataDictionaryService,
    private dialogService: DialogService,
    private popoverService: PopoverService,
    private roleService: RoleService,
    private formService: FormService,
    private ffs: FeatureFlagService,    
    private contactOrganizationRepo: ContactOrganizationRepository,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private currentOrganizationStore: CurrentOrganizationStore,
    private rolesStore: RolesStore,
    private userRepo: UserRepository,
    private reportDefinitionsStore: ReportDefinitionsStore,
    private segmentStore: ApplicationSegmentStore,
    private notify: Notification,
    private confirm: Confirm,
    private nav: Navigator,
    private permissionService: Permissions
  ) { }

  get activeSeason() {
    return this.currentOrganizationStore.state.organization.ActiveSeasonId;
  }

  get customPermissions(): string[] {
    return this.roleService.customPermissions();
  }

  get activeRestrictions(): ApplicationRestriction[] {
    const seasonId = this.activeSeason;
    if (!this.roleForm || !this.roleForm.DataPolicies || !seasonId) return [];

    const policies = this.roleForm.DataPolicies[seasonId];
    return policies?.ApplicationRestrictions ?? [];
  }

  private async getInvalidRestrictions(): Promise<string[]> {
    const activeRestrictions = this.activeRestrictions;
    const invalid: string[] = [];
    let fields: DataDictionaryField[] = null;
    for (const restriction of activeRestrictions) {
      switch (restriction.Type) {
        case "ApplicationProperty":
          for (const propertyKey of restriction.Paths) {
            if (!propertyKey || propertyKey.length === 0) continue;

            const property = this.applicationSettingsService.getApplicationPropertyFromPath(propertyKey);
            if (!property) {
              invalid.push(`Application Properties/${propertyKey}`);
            }
          }

          break;

        case "FormStep":
        case "Evaluation":
        case "ReferenceForm":
          for (const path of restriction.Paths) {
            const pathResult = await this.formService.getFormPathResult(path);
            if (!pathResult || pathResult.IsValid) {
              continue;
            }

            let invalidPath = pathResult.FormTypeName || getFormName(restriction.Type);
            const form = pathResult.FormName || pathResult.FormKey;
            if (form) invalidPath += `/${form}`;

            const section = pathResult.SectionTitle || pathResult.SectionKey;
            if (section) invalidPath += `/${section}`;

            const question = pathResult.QuestionKey;
            if (question) invalidPath += `/${question}`;

            invalid.push(invalidPath);
          }

          break;

        case "PortfolioStep":
        case "DocumentStep":
          if (!restriction.StepKey || restriction.StepKey.length === 0) {
            continue;
          }

          let hasStep: boolean = false;

          if (fields == null) {
            // Lazy load fields, this ensures it's always up to date.
            await dispatch(new EnsureDataDictionaryFieldsAction(false));
            fields = this.dataDictionaryStore.state.fields;
          }

          const category: DataDictionaryFieldCategory = getCategoryFromApplicationRestrictionType(restriction.Type);
          hasStep = !!this.dataDictionaryService.resolveStep(fields, category, restriction.StepKey);


          if (!hasStep) {
            invalid.push(`${restriction.Type.replace("Step", "")}/${restriction.StepKey}`);
          }

          break;

      }
    }

    return invalid;
  }

  get reportDefs() {
    return [
      { text: "Default", value: null },
      ...this.reportDefinitionsStore.state.allDefinitions.map(d => ({
        text: d.Name,
        value: d.Id
      }))
    ];
  }

  get hasApplicationPermissions() {
    const p = this.roleService.permissions();
    const selected = intersection(keys(p.applicationTrackPermissions), this.roleForm.Permissions);
    return selected.length > 0;
  }

  get hasApplicationSettingsPermissions() {
    const p = this.roleService.permissions();
    const selected = intersection(keys(p.applicationTrackSettingsPermissions), this.roleForm.Permissions);
    return selected.length > 0;
  }

  get hasPaymentTrackPermissions() {
    const p = this.roleService.permissions();
    const selected = intersection(keys(p.paymentTrackPermissions), this.roleForm.Permissions);
    return selected.length > 0;
  }

  get hasGeneralSettingsPermissions() {
    const p = this.roleService.permissions();
    const selected = intersection(keys(p.coreTrackPermissions), this.roleForm.Permissions);
    return selected.length > 0;
  }

  get hasContactsPermissions() {
    const p = this.roleService.permissions();
    const selected = intersection(keys(p.contactsTrackPermissions), this.roleForm.Permissions);
    return selected.length > 0;
  }

  get hasMarketingPermissions() {
    const p = this.roleService.permissions();
    const selected = intersection(keys(p.marketingPermissions), this.roleForm.Permissions);
    return selected.length > 0;
  }

  get hasEventsPermissions() {
    const p = this.roleService.permissions();
    const selected = intersection(keys(p.eventsTrackPermissions), this.roleForm.Permissions);
    return selected.length > 0;
  }

  get seasonDashboard() {
    if (this.roleForm == null) return null;
    const settings = this.roleForm.SeasonalRoleSettings[this.activeSeason];
    if (settings == null) return null;

    return settings.DashboardReportId;
  }
  set seasonDashboard(v: string) {
    if (this.roleForm == null) return;
    const settings = this.roleForm.SeasonalRoleSettings[this.activeSeason];
    if (settings == null) return;

    settings.DashboardReportId = v;
  }

  public async activate(params: { id: string }) {
    if (params?.id) {
      const role = this.rolesStore.state.roles.find(t => t.Id == params.id);
      if (role == null) return;

      this.newRole = false;
      this.isAdministrator = role.Name == "Administrator";

      // NOTE:  the role must be cloned here because we cleanup the data restrictions when we load the data
      // however, we don't want dirty detection unless an explicit change is made to the role
      const clone = cloneDeep(role);
      this.roleForm = roleFormCreator(clone);
      // cleanup the restrictions on load
      this.normalize();
      this.numRoleUsers = await this.getNumRoleUsers();

    } else {
      this.roleForm = roleFormCreator(null);
      this.newRole = true;
      this.isAdministrator = false;
    }

    if (this.roleForm.SeasonalRoleSettings == null)
      this.roleForm.SeasonalRoleSettings = {};

    if (this.roleForm.SeasonalRoleSettings[this.activeSeason] == null) {
      this.roleForm.SeasonalRoleSettings[
        this.activeSeason
      ] = seasonalRoleSettingsFormCreator(null);
    }

    // NOTE: this is actually a clone of a clone for an existing role;  see above NOTE
    this.originalModel = cloneDeep(this.roleForm.updatedModel());
    this.invalidRestrictions = await this.getInvalidRestrictions();


    const contactRole = (this.roleForm?.Id
      ? this.currentContactOrganizationStore.state.organization.roles.find(r => r.role_id === this.roleForm.Id)
      : null);
    this.contactRoleForm = contactRoleSettingsFormCreator(contactRole || null);
    this.contactRoleForm.role_id = this.roleForm.Id;
    // make sure each contact type has a data policy
    const dataPolicies = [];
    const sortedContactTypes = this.currentContactOrganizationStore.state.organization.contact_types.sort((a, b) => {
      return ('' + a.name).localeCompare(b.name);
    });
    sortedContactTypes.forEach(ct => {
      const dataPolicy = this.contactRoleForm.data_policies.find(d => d.contact_type === ct.key);
      dataPolicies.push(contactTypeDataPolicyFormCreator(dataPolicy || {
        contact_type: ct.key,
        is_hidden: false,
        segment_id: null,
        restricted_field_ids: []
      }));
    });
    this.contactRoleForm.data_policies = dataPolicies;
    this.originalContactRole = cloneDeep(this.contactRoleForm.updatedModel());


    this.isLoading = false;
  }

  checkDirty() {

    return (!isEqual(this.originalModel, this.roleForm.updatedModel()) ||
      !isEqual(this.originalContactRole, this.contactRoleForm.updatedModel())
    );

  }

  async save() {
    if (!this.canEditRole) return;
    this.isSaving = true;

    let throwErr = null;
    let action: AddRoleAction | UpdateRoleAction = null;
    try {
      if (this.newRole) {
        const action = new AddRoleAction(this.roleForm);
        await dispatch(action);
        this.contactRoleForm.role_id = action.added.Id;
      }


      const updated = this.contactRoleForm.updatedModel();
      await this.contactOrganizationRepo.saveRoleSettings(updated);
      await dispatch(new RefreshContactOrganizationModelAction(this.currentContactOrganizationStore.state.organization.id));
      this.originalContactRole = updated;


      if (!this.newRole) {
        action = new UpdateRoleAction(this.roleForm);
        await dispatch(action);
      }

      this.notify.notify(
        `${this.newRole ? "Created" : "Updated"} Role: ${this.roleForm.Name}`
      );
      this.originalModel = this.roleForm.updatedModel();
      if (this.newRole) {
        this.nav.navigate("/settings/roles");
      }
    } catch (err) {
      if (action instanceof UpdateRoleAction && action.canForce) {
        if ((await this.confirm.confirm("Data restrictions contain invalid and/or deleted values. Do you want to continue? Note: Invalid/deleted restrictions will be removed.", { okButtonText: "Save" }))) {
          await this.fix();
          return;
        }
      } else {
        let msg = "Error saving role";
        if (this.roleForm.validationMessages.length)
          msg += ": " + this.roleForm.validationMessages.join(", ");
        this.notify.error(msg);
      }

      throwErr = err;
    }

    this.isSaving = false;

    if (throwErr != null) throw throwErr;
  }

  async fix() {
    await dispatch(new UpdateRoleAction(this.roleForm, true));
    this.notify.notify(
      `Updated Role: ${this.roleForm.Name}`
    );

    const role = this.rolesStore.state.roles.find(t => t.Id == this.roleForm.Id);
    const clone = cloneDeep(role);
    this.roleForm = roleFormCreator(clone);
    this.originalModel = this.roleForm.updatedModel();
    this.invalidRestrictions = await this.getInvalidRestrictions();
  }

  async deleteRole() {
    if ((await this.confirm.confirmDelete("Delete this role?")) == false)
      return;

    this.isDeleting = true;

    try {
      await dispatch(new DeleteRoleAction(this.roleForm.Id));
      // check for users assigned to role happens in the above delete. delete contact role after.
      if (this.roleForm.Id) {
        await this.contactOrganizationRepo.deleteRoleSettings(this.roleForm.Id);
        await dispatch(new RefreshContactOrganizationModelAction(this.currentContactOrganizationStore.state.organization.id));
      }
      this.notify.notify(`Deleted Role: ${this.roleForm.Name}`);
      this.nav.navigate("/settings/roles");
    } catch (err) {
      if (err.result != null && err.result.Message != null) {
        this.confirm.systemMessage(err.result.Message);
      }
    }

    this.isDeleting = false;
  }

  get permissions() {
    return this.roleService.permissions();
  }

  async addRestriction() {
    const activeRestrictions = this.activeRestrictions;
    const popover = await this.popoverService.open<ApplicationRestriction[]>(
      RestrictionSelectorPopover,
      activeRestrictions,
    );
    if (popover.canceled || !popover.result || popover.result.length === 0) return;

    for (const restriction of popover.result) {
      switch (restriction.Type) {
        case "ApplicantIdentity":
          const applicantIdentity = activeRestrictions.find(r => r.Type == restriction.Type);
          if (applicantIdentity) continue;
          break;

        case "ApplicationProperty":
          if (!restriction.Paths || restriction.Paths.length === 0) continue;
          const applicationProperty = activeRestrictions.find(r => r.Type == restriction.Type);
          if (applicationProperty) {
            applicationProperty.Paths = applicationProperty.Paths.concat(restriction.Paths);
            continue;
          }
          break;

        case "FormStep":
        case "Evaluation":
        case "ReferenceForm":
          //  just create a new one
          break;

        case "DocumentStep":
        case "PortfolioStep":
          if (!restriction.StepKey || restriction.StepKey.trim().length === 0) continue;
          const step = activeRestrictions.find(r => r.Type == restriction.Type && r.StepKey == restriction.StepKey);
          if (step) continue;
          break;
      }

      this.createRestriction(restriction);
    }

    this.normalize();
  }

  normalize() {
    const propertiesHash = {};
    const formsHash = {};
    const stepsHash = {};

    const activeRestrictions = this.activeRestrictions;
    for (const restriction of activeRestrictions) {
      switch (restriction.Type) {
        case "ApplicantIdentity": break;

        case "ApplicationProperty":
          for (const propertyPath of restriction.Paths)
            // const p = this.applicationSettingsService.getApplicationPropertyFromPath(path);
            propertiesHash[propertyPath] = true;
          break;

        case "FormStep":
        case "Evaluation":
        case "ReferenceForm":
          const type = restriction.Type;
          if (!formsHash[type]) formsHash[type] = {};

          for (const formPath of restriction.Paths) {
            const p = formPath.startsWith("forms.") ? formPath.substring(6) : formPath;
            const paths = toPath(p);
            const formKey = paths[0];

            if (!formsHash[type][formKey]) formsHash[type][formKey] = {};

            formsHash[type][formKey][p] = true;

          }
          break;

        case "DocumentStep":
        case "PortfolioStep":
          if (!stepsHash[restriction.Type]) stepsHash[restriction.Type] = {};
          stepsHash[restriction.Type][restriction.StepKey] = true;
          break;
      }
    }

    if (propertiesHash && !isEmpty(propertiesHash)) {
      const propertyPaths = keys(propertiesHash);
      if (propertyPaths && propertyPaths.length > 0) {
        const restrictions = activeRestrictions.filter(r => r.Type == "ApplicationProperty");
        let restriction = restrictions && restrictions.length > 0 && restrictions[0];
        if (!restriction) {
          this.createPathRestriction("ApplicationProperty", propertyPaths);
        } else {
          restriction.Paths = propertyPaths;
          if (restrictions.length > 1) {
            for (let idx = 1; idx < restrictions.length; idx++) {
              this.deleteRestriction(restrictions[idx]);
            }
          }
        }
      }
    }

    if (stepsHash && !isEmpty(stepsHash)) {
      for (const type of keys(stepsHash)) {
        if (stepsHash[type] && !isEmpty(stepsHash[type])) {
          for (const stepKey of keys(stepsHash[type])) {
            const restrictions = activeRestrictions.filter(r => r.Type == type && r.StepKey == stepKey);
            let restriction = restrictions && restrictions.length > 0 && restrictions[0];
            if (!restriction) {
              this.createStepRestriction(type as ApplicationRestrictionType, stepKey);
            } else if (restrictions.length > 1) {
              for (let idx = 1; idx < restrictions.length; idx++) {
                this.deleteRestriction(restrictions[idx]);
              }
            }
          }
        }
      }
    }

    if (formsHash && !isEmpty(formsHash)) {
      for (const type of keys(formsHash)) {
        if (formsHash[type] && !isEmpty(formsHash[type])) {
          for (const formKey of keys(formsHash[type])) {
            if (formsHash[type][formKey] && !isEmpty(formsHash[type][formKey])) {
              const formPaths = keys(formsHash[type][formKey]);
              if (formPaths && formPaths.length > 0) {
                const restrictions = activeRestrictions.filter(r => r.Type == type && r.Paths && r.Paths.length > 0 && r.Paths.findIndex(p => p.startsWith(`${formKey}.`)) != -1);
                let restriction = null;
                if (!restrictions || restrictions.length === 0) {
                  restriction = this.createPathRestriction(type as ApplicationRestrictionType, formPaths);
                } else {
                  restriction = restrictions[0];
                  restriction.Paths = restrictions.length === 1
                    ? restriction.Paths.concat(formPaths)
                    : restrictions.map(r => r.Paths && r.Paths.length > 0 ? r.Paths : []).reduce((a, b) => a.concat(b)).concat(formPaths);
                  restriction.Paths = uniq(restriction.Paths);
                  if (restrictions.length > 1) {
                    for (let idx = 1; idx < restrictions.length; idx++) {
                      this.deleteRestriction(restrictions[idx]);
                    }
                  }
                }

                const wildCard = `${formKey}.*`;
                if (restriction.Paths.indexOf(wildCard) != -1) {
                  restriction.Paths = [wildCard];

                } else {
                  const pathHash = {};
                  for (const path of restriction.Paths) {
                    pathHash[path] = true;
                  }
                  restriction.Paths = keys(pathHash);
                }

              }
            }
          }
        }
      }
    }

  }

  createPathRestriction(type: ApplicationRestrictionType, paths: string[]) {
    if (!type || !paths || paths.length === 0) return;
    const applicationRestriction: ApplicationRestriction = new ApplicationRestriction();
    applicationRestriction.Type = type;
    applicationRestriction.Paths = paths;
    return this.createRestriction(applicationRestriction);
  }

  createStepRestriction(type: ApplicationRestrictionType, stepKey: string) {
    if (!type || !stepKey || stepKey.trim().length === 0) return;
    const applicationRestriction: ApplicationRestriction = new ApplicationRestriction();
    applicationRestriction.Type = type;
    applicationRestriction.StepKey = stepKey;
    return this.createRestriction(applicationRestriction);
  }

  createRestriction(applicationRestriction: ApplicationRestriction) {
    const restrictionForm = applicationRestrictionFormCreator(null);
    restrictionForm.applyModel(applicationRestriction);

    if (this.roleForm.DataPolicies[this.activeSeason] == null) {
      const policyForm = dataPolicyFormCreator(null);
      const newDataPolicies = Object.assign({}, this.roleForm.DataPolicies, {
        [this.activeSeason]: policyForm
      });
      this.roleForm.DataPolicies = newDataPolicies;
    }

    this.roleForm.DataPolicies[this.activeSeason].ApplicationRestrictions.push(
      restrictionForm
    );

    return applicationRestriction;
  }

  deletePath(args: { restriction: ApplicationRestriction, index: number }) {
    const restriction = args.restriction;
    const index = args.index;
    if (restriction.Paths && index != -1) {
      restriction.Paths.splice(index, 1);
    }

    if (!restriction.Paths || restriction.Paths.length === 0) {
      this.deleteRestriction(restriction);
    }
  }

  getRestrictionType(r: ApplicationRestrictionFormType) {
    return getRestrictionComponentType(r);
  }

  get segmentNameHash() {
    return hashOn(this.segmentStore.state.segments, s => s.Id, s => s.Label);
  }

  get segmentIds() {
    if (this.roleForm == null) return null;
    const settings = this.roleForm.SeasonalRoleSettings[this.activeSeason];
    if (settings == null) return null;

    return settings.ApplicationSegmentIds;
  }

  set segmentIds(v: string[]) {
    if (this.roleForm == null) return;
    const settings = this.roleForm.SeasonalRoleSettings[this.activeSeason];
    if (settings == null) return;

    settings.ApplicationSegmentIds = v;
  }

  private clearSegment() {
    this.segmentIds = [];
  }

  async openSegmentSelector() {
    const res = await this.popoverService.open<ApplicationSegmentSelectorPopoverResponse>(ApplicationSegmentSelectorPopover);

    if (res.canceled == false) {
      this.segmentIds = [res.result.id];
    }
  }

  get enableApplications() {
    return this.roleForm.Permissions.includes("ApplicationsModule");
  }

  get enableMarketing() {
    return this.roleForm.Permissions.includes("MarketingModule");
  }

  get canEditRole() {
    return this.permissionService.all("ManageRoles");
  }

  get isCalendarEnabled() {
    return this.ffs.isFeatureFlagEnabled("CalendarModule");
  }

  toggleRelated() {
    if (this.enableApplications) {
      // for (const key in this.permissions.applicationPermissions) {
      //   this.roleForm.Permissions.push(key);
      // }
      // for (const key in this.permissions.applicationSettingsPermissions) {
      //   this.roleForm.Permissions.push(key);
      // }

      // NOTE:  instead of adding all the permissions on toggle, just restore the previous state if the module was enabled
      if (
        this.originalModel &&
        this.originalModel.Permissions &&
        this.originalModel.Permissions.includes("ApplicationsModule")
      ) {
        this.roleForm.Permissions = this.originalModel.Permissions;
      }
    } else {
      this.roleForm.Permissions = this.roleForm.Permissions.filter(
        p =>
          p in this.permissions.applicationPermissions === false &&
          p in this.permissions.applicationSettingsPermissions === false &&
          p in this.permissions.applicationTrackPermissions === false &&
          p in this.permissions.applicationTrackSettingsPermissions === false
      );
    }
  }

  toggleMarketing() {
    if (this.enableMarketing) {
      if (
        this.originalModel &&
        this.originalModel.Permissions &&
        this.originalModel.Permissions.includes("MarketingModule")
      ) {
        this.roleForm.Permissions = this.originalModel.Permissions;
      }
    } else {
      this.roleForm.Permissions = this.roleForm.Permissions.filter(
        p => p in this.permissions.marketingPermissions === false
      );
    }
  }

  resolvePermissionDependencies({ check, trackDependencies, permission }): string[] {
    let permissionDependencies: string[];
    if (check) {
      permissionDependencies = trackDependencies[permission] ?? [];
      this.roleForm.Permissions.push(...permissionDependencies);
      this.roleForm.Permissions = Array.from(new Set(this.roleForm.Permissions));
    } else {
      permissionDependencies = Object.keys(trackDependencies).filter(p => trackDependencies[p].includes(permission));
      this.roleForm.Permissions = this.roleForm.Permissions.filter(p => !permissionDependencies.includes(p));
    }
    return permissionDependencies;
  }

  togglePermissionApplicationReview(permission: string, check: boolean): void {
    const trackDependencies: { [key: string]: string[] } = {
      'SubmitReviewsForOthers': ['ViewEvaluations'],
    };

    const permissionDependencies = this.resolvePermissionDependencies({
      check,
      trackDependencies,
      permission,
    });

    permissionDependencies.forEach(p => this.togglePermissionApplicationReview(p, check));
  }

  togglePermissionMarketing(permission: string, check: boolean): void {
    const trackDependencies: { [key: string]: string[] } = {
      'ManageMarketingSettings': [],
      'ViewOutreach': [],
      'ManageOutreach': ['ViewOutreach'],
      'ManageEmails': ['ViewOutreach', 'ViewEmailTemplates'],
      'SendEmails': ['ManageEmails'],
      'ManagePrintPieces': ['ViewOutreach', 'ViewPrintTemplates'],
      'SendPrintPieces': ['ManagePrintPieces'],
      'ManageTextMessages': ['ViewOutreach'],
      'SendTextMessages': ['ManageTextMessages'],
      'ManageVoiceMessages': ['ViewOutreach'],
      'SendVoiceMessages': ['ManageVoiceMessages'],
      'ViewEmailTemplates': [],
      'ManageEmailTemplates': ['ViewEmailTemplates'],
      'ViewPrintTemplates': [],
      'ManagePrintTemplates': ['ViewPrintTemplates'],
      'ViewPdfApproval': [],
      'ManagePdfApproval': ['ViewPdfApproval'],
    };

    const permissionDependencies = this.resolvePermissionDependencies({
      check,
      trackDependencies,
      permission,
    });

    permissionDependencies.forEach(p => this.togglePermissionMarketing(p, check));
  }

  togglePermissionContacts(permission: string, check: boolean): void {
    const trackDependencies: { [key: string]: string[] } = {
      'ManageEvents': [],
      'ViewEvents': [],
      'ManageEventRegistrations': [],
      'ManageAssignedConversations': [],
      'ViewAllConversations': [],
      'ManageAllConversations': ['ManageAssignedConversations', 'ViewAllConversations'],
    };
    const permissionDependencies = this.resolvePermissionDependencies({
      check,
      trackDependencies,
      permission,
    });

    permissionDependencies.forEach(p => this.togglePermissionContacts(p, check));
  }

  deleteRestriction(restriction) {
    let restrictions = this.roleForm.DataPolicies[this.activeSeason].ApplicationRestrictions;
    const idx = restrictions.indexOf(restriction);
    if (idx != -1) {
      restrictions.splice(idx, 1);
    }
  }

  public async getNumRoleUsers(): Promise<number> {
    const numRoleUsers = (await this.userRepo.list(`memberships.roleId:${this.roleForm.Id}`, null, null, 1, 1)).total;
    
    return numRoleUsers;
  }

  public async gotoUsers() {
    await dispatch(new SetFilterAction(`memberships.roleId:${this.roleForm.Id}`, this.roleForm.Id, "role", true));
    this.nav.navigate("/settings/users");
  }
}
