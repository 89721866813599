import { ContainerInstance } from "fw";
import { FormForType, formFor, Validators } from "fw-model";
const { required, wrap, isEmail } = Validators;

import { emailContactFormCreator } from "forms/email-contact";
import { isValidKey, isValidSendingEmail, noNewLines, requiredTextInHtml } from "forms/validators";
import { EmailTemplate, EmailTemplateSendEmail, EmailTemplateTestEmail } from "models/email-template";
import { FeatureFlagService } from "service/feature-flag";

export type FormForEmailTemplate = FormForType<EmailTemplate>;
export type FormForEmailTemplateSendEmail = FormForType<EmailTemplateSendEmail>;

export class UpdateEmailTemplateAction {
  constructor(public form: FormForEmailTemplate) {}
}

export class UpdateApplicationEmailTemplateAction {
  constructor(public form: FormForEmailTemplate) {}
}

export class DeleteCustomTemplateAction {
  constructor(public templateKey: string) {}
}
export class DeleteApplicationTemplateAction {
  constructor(public templateKey: string) {}
}

export class SendEmailTemplateAction {
  constructor(public form: FormForEmailTemplateSendEmail) {}
}

let dmarcSendingDomainFF: boolean = false;
const isDmarcSendingDomainFF = (): boolean => {
  if (!!dmarcSendingDomainFF) return true;
  dmarcSendingDomainFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled(
    "MarketingSendingDomainsDmarcOUT2435Feb2024"
  );
  return dmarcSendingDomainFF;
};

const requiredIf = () => {
  return (input, model) => {
    return model.IsCustomKey || model.Key !== "OrganizationMaster" ? required(input) : null;
  };
};

export const emailApplicationTemplateFormCreator = formFor(EmailTemplate, (s) => {
  s.requiredField((et) => et.Html, "Html Content");
  s.requiredField((et) => et.Subject, "Subject", wrap(noNewLines, requiredIf()));
  s.requiredField((et) => et.Name, "Name");
  s.requiredField(
    (et) => et.Key,
    "Template Key",
    (v) => v.if((t) => t.IsCustomKey, isValidKey)
  );
  s.requiredField((et) => et.Text, "Text Content");
  s.field((et) => et.CustomEmailTemplateTypeCode, "Type Code");
  s.form((et) => et.From, "From", emailContactFormCreator());
  s.form((et) => et.ReplyTo, "Reply To", emailContactFormCreator());
});

export const emailTemplateFormCreator = formFor(EmailTemplate, (s) => {
  s.requiredField((et) => et.Html, "Html Content");
  s.requiredField((et) => et.Text, "Text Content");
  s.field((et) => et.Subject, "Subject", wrap(noNewLines, requiredIf()));
  s.field((et) => et.Name, "Name");
  s.requiredField(
    (et) => et.Key,
    "Template Key",
    (v) => v.if((t) => t.IsCustomKey, isValidKey)
  );
  s.field((et) => et.Cc, "CC");
  s.field((et) => et.Bcc, "BCC");
  s.field((et) => et.Template, "Template");

  s.form((et) => et.From, "From", emailContactFormCreator());
  s.form((et) => et.ReplyTo, "Reply To", emailContactFormCreator());
});

export const oneOffEmailTemplateFormCreator = formFor(EmailTemplate, (s) => {
  s.requiredField((et) => et.Html, "Html Content");
  s.requiredField((et) => et.Text, "Text Content");
  s.field((et) => et.Subject, "Subject", wrap(noNewLines, requiredIf()));
  s.field((et) => et.Name, "Name");
  s.field((et) => et.Cc, "CC");
  s.field((et) => et.Bcc, "BCC");
  s.field((et) => et.Template, "Template");

  s.form((et) => et.From, "From", emailContactFormCreator(true, true));
  s.form((et) => et.ReplyTo, "Reply To", emailContactFormCreator());
});

export const emailEditorFormCreator = formFor(EmailTemplate, (s) => {
  s.field((et) => et.Html, "Html Content", wrap(requiredTextInHtml));
  s.field((et) => et.Text, "Text Content");
  s.field((et) => et.Subject, "Subject", wrap(noNewLines, requiredIf()));
  s.field((et) => et.Name, "Name");
  s.field((et) => et.Cc, "CC");
  s.field((et) => et.Bcc, "BCC");
  s.field((et) => et.Template, "Template");

  s.form((et) => et.From, "From", emailContactFormCreator(true, true));
  s.form((et) => et.ReplyTo, "Reply To", emailContactFormCreator(true, true));
});

export const emailTemplateTestEmailFormCreator = formFor(EmailTemplateTestEmail, (s) => {
  s.requiredField((et) => et.EmailAddress, "Email Address", wrap(isEmail));
});

export const emailTemplateSendEmailFormCreator = formFor(EmailTemplateSendEmail, (s) => {
  s.requiredField((et) => et.Html, "Html Content");
  s.field((et) => et.Text, "Text Content");
  s.requiredField((et) => et.Subject, "Subject", wrap(noNewLines));
  s.requiredField((et) => et.To, "To");
  if (isDmarcSendingDomainFF()) {
    s.requiredField((f) => f.FromAddress, "From Address", wrap(isValidSendingEmail));
    s.requiredField((f) => f.ReplyToAddress, "Reply To Address", wrap(isValidSendingEmail));
  } else {
    s.requiredField((f) => f.FromAddress, "From Address");
    s.requiredField((f) => f.ReplyToAddress, "Reply To Address");
  }
  s.requiredField((f) => f.FromName, "From Name");
  s.requiredField((f) => f.ReplyToName, "Reply To Name");
  s.field((et) => et.Cc, "CC");
  s.field((et) => et.Bcc, "BCC");
  s.field((et) => et.Tokens, "Tokens");
  s.formArray((et) => et.TestEmails, "Test Emails", emailTemplateTestEmailFormCreator);
  s.field((et) => et.EmailAttachments, "Email Attachments");
  s.field((et) => et.AttachmentFileIds, "Attachment File Ids");
});
