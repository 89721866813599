import { inject, prop, ComponentEventBus } from "fw";

export type ItemState = "add" | "remove" | "none";

@inject
export class ItemAssignmentContext {
  @prop(null) totalIds!: number;
  @prop("Search...") searchText!: string;
  @prop(null) itemStateHash!: { [userId: string]: ItemState };
  @prop(null) assignmentContext!: { [userId: string]: number };
  @prop(() => []) itemPool!: { id: string; name: string }[];
  @prop(true) showSearch!: boolean;
  @prop(false) cleanupUnused: boolean;

  private searchTerm = "";
  private localItems: { [id: string]: any } = {};

  constructor(private ceb: ComponentEventBus) { }

  toggleItem(itemId: string, item: any) {
    this.localItems = {
      ...this.localItems,
      [itemId]: item,
    };

    switch (this.itemStateHash[itemId]) {
      case "none":
        if (this.assignmentContext[itemId] == this.totalIds) {
          this.itemStateHash[itemId] = "remove" as ItemState;
        } else {
          this.itemStateHash[itemId] = "add" as ItemState;
        }
        break;

      case "add":
        if (this.assignmentContext[itemId] == null) {
          this.itemStateHash[itemId] = "none" as ItemState;
        } else {
          this.itemStateHash[itemId] = "remove" as ItemState;
        }
        break;

      case "remove":
        this.itemStateHash[itemId] = "none" as ItemState;
        break;
    }

    if (this.cleanupUnused && this.itemStateHash[itemId] == 'none' && !this.assignmentContext[itemId]) {
      delete this.itemStateHash[itemId];
    }

    this.ceb.dispatch("update:itemStateHash", { ...this.itemStateHash });
  }


  get items() {
    const lowerTerm = this.searchTerm.toLowerCase();

    if (lowerTerm.length == 0) {
      return this.itemPool;
    }

    return (this.itemPool || []).filter(u => {
      return u.name.toLowerCase().indexOf(lowerTerm) >= 0;
    });
  }

}
