import { prop, ComponentEventBus, inject } from "fw";
import { generateDataId } from "helpers/data-id-gen";

let uniqueId = 0;

@inject
export class ConcealedTextField {
  @prop(null) public label!: string;
  @prop("") public placeholder!: string;
  @prop(null) public value!: string;
  @prop(null) public decryptedValue!: string;
  @prop(null) public validation!: string;
  @prop(null) public maxlength!: number;
  @prop(null) public readonly!: boolean;
  @prop(null) public setfocus!: boolean;
  @prop(false) public disabled!: boolean;
  @prop(false) public floatingLabel!: string;
  @prop(null) public ariaLabel!: string;
  @prop(null) public helpText!: string;
  @prop(null) public autocomplete!: boolean;
  @prop(null) public meta!: string;
  @prop(() => {}) public decrypt: (text: string) => Promise<string>;

  public uniqueId = uniqueId++;

  public isFocused: boolean = false;
  public inputEl;

  public isRevealed: boolean = false;
  public isRevealing: boolean = false;
  public isRevealingError: boolean = false;

  constructor(private ceb: ComponentEventBus) {}

  public attached() {
    if (!this.value || this.decryptedValue) {
      this.isRevealed = true;

      if (this.setfocus) {
        setTimeout(() => this.inputEl.focus(), 50);
      }
    }
  }

  public valueChanged() {
    this.isRevealingError = false;
    this.ceb.dispatch("update:decrypted-value", this.value);

    if (!this.value) {
      this.isRevealed = true;
    }
  }

  public makeId() {
    return `${generateDataId(null, this.label)}-tf-${this.uniqueId}`;
  }

  public async reveal() {
    if (!this.decrypt) {
      return;
    }

    this.isRevealing = true;
    this.isRevealingError = false;

    try {
      const decryptedValue = await this.decrypt(this.value);
      this.ceb.dispatch("update:decrypted-value", decryptedValue);
    } catch (err) {
      this.isRevealingError = true;
    } finally {
      if (!this.isRevealingError) {
        this.isRevealed = true;
      }
      this.isRevealing = false;
    }
  }

  public get localDecryptedValue(): string {
    return this.decryptedValue;
  }

  public set localDecryptedValue(value: string) {
    this.onInput(value);
  }

  public decryptedValueChanged() {
    this.isRevealed = !this.value || !!this.decryptedValue;
  }

  public onFocus(e) {
    this.isFocused = true;
    this.ceb.dispatch("focus", e);
  }

  public onBlur(e) {
    this.isFocused = false;
    this.ceb.dispatch("blur", e);
  }

  public onInput(value) {
    this.ceb.dispatch("update:value", value);
  }
  public onChange() {
    this.ceb.dispatch("change");
  }
  public onKeyUp() {
    this.ceb.dispatch("keyup");
  }
  public onKeyEvent(event) {
    this.ceb.dispatch("keypress", event);
  }
}
