import { inject, ComponentEventBus, prop } from "fw";

@inject
export class GridBulkMenu {
  @prop('') className: string;
  @prop(0) selected: number;
  @prop(false) selectAll: boolean;
  @prop(0) total: number;

  constructor(private ceb: ComponentEventBus) {}

  public get selectedCount(): number {
    return this.selectAll ? this.total : this.selected;
  }

  public async deselectAll() {
    this.ceb.dispatch("unchecked");
  }
}
