import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LocalStorageCache } from "caching";
import { LogoutAction, StartAction, StopImpersonatingAction } from "./actions";
import { InterviewPortalRepository } from "network/interview-portal-repository";
import { InterviewPortal as InterviewPortalModel } from "models/interview-portal";
import { InterviewPortalFormType } from "forms/interview-portal";
import { FormErrorHandling } from "./error-handling";

interface InterviewPortalShape {
  userId: string;
  organizationId: null,
  interviewPortal: InterviewPortalModel,
  loading: boolean;
  loaded: boolean;
}

interface SavedState {
  interviewPortal: InterviewPortalModel;
}

export class EnsureInterviewPortalAction {
  constructor(public refresh: boolean = false) { }
}

export class UpdateInterviewPortalAction {
  public interviewPortal: InterviewPortalModel = null;
  constructor(public form: InterviewPortalFormType) { }
}

export class RefreshInterviewPortalAction {
  constructor() { }
}

@inject
export class InterviewPortalStore extends Store<InterviewPortalShape> {
  public constructor(
    private interviewPortalRepo: InterviewPortalRepository,
    private cache: LocalStorageCache
  ) {
    super();
  }

  defaultState() {
    return {
      userId: null,
      organizationId: null,
      interviewPortal: null,
      loading: false,
      loaded: false
    };
  }

  @handle(StartAction)
  private handleStart(s: StartAction) {
    this.setState(s => this.defaultState());

    const defaultState = this.defaultState();
    defaultState.organizationId = s.context.Organization.Id;
    defaultState.userId = s.context.Me.Id;

    this.setState(s => defaultState);
    this.restoreState();
  }

  private restoreState() {
    if (!this.state.organizationId) {
      return;
    };

    const savedState = this.cache.get<SavedState>(this.cacheKey);
    if (!savedState) {
      return;
    }

    this.setState(state => ({
      ...state,
      interviewPortal: { ...savedState.interviewPortal }
    }));
  }

  private get cacheKey(): string {
    return `${this.state.organizationId}:${this.state.userId}-interview-portal`;
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  private saveState() {
    if (!this.state.organizationId) {
      return;
    }

    this.cache.set<SavedState>(
      this.cacheKey,
      {
        interviewPortal: { ...this.state.interviewPortal }
      },
    );
  }

  @handle(EnsureInterviewPortalAction)
  async handleEnsureInterviewPortalAction(action: EnsureInterviewPortalAction) {
    if ((this.state.loaded && !action.refresh) || this.state.loading) {
      return;
    }

    this.setState(state => ({
      ...state,
      loading: true
    }));

    const res = await this.interviewPortalRepo.get();

    this.setState(state => ({
      ...state,
      id: res.Id,
      interviewPortal: { ...res },
      loaded: true,
      loading: false
    }));

    this.saveState();
  }

  @handle(UpdateInterviewPortalAction, FormErrorHandling)
  private async handleUpdateInterviewPortalAction(action: UpdateInterviewPortalAction) {
    this.setState(state => ({
      ...state,
      loading: true,
    }));

    const interviewPortal = await this.interviewPortalRepo.put(action.form.updatedModel());

    this.setState(state => ({
      ...state,
      interviewPortal: { ...interviewPortal },
      loading: false
    }));

    action.interviewPortal = interviewPortal;
    this.saveState();
  }

  @handle(RefreshInterviewPortalAction, FormErrorHandling)
  private async handleRefreshInterviewPortalAction(action: RefreshInterviewPortalAction) {
    this.setState(state => ({
      ...state,
      loading: true,
    }));

    await this.interviewPortalRepo.refresh();

    this.setState(state => ({
      ...state,
      loading: false
    }));
  }
}
