import { inject } from "fw";
import { createFrom } from "fw-model";

import { DialogController } from "fw-dialog";
import { OrganizationModuleService } from "service/organization-module";

import {
    WidgetDefinition,
    ReportChartDefinition,
    ReportChartType,
    AggregationType,
    ChartDataSource,
    AssignmentDefinition,
    GoalsDefinition,
    TasksDefinition,
    GridViewDefinition,
    FunnelDefinition,
} from "shared/report-runtime";

@inject
export class AddTileDialog {
  private currentTab: "applications" | "contacts" | "tasks" = "applications";

  constructor(
    private controller: DialogController<WidgetDefinition>,
    private organizationModuleService: OrganizationModuleService
  ) { }

  public attached() {
    if(!this.organizationModuleService.hasReviewFeature) [
      this.currentTab = "contacts"
    ]
  }

  okWithReportChart(ChartType: ReportChartType) {
    let dataSource: ChartDataSource = ChartDataSource.Admissions;
    switch (this.currentTab) {
      case "applications":
        if (this.organizationModuleService.hasReviewFeature === false) {
          // TODO: need to surface an issue here
        }
        break;
      case "contacts":
        dataSource = ChartDataSource.Contacts;
        break;
      case "tasks":
        dataSource = ChartDataSource.Tasks;
        break;
      default:
        // TODO: need to surface an issue here
        break;
    }
    this.controller.ok(
      createFrom(ReportChartDefinition, {
        ChartType,
        DataSource: dataSource
      } as Partial<ReportChartDefinition>)
    );
  }

  ok(type: string) {
    const DataSource: ChartDataSource = this.currentTab === "contacts" ?
      ChartDataSource.Contacts : ChartDataSource.Admissions;

    switch (type) {
      case "count":
        this.okWithReportChart(ReportChartType.Count);
        return;
      case "pie":
        this.okWithReportChart(ReportChartType.Pie);
        return;
      case "chevron":
        this.okWithReportChart(ReportChartType.Chevron);
        return;
      case "heat":
        this.okWithReportChart(ReportChartType.Table);
        return;
      case "stack":
        this.okWithReportChart(ReportChartType.Stack);
        return;
      case "bar":
        this.okWithReportChart(ReportChartType.Bar);
        return;

      case "pipeline":
        this.controller.ok(
          createFrom(ReportChartDefinition, {
            Name: "Phase Pipeline",
            ChartType: ReportChartType.Pipeline,
            PrimaryIndependentVariable: {
              AggregationType: AggregationType.Terms,
              Path: "phaseId",
              LabelTransform: "phase",
              Label: "Phase"
            },
            ClientData: JSON.stringify({
              isPipeline: true
            })
          } as Partial<ReportChartDefinition>)
        );
        return;

      case "assignment":
        this.controller.ok(
          createFrom(AssignmentDefinition, {
            Name: "Assignment"
          } as Partial<AssignmentDefinition>)
        );

        break;

      case "progress":
        this.controller.ok(
          createFrom(ReportChartDefinition, {
            Name: "Progress",
            ChartType: ReportChartType.Progress,
            PrimaryIndependentVariable: {
              AggregationType: AggregationType.Terms,
              Path: "phases.current.assigned_team_id",
              LabelTransform: "team",
              Label: "Object"
            },
            SecondaryIndependentVariable: {
              AggregationType: AggregationType.Terms,
              Path: "phases.current.evaluation_complete",
              LabelTransform: "boolean",
              Label: "Complete"
            },
            ClientData: JSON.stringify({
              isProgress: true,
              phase: "current",
              isTeam: true
            })
          } as Partial<ReportChartDefinition>)
        );

      case "goals":
        this.controller.ok(
          createFrom(GoalsDefinition, {
            Name: "Goals",
            DataSource
          } as Partial<GoalsDefinition>)
        );

        break;


      case "funnel":
        this.controller.ok(
          createFrom(FunnelDefinition, {
            Name: "Funnel",
            DataSource
          } as Partial<FunnelDefinition>)
        );

        break;

      case "tasks":
        this.controller.ok(
          createFrom(TasksDefinition, {
            Name: "Tasks"
          } as Partial<TasksDefinition>)
        );

        break;

      case "grid-view":
        let name = "Grid View";
        let sort = null;
        const columns = [];

        switch (this.currentTab) {
          case "applications":
            name = "Applications";
            break;
          case "contacts":
            name = "Contacts";
            break;
          case "tasks":
            name = "Tasks";
            sort = "dateDueUtc";
            columns.push(
              { Label: "Description", Path: "description", Sort: "description" },
              { Label: "Due Date", Path: "dateDueUtc", Sort: "dateDueUtc" }
            );
            break;
        }

        this.controller.ok(
          createFrom(GridViewDefinition, {
            Name: name,
            Columns: columns,
            Sort: sort,
          } as Partial<GridViewDefinition>)
        );

        break;
    }
  }
}
