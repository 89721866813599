import { inject, prop } from "fw";

export enum TooltipContentFlowDirection {
  Right = 'right',
  Left = 'left',
}

class DomPositionData {
  popoverHeight: number = null;
  popoverWidth: number = null;
  targetBottom: number = null;
  targetLeft: number = null;
  targetHeight: number = null;
  targetWidth: number = null;
  viewPortHeight: number = null;
  viewPortWidth: number = null;
}

@inject
export class Tooltip {
  @prop(TooltipContentFlowDirection.Right) flow: TooltipContentFlowDirection;

  public isOpen: boolean = false;
  public main: HTMLElement;
  private $refs: any;
  private $scopedSlots: any;

  private getDomPositionData(): DomPositionData {
    const targetElement = this.main;
    const {
      left: targetLeft,
      bottom: targetBottom,
      height: targetHeight,
      width: targetWidth,
    } = targetElement.getBoundingClientRect();

    const tooltipElement = this.$refs.tooltip;
    const popoverHeight = tooltipElement.clientHeight;
    const popoverWidth = tooltipElement.clientWidth;
    const viewPortHeight = window?.innerHeight;
    const viewPortWidth = window?.innerWidth;

    return {
      popoverHeight,
      popoverWidth,
      targetBottom,
      targetHeight,
      targetLeft,
      targetWidth,
      viewPortHeight,
      viewPortWidth,
    };
  }

  private positionTooltipContent(
    positions: DomPositionData,
    flow: TooltipContentFlowDirection = TooltipContentFlowDirection.Right,
  ) {
    const {
      popoverHeight,
      popoverWidth,
      targetBottom,
      targetHeight,
      targetLeft,
      targetWidth,
      viewPortHeight,
      viewPortWidth,
    } = positions;
    const verticalPadding = 4;
    let [anchorLeft, anchorTop] = [0, targetHeight + verticalPadding];

    if (
      TooltipContentFlowDirection.Left === flow
      || (targetLeft + popoverWidth > viewPortWidth)
    ) {                                                             // if flow left designated or target too far right
      anchorLeft = targetWidth - popoverWidth;                      // ...anchor right of target, expand left
    }

    if (targetBottom + popoverHeight > viewPortHeight) {            // if too far down
      anchorTop = -(popoverHeight + verticalPadding);               // ...anchor above target
    }

    const tooltipElement = this.$refs.tooltip;
    tooltipElement.style.left = `${anchorLeft}px`;
    tooltipElement.style.top = `${anchorTop}px`;
  }

  public showTooltipContent(): void {
    const tooltipContent = this.$refs.tooltip?.textContent;
    if (!tooltipContent) {
      return;
    }
    this.isOpen = true;
    this.positionTooltipContent(this.getDomPositionData(), this.flow);
  }

  public hideTooltipContent(): void {
    this.isOpen = false;
  }

  public mouseOver(): void {
    this.showTooltipContent();
  }

  public mouseOut(): void {
    this.hideTooltipContent();
  }

  public onFocus(): void {
    this.showTooltipContent();
  }

  public onBlur(): void {
    this.hideTooltipContent();
  }

  public get hasDefaultSlot () {
    return !!this.$scopedSlots.default().length;
  }
}
