import { inject, prop, ComponentEventBus } from "@derekpitt/fw"
import { NumberQuestionType } from "../../enums";
import { Question, FormAnswer } from "../../models";

@inject
export class NumberType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledby;

  constructor(private ecb: ComponentEventBus) { }

  change() {
    this.answer.Number = this.answer.Text != null ? parseFloat(this.answer.Text) : null;
    this.ecb.dispatch("answer-changed");
  }

  get valueText() {
    if(this.answer != null && this.answer.Number != null) {
      return this.answer.Number.toFixed(this.question.Options.Number.Type == NumberQuestionType.Integer ? 0 : 2 );
    } else {
      return ""
    }
  }

}

