import { prop, inject, ComponentEventBus } from "fw";

@inject
export class Information {

  @prop(null) text: string;

  constructor(
    private ceb: ComponentEventBus
  ) {}

  public close() {
    console.log('hello');
    this.ceb.dispatch('close', null);
  }
}
