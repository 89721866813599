import { ContainerInstance, inject, NetworkException } from "fw";
import { createFrom, createFromProperties } from "fw-model";
import { Store, dispatch, handle } from "fw-state";
import { LocalStorageCache } from "caching";
import { capitalize, difference, forEach, throttle } from "lodash-es";

import { once } from "helpers/once";

import {
  LogoutAction,
  StartAction,
  ContactModelChangedAction,
  SelectedContactTypeChangedAction,
  RefreshFileProviderAction,
  WatchTaskAction,
  ContactOrganizationModelChangedAction
} from "./actions";
import { ContactRepository, AddNewContactPostArgs } from "network/contact-repository";
import { ContactOrganizationRepository } from "network/contact-organization-repository";
import { ExportRepository } from "network/export-repository";
import { Contact } from "models/contact";
import { ExportFormatTypeCode } from "models/export-definition";
import { GroupFilter, FilterContext } from "models/filter-setup";
import { GridColumn } from "models/grid-column";
import { TaskRequest } from "models/task-request";
import {
  ContactTypeDefinition,
  ICustomFieldDefinition,
  ContactOrganization,
  EmptyContactOrganization,
  getContactTypeOrDefault,
  getDefaultContactType,
  CustomFieldType,
} from "models/contact-organization";
import { AddNewContactAction } from "views/contacts/add-new-contact";
import { ContactTypeAddedAction, ContactTypeDeletedAction } from "./current-contact-organization";
import { SetSelectedContactTypeAction, SetGridColumnsAction, SetCurrentSelectedContactTypeFilterAction } from "./current-user-settings";
import { ContactsFilter } from "service/contacts-filter";
import { ContactsService } from "service/contacts";
import {
  EntityChanged,
  WebSocketMessageAction,
  filterEntityChangedMessage
} from './filter-websocket-message';
import { EntitySelection, EntitySelectionPatches } from "models/application-client-model";
import { DataDictionaryRefreshedAction, DataDictionaryStore } from "./data-dictionary";
import { FeatureFlagService } from "service/feature-flag";
import { Operation } from "fast-json-patch";
import { ContactActivityRepository } from "network/contact-activity-repository";
import { ActivityResult } from "models/contact-activity";

export type ContactTypeFilters = { [contactType: string]: GroupFilter };

interface ContactStoreShape {
  organizationId: string;
  organization: ContactOrganization;
  userId: string;
  roleId: string;
  selectedContactType: ContactTypeDefinition;
  contacts: Contact[];
  columns: GridColumn[];
  totalCount: number;
  firstPageLoaded: boolean;
  loaded: boolean;
  errorLoading: boolean;
  errorMessage: string;
  hasErrors: boolean;
  pageSize: number;
  currentPage: number;
  pageStartNumber: number;
  pageEndNumber: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  canAdvancePage: boolean;
  sort: string;
  segmentId: string;
  filter: GroupFilter;
  filterRequiresReindex: boolean;
  addlFilter: string;
  selectAll: boolean;
  selectedIds: string[];
  filtersByContactType: ContactTypeFilters;
  mapView: boolean;
  contactInPreview: Contact;
}

export class EnsureContactStoreAction {
  constructor(public forceRefresh: boolean = false) { }
}

export class ContactIdsSelectedAction {
  constructor(public ids: string[]) { }
}

export class ContactsNextPage { }
export class ContactsPreviousPage { }
export class ContactsFirstPage { }

export class SetContactsAdditionalFilterAction {
  constructor(public addlFilter: string) { }
}

export class SelectContactSegmentAction {
  constructor(public segmentId: string, public clearOthers = false) { }
}

const MAX_SKIP = 2000;
const DEFAULT_PAGE_SIZE = 20;

export class ContactsGridColumnsChangedAction {
  constructor(public columns: GridColumn[]) { }
}

export class SetContactsFilterAction {
  // clear others will set other surrounding state
  // to null so the filter container can stand alone
  constructor(
    public contactTypeKey: string,
    public filter: GroupFilter,
    public clearOthers: boolean = false,
    public isDisabledLoading: boolean = false
  ) { }
}

export class RefreshContactsGridAction {
  constructor(public isDisabledLoading: boolean = false) { }
}

export class ToggleContactsSortAction {
  constructor(public sort: string) { }
}

export class ToggleContactsSelectAllAction {
  constructor(public selectAll: boolean) { }
}

export class DeleteContactsAction {
  constructor() { }
}

export class MergeContactsAction {
  constructor() { }
}

export class ExportContactsAction {
  public taskId: string = null;

  constructor(
    public selection: EntitySelection,
    public format = ExportFormatTypeCode.JSON,
    public exportDefinition = null,
    public exportDefinitionId: string = null,
    public fileProviderId: string = null,
    public fileProviderFolder: string = null,
  ) { }
}

export class BulkUpdateContactPropertiesAction {

  public selectionPatch: EntitySelectionPatches;

  constructor(
    public selection: EntitySelection,
    public operations: Operation[]
  ) { this.selectionPatch = new EntitySelectionPatches(selection, operations); }
}

export class BulkContactCommentAction {
  constructor(public selection: EntitySelection, public commentText: string) { }
}

export class ToggleMapViewAction {
  constructor(public enableMap: boolean = null) { }
}

export class SetContactInPreview {
  constructor(public contact: Contact) { }
}

type SavedContactTypeState = {
  segmentId: string;
  filter: GroupFilter;
  addlFilter: string;
  sort: string;
  currentPage: number;
  pageStartNumber: number;
  pageEndNumber: number;
  pageSize: number;
  contactInPreview: Contact;
};

type SavedOrganizationState = {
  filtersByContactType: ContactTypeFilters;
};

@inject
export class ContactStore extends Store<ContactStoreShape> {
  private throttledUpdate: Function = () => { };

  constructor(
    private contactActivityRepo: ContactActivityRepository,
    private contactRepo: ContactRepository,
    private contactOrganizationRepository: ContactOrganizationRepository,
    private exportRepository: ExportRepository,
    private cache: LocalStorageCache,
    private filtersCache: ContactsFilter,
    private contactsService: ContactsService,
    private ffs: FeatureFlagService
  ) {
    super();
    this.throttledUpdate = throttle(() => this.update(), 5000);
  }


  defaultState() {
    const emptyFilterContainer = createFrom(GroupFilter, {
      operation: "AND",
      filters: [],
    });

    return {
      organizationId: null,
      organization: null,
      userId: null,
      roleId: null,
      selectedContactType: null,
      contacts: [],
      columns: this.defaultColumns,
      totalCount: 0,
      firstPageLoaded: false,
      loaded: false,
      errorLoading: false,
      errorMessage: null,
      hasErrors: false,
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      pageStartNumber: 1,
      pageEndNumber: DEFAULT_PAGE_SIZE,
      hasPreviousPage: false,
      hasNextPage: false,
      canAdvancePage: false,
      sort: "last first company",
      segmentId: null,
      filter: emptyFilterContainer,
      filterRequiresReindex: false,
      addlFilter: "",
      selectAll: false,
      selectedIds: [],
      filtersByContactType: {},
      mapView: false,
      contactInPreview: null
    };
  }

  get defaultColumns() {
    return this.contactsService
      ? this.contactsService.defaultGridColumns
      : [];
  }

  get contactTypeKey(): string {
    return this.state.selectedContactType?.key;
  }

  get contactTypeCacheKey(): string {
    return `${this.state.organizationId}:${this.state.userId}:${this.contactTypeKey}-contacts-store`;
  }

  get organizationCacheKey(): string {
    return `${this.state.organizationId}:${this.state.userId}-contacts-store`;
  }

  private contactTypeCacheKeyByKey(key: string): string {
    return `${this.state.organizationId}:${this.state.userId}:${key}-contacts-store`;
  }

  saveState() {
    if (this.state.organizationId == null) return;

    const savedType: SavedContactTypeState = {
      segmentId: this.state.segmentId,
      filter: this.state.filter,
      addlFilter: this.state.addlFilter,
      sort: this.state.sort,
      currentPage: this.state.currentPage,
      pageStartNumber: this.state.pageStartNumber,
      pageEndNumber: this.state.pageEndNumber,
      pageSize: this.state.pageSize,
      contactInPreview: this.state.contactInPreview
    };
    this.cache.set<SavedContactTypeState>(this.contactTypeCacheKey, savedType);

    const savedOrganization: SavedOrganizationState = {
      filtersByContactType: this.state.filtersByContactType,
    };
    this.cache.set<SavedOrganizationState>(this.organizationCacheKey, savedOrganization);
  }

  restoreState() {
    if (this.state.organizationId == null) return;

    const savedType = this.cache.get<SavedContactTypeState>(this.contactTypeCacheKey);
    if (savedType != null) {
      this.setState(state => ({
        ...state,
        segmentId: savedType.segmentId,
        filter: createFrom(GroupFilter, savedType.filter),
        addlFilter: savedType.addlFilter || "",
        sort: savedType.sort || "last first company",
        currentPage: savedType.currentPage || 1,
        pageStartNumber: savedType.pageStartNumber || 1,
        pageEndNumber: savedType.pageEndNumber || DEFAULT_PAGE_SIZE,
        pageSize: savedType.pageSize || DEFAULT_PAGE_SIZE,
        contactInPreview: savedType.contactInPreview
      }));
    }

    const savedOrganization = this.cache.get<SavedOrganizationState>(this.organizationCacheKey);
    if (savedOrganization != null) {
      this.setState(state => ({
        ...state,
        filtersByContactType: { ...state.filtersByContactType, ...savedOrganization.filtersByContactType ? createFromProperties(GroupFilter, savedOrganization.filtersByContactType) : {} },
      }));
    }
  }

  getWholeFilter() {
    const dataDictionaryStore = ContainerInstance.get(DataDictionaryStore);
    const filterContext: FilterContext = {
      contactType: this.state.selectedContactType?.key,
      fields: dataDictionaryStore.state.fields
    };
    let filter = this.state.filter.toFilterString(filterContext);

    if (this.state.segmentId != null) {
      if (filter.length > 0) {
        filter = `(@include:${this.state.segmentId}) AND (${filter})`;
      } else {
        filter = `@include:${this.state.segmentId}`;
      }
    }

    if (this.state.addlFilter.trim().length > 0) {
      if (filter.length > 0) {
        filter = `(${filter}) AND (${this.state.addlFilter.trim()})`;
      } else {
        filter = this.state.addlFilter.trim();
      }
    }

    const typeFilter: string = this.state.selectedContactType ? `type:${this.state.selectedContactType.key}` : null;

    if (!filter && !typeFilter)
      return null;

    if (typeFilter)
      return filter.length > 0 ? `${typeFilter} ${filter}` : typeFilter;

    return filter;
  }

  private get clientModelAggs(): string {
    return "sum:field_error_count";
  }

  update = async () => {
    try {
      const { currentPage, pageSize, sort, filterRequiresReindex, contactInPreview } = this.state;
      if (filterRequiresReindex) {
        return;
      }
      const contactType = this.contactTypeKey;

      const filter = this.getWholeFilter();

      const countResult = await this.contactRepo.count(null, filter, null, contactType);
      const totalCount = countResult.total;

      const pageStartNumber = ((currentPage - 1) * pageSize) + 1;
      const pageEndNumber = pageStartNumber + (pageSize - 1);
      const hasNextPage = pageEndNumber < totalCount;
      const hasPreviousPage = pageStartNumber > 1;
      const canAdvancePage = pageEndNumber + 1 < MAX_SKIP;

      const res = await this.contactRepo.list(null, filter, this.clientModelAggs, sort, currentPage, pageSize, contactType);
      const contacts = res.results;
      if (contactInPreview && !contacts.find(c => c.id === contactInPreview.id)) {
        const countResult = await this.contactRepo.count(null, `${filter} (id:${contactInPreview.id})`, null, contactType);
        Object.assign(contactInPreview, { stale: !countResult.total});
      }

      const { hasErrors } = this.processAggregations(res.aggregations);

      this.setState(state => ({
        ...state,
        firstPageLoaded: true,
        errorLoading: false,
        errorMessage: null,
        hasErrors: hasErrors,
        loaded: true,
        contacts,
        totalCount,
        pageStartNumber: Math.max(pageStartNumber, 0),
        pageEndNumber: Math.min(pageEndNumber, totalCount),
        hasNextPage,
        hasPreviousPage,
        canAdvancePage,
        contactInPreview: state.contactInPreview?.id === contactInPreview?.id ? contactInPreview : state.contactInPreview
      }));
    } catch (err) {
      if (err instanceof NetworkException && err.statusCode == 400) {
        // const organization = await this.contactOrganizationRepository.getById(this.state.organizationId);
        if (await this.ensureValidState(this.state.organization, this.state.selectedContactType) === false) {
          return;
        }
      }

      this.setState(state => ({ ...state, errorLoading: true, errorMessage: err.result?.message }));
    }
  }

  private processAggregations(aggregations) {
    let hasErrors = false;
    if (aggregations["sum_field_error_count"] != null) {
      hasErrors = aggregations["sum_field_error_count"].value > 0;
    }
    return { hasErrors };
  }

  @handle(StartAction)
  async handleStartAction(action: StartAction) {
    if (!action?.context)
      return;

    const initialState = this.defaultState();
    const organization = action.context.ContactOrganization || EmptyContactOrganization;
    const organizationId = organization.id;

    initialState.organizationId = organizationId;
    initialState.organization = organization;
    initialState.userId = action.context.Me.Id;
    const roleId = action.context.Me.Memberships?.find(m => m.OrganizationId === organizationId)?.RoleId;
    initialState.roleId = roleId;

    let contactType: ContactTypeDefinition = null;
    if (organization.contact_types.length === 1)
      contactType = organization.contact_types[0];
    else {
      const contactTypeKey = action.context.UserSeasonSettings.Settings["contactsTypeSelected"];

      contactType = getContactTypeOrDefault(organization, contactTypeKey, roleId);

    }

    const gridColumnsKey: string = (contactType
      ? `contactsGridColumns${capitalize(contactType.key)}`
      : "contactsGridColumnsAll");

    const columns = action.context.UserSeasonSettings.Settings[gridColumnsKey] || this.defaultColumns;
    let filtersByContactType: ContactTypeFilters = {};

    organization.contact_types.forEach(t => {
      filtersByContactType[t.key] = new GroupFilter();
    });
    initialState.filtersByContactType = filtersByContactType;
    initialState.selectedContactType = contactType;
    initialState.columns = columns;

    this.setState(s => initialState);
    this.restoreState();
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureContactStoreAction)
  async handleEnsureContactStoreAction(action: EnsureContactStoreAction) {
    if (action.forceRefresh) {
      await this.loadContactsData();
    }

    await once("ensure-contacts", async () => {
      await this.loadContactsData();
    });
  }

  async loadContactsData() {
    if (await this.ensureValidState(this.state.organization, this.state.selectedContactType)) {
      this.update();
      this.saveState();
    }
  }

  @handle(ContactOrganizationModelChangedAction)
  async handleContactOrganizationModelChangedAction(action: ContactOrganizationModelChangedAction) {
    this.setState(state => ({
      ...state,
      organization: action.organization,
    }));
  }

  @handle(ContactIdsSelectedAction)
  handleContactIdsSelectedAction(action: ContactIdsSelectedAction) {
    this.setState(state => ({
      ...state,
      selectedIds: action.ids
    }));
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("PersistentContact"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    // TODO: Can be further optimized by sending down the contact type  with the entity change message.
    if (this.state.firstPageLoaded && (!this.state.selectedIds || this.state.selectedIds.length === 0)) {
      this.throttledUpdate();
    }
  }

  @handle(SelectedContactTypeChangedAction)
  async handleSelectedContactTypeChangedAction(action: SelectedContactTypeChangedAction) {
    const key = this.state.selectedContactType?.key;
    if (action.type == key)
      return;

    const contactType = this.state.organization.contact_types.find(t => t.key == action.type);
    this.setState(state => ({
      ...state,
      firstPageLoaded: false,
      loaded: false,
      currentPage: 1,
      selectedContactType: contactType,
      filter: action.filter,
      columns: action.contactsColumns,
      selectAll: false,
    }));
    this.restoreState();

    if (action.segmentId !== undefined) {
      this.setState(state => ({ ...state, segmentId: action.segmentId }));
    }

    if (await this.ensureValidState(this.state.organization, contactType)) {
      await this.update();
      this.saveState();
    }
  }

  /**
   * This will fix grid columns and sort, if it's invalid it will save state and trigger an update.
   */
  private async ensureValidState(organization?: ContactOrganization, contactType?: ContactTypeDefinition): Promise<boolean> {
    const fields = organization.fields.filter(f => !contactType || f.contact_type == contactType.key);
    const validColumns = this.getValidColumns(this.state.columns, fields);
    const invalidColumns = difference(this.state.columns.map(c => c.Sort), validColumns.map(c => c.Sort));

    if (invalidColumns.length > 0) {
      await dispatch(new SetGridColumnsAction(validColumns, contactType ? contactType.key : null));
    }

    if (!this.hasValidSort(this.state.sort, fields)) {
      await dispatch(new ToggleContactsSortAction("last first company"));
      return false;
    }

    return true;
  }

  private getValidColumns(columns: GridColumn[], fields: ICustomFieldDefinition[]): GridColumn[] {
    const sortNames: string[] = this.getValidSortableFieldNames(fields);
    const valid = (columns || []).filter(c => sortNames.includes(c.Sort));
    if (valid.length === 0) {
      return this.defaultColumns;
    }

    return valid;
  }

  private getValidSortableFieldNames(fields: ICustomFieldDefinition[]): string[] {
    const DEFAULT_NAMES = ["last", "first", "name", "last first", "company", "type", "email", "tags", "score", "contact_number"];
    const additionalNames = fields.map(f => f.type === CustomFieldType.address ? `${f.search_field}.country ${f.search_field}.state ${f.search_field}.city ${f.search_field}.postal_code` : f.search_field)
    return [...DEFAULT_NAMES, ...additionalNames]
  }

  private hasValidSort(sort: string, fields: ICustomFieldDefinition[]) {
    if (!sort) {
      return true;
    }

    const parts = (sort.startsWith("-(") ? sort.substring(2, sort.length - 1) : sort).split(" ");
    const sortNames: string[] = this.getValidSortableFieldNames(fields);
    const invalid = parts.filter(c => !sortNames.find(n => n === c));
    return invalid.length === 0;
  }

  @handle(SetContactsFilterAction)
  async handleSetContactsFilterAction(action: SetContactsFilterAction) {
    // const organization = await this.contactOrganizationRepository.getById(this.state.organizationId);
    const contactType = this.state.organization.contact_types.find(t => t.key == action.contactTypeKey);

    this.setState(state => ({
      ...state,
      firstPageLoaded: action.isDisabledLoading,
      loaded: action.isDisabledLoading,
      currentPage: 1,
      filter: action.filter,
      filterRequiresReindex: this.filterRequiresReindexing(action.filter),
      addlFilter: action.clearOthers ? "" : state.addlFilter,
      segmentId: action.clearOthers ? null : state.segmentId,
      selectAll: false,
      selectedContactType: contactType,
      filtersByContactType: {
        ...state.filtersByContactType,
        [action.contactTypeKey]: action.filter,
      },
      contactInPreview: null
    }));

    await this.update();
    this.saveState();

    if (action.clearOthers) {
      await dispatch(new SetCurrentSelectedContactTypeFilterAction(action.filter));
    }
  }

  private filterRequiresReindexing(filter: GroupFilter): boolean {
    if (!filter || !this.ffElectiveIndexing) {
      return false;
    }

    const dataDictionaryStore = ContainerInstance.get(DataDictionaryStore);
    const terms = filter.toFilterTerms({ fields: dataDictionaryStore.state.fields });
    return terms.some(t => t.requiresIndexing);
  }

  private get ffElectiveIndexing(): boolean {
    return this.ffs.isFeatureFlagEnabled("ElectiveIndexing");
  }

  @handle(SetContactsAdditionalFilterAction)
  private async handleSetContactsAdditionalFilterAction(s: SetContactsAdditionalFilterAction) {
    this.setState(state => ({
      ...state,
      addlFilter: s.addlFilter,
      firstPageLoaded: false,
      loaded: false,
      currentPage: 1,
      selectAll: false,
      contactInPreview: null
    }));

    await this.update();

    this.saveState();
  }

  @handle(SelectContactSegmentAction)
  private async handleSelectContactSegmentAction(action: SelectContactSegmentAction) {
    const filter = action.clearOthers ? createFrom(GroupFilter, { operation: "AND", filters: [] }) : this.state.filter;

    this.setState(state => ({
      ...state,
      segmentId: action.segmentId,
      filter: filter,
      filterRequiresReindex: this.filterRequiresReindexing(filter),
      addlFilter: action.clearOthers ? "" : state.addlFilter
    }));

    this.saveState();
    await dispatch(new SetContactsFilterAction(this.contactTypeKey, this.state.filter));
  }

  @handle(ContactsNextPage)
  async handleContactsNextPage() {
    const currentPage = this.state.currentPage + 1;
    this.setState(state => ({
      ...state,
      currentPage,
      loaded: false,
    }));

    await this.update();

    this.saveState();
  }

  @handle(ContactsPreviousPage)
  async handleContactsPreviousPage() {
    let currentPage = this.state.currentPage - 1;
    if (currentPage < 0) currentPage = 0;

    this.setState(state => ({
      ...state,
      currentPage,
      loaded: false,
    }));

    await this.update();

    this.saveState();
  }

  @handle(RefreshContactsGridAction)
  async handleRefreshContactsGridAction(action: RefreshContactsGridAction) {
    const { loaded, filterRequiresReindex } = this.state;
    if (!loaded || filterRequiresReindex) {
      return;
    }

    this.setState(state => ({
      ...state,
    }));
    await dispatch(new SetContactsFilterAction(
      this.contactTypeKey,
      this.state.filter,
      undefined,
      action.isDisabledLoading
    ));
  }

  @handle(ToggleContactsSortAction)
  async handleToggleContactsSortAction(action: ToggleContactsSortAction) {
    if (action.sort && action.sort == this.state.sort) {
      this.setState(state => ({ ...state, sort: `-(${action.sort})` }));
    } else {
      this.setState(state => ({ ...state, sort: action.sort }));
    }

    this.saveState();

    await dispatch(new SetContactsFilterAction(this.contactTypeKey, this.state.filter));
  }

  @handle(ToggleContactsSelectAllAction)
  async handleToggleContactsSelectAllAction(action: ToggleContactsSelectAllAction) {
    this.setState(state => ({ ...state, selectAll: action.selectAll }));
  }

  @handle(ContactsGridColumnsChangedAction)
  async handleContactsGridColumnsChangedAction(action: ContactsGridColumnsChangedAction) {
    this.setState(state => ({ ...state, columns: action.columns }));
  }

  @handle(ContactModelChangedAction)
  async handleContactModelChangedAction(action: ContactModelChangedAction) {
    if (!this.state.contacts || this.state.contacts.length === 0) {
      return;
    }

    const contact = this.state.contacts.find(c => c.id === action.contact.id);
    if (contact == null) {
      return;
    }

    Object.assign(contact, action.contact);
    this.setState(s => s);
  }

  @handle(AddNewContactAction)
  async handleAddNewContactAction(action: AddNewContactAction) {
    action.form.validate();

    const contact = action.form.updatedModel();
    try {
      const newContact = await this.contactRepo.post(<AddNewContactPostArgs>{
        type: action.contactType.key,
        email_address: contact.email_address,
        company_name: contact.company_name,
        first_name: contact.first_name,
        last_name: contact.last_name
      });
      action.createdContact = newContact;
    } catch (err) {
      if (err instanceof NetworkException) {
        switch (err.statusCode) {
          case 500:
            action.form.validation["email_address"] = `That email is already in use on another ${action.contactType.name} record.`;
            action.form.isInvalid = true;
            break;
        }
      }

      throw err;
    }
  }

  @handle(ContactTypeAddedAction)
  private async handleContactTypeAddedAction(action: ContactTypeAddedAction) {
    this.state.filtersByContactType[action.key] = new GroupFilter();
    this.setState(state => ({
      ...state,
      filtersByContactType: {
        ...state.filtersByContactType
      }
    }));
  }

  @handle(ContactTypeDeletedAction)
  private async handleContactTypeDeletedAction(action: ContactTypeDeletedAction) {
    delete this.state.filtersByContactType[action.key];
    this.setState(state => ({
      ...state,
      filtersByContactType: {
        ...state.filtersByContactType
      }
    }));
    this.cache.remove(this.contactTypeCacheKeyByKey(action.key));
    this.filtersCache.removeFilterFor(this.state.organizationId, this.state.userId, action.key);
    if (this.state.selectedContactType?.key == action.key) {
      this.setState((state) => ({
        ...state,
        selectedContactType: null
      }));


      const contactType = getDefaultContactType(this.state.organization, this.state.roleId);
      await dispatch(new SetSelectedContactTypeAction(contactType.key, this.state.filtersByContactType[contactType.key]));

    }
  }

  @handle(DeleteContactsAction)
  private async handleDeleteContactsAction(action: DeleteContactsAction) {
    const selection = {
      contact_type: this.state.selectedContactType?.key,
      ids: this.state.selectAll ? [] : this.state.selectedIds,
      filter: this.state.selectAll ? this.getWholeFilter() : null
    };
    await this.contactRepo.deleteSelection(selection);
    this.setState(state => ({
      ...state,
      firstPageLoaded: !this.state.selectAll,
      currentPage: this.state.selectAll ? 1 : this.state.currentPage,
      loaded: false,
      selectAll: false,
      selectedIds: [],
    }));

    await this.update();

    this.saveState();
  }

  @handle(MergeContactsAction)
  private async handleMergeContactsAction(action: MergeContactsAction) {
    const selection = {
      contact_type: this.state.selectedContactType.key,
      ids: this.state.selectAll ? [] : this.state.selectedIds,
      filter: this.state.selectAll ? this.getWholeFilter() : null
    };
    await this.contactRepo.mergeSelection(selection);
    this.setState(state => ({
      ...state,
      firstPageLoaded: !this.state.selectAll,
      currentPage: this.state.selectAll ? 1 : this.state.currentPage,
      loaded: false,
      selectAll: false,
      selectedIds: [],
    }));

    await this.update();

    this.saveState();
  }

  @handle(ExportContactsAction)
  private async handleExportContactsAction(action: ExportContactsAction) {
    let task: TaskRequest = null;

    const selection: EntitySelection = { ...action.selection }
    if (this.state.selectAll) {
      selection.filter = this.getWholeFilter();
      selection.excludedIds = [];
      selection.ids = [];
    }

    const key = this.state.selectedContactType?.key;

    switch (action.format) {
      case ExportFormatTypeCode.JSON:
        task = await this.exportRepository.contactJson(
          key,
          selection,
          action.exportDefinitionId
        );
        break;
      case ExportFormatTypeCode.Tabular:
        task = await this.exportRepository.contactTabular(
          key,
          selection,
          action.exportDefinition,
          action.exportDefinitionId
        );
        break;
    }

    action.taskId = task.Id;

    if (
      action.fileProviderId != null &&
      action.fileProviderFolder != null &&
      action.fileProviderFolder.length > 0
    ) {
      await dispatch(new RefreshFileProviderAction(action.fileProviderId));
    }

    await dispatch(new WatchTaskAction(task, "Contact Export"));
  }

  @handle(BulkUpdateContactPropertiesAction)
  private async handleBulkUpdateContactPropertiesAction(action: BulkUpdateContactPropertiesAction) {
    if (action.selectionPatch.patch?.length === 0) {
      return;
    }

    action.selectionPatch.contact_type ??= action.selection.contact_type;

    if (this.state.selectAll) {
      action.selectionPatch.filter = this.getWholeFilter();
      action.selectionPatch.excludedIds = [];
      action.selectionPatch.ids = [];
    }

    await this.contactRepo.bulkEdit(action.selectionPatch);
    // const contactMeta = await this.repository.getMetaByContactId(action.contact.id);

    // await this.update(contact, contactMeta);
    // await dispatch(new RefreshCurrentRelationshipsAction());
  }

  @handle(BulkContactCommentAction)
  private async handleBulkContactCommentAction(action: BulkContactCommentAction) {

    if (action.selection.ids?.length === 0 && action.selection.filter.length === 0) {
      return;
    }

    if (this.state.selectAll) {
      action.selection.filter = this.getWholeFilter();
      action.selection.excludedIds = [];
      action.selection.ids = [];
    }

    await this.contactActivityRepo.bulkComment(action.selection, <ActivityResult>{
      type: "@contact.comment",
      description: action.commentText,
    });

  }

  @handle(ToggleMapViewAction)
  private handleToggleMapViewAction(action: ToggleMapViewAction) {
    this.setState(state => ({
      ...state,
      mapView: action.enableMap != null ? action.enableMap : !state.mapView
    }));
  }

  @handle(DataDictionaryRefreshedAction)
  private async handleDataDictionaryRefreshedAction(action: DataDictionaryRefreshedAction) {
    const { filter, filterRequiresReindex, selectedContactType } = this.state;
    // Handles debouncing extra searches if the filter status hasn't changed.
    // NOTE: The data dictionary may not be initialized the first time search is loaded.
    if (filterRequiresReindex !== this.filterRequiresReindexing(filter)) {
      await dispatch(new SetContactsFilterAction((selectedContactType?.key ?? 'all'), this.state.filter));
    }
  }

  @handle(SetContactInPreview)
  private async handleSetContactInPreview(action: SetContactInPreview) {
    this.setState(state => ({
      ...state,
      contactInPreview: action.contact
    }));
    this.saveState();
  }
}
