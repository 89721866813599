import { inject } from "fw";
import { createFromArray, createFrom } from "fw-model";

import { ATS } from "./ats";

import { EvaluationPhase } from "models/evaluation-phase";
import { TaskRequest } from "models/task-request";
import { AssignmentArgs } from "./args";
import { EntitySelection } from "models/application-client-model";

@inject
export class EvaluationPhasesRepository {
  constructor(private s: ATS) { }

  public async put(phases: EvaluationPhase[], seasonId: string = null) {
    const res = await this.s.put<any[]>("evaluation-phases", phases, {
      seasonId,
    });
    return createFromArray(EvaluationPhase, res.body);
  }

  public async setDefaultLabel(label: string, seasonId: string = null) {
    const res = await this.s.put<string>("evaluation-phases/set-default-label", label, { seasonId });
    return res.body;
  }

  public async runRules(phaseId: string, selection: EntitySelection = null) {
    const res = await this.s.post<any[]>(
      `evaluation-phases/${phaseId}/assignments/run-rules`,
      selection,
    );
    return createFrom(TaskRequest, res.body);
  }

  public async assign(evaluationPhaseId: string, args: AssignmentArgs): Promise<TaskRequest> {
    const res = await this.s.post(`evaluation-phases/${evaluationPhaseId}/assign`, args);
    return createFrom(TaskRequest, res.body);
  }
}
