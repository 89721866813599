import { CustomFieldType } from "./contact-organization";
import { CalculatedFieldDataTypeCode, QuestionType } from "./form";

export enum DataDictionaryFieldCategory {
  Applicant = "Applicant",
  Application = "Application",
  Calculated = "Calculated",
  DocumentSteps = "Document Steps",
  EvaluationPhases = "Evaluation Phases",
  PortfolioSteps = "Portfolio Steps",
  Program = "Program",
  ReferenceSteps = "Reference Steps",
  Task = "Task"
}

export type DataDictionaryFieldType = "array" | "boolean" | "number" | "object" | "string";

export enum DataDictionaryFieldDataSource
{
  SystemFieldType = 1,
  QuestionTypeCode = 2,
  CalculatedFieldDataTypeCode = 3,
  ContactsFieldType = 4
}

export enum SystemFieldType
{
  Boolean = 1,
  Date = 2,
  Number = 3,
  String = 4,
  File = 5,
  Tags = 6,
  Geo = 7
}

export const enum DataDictionaryIndexStatus {
  Unavailable = 0,
  NotIndexed = 1,
  Indexing = 2,
  Indexed = 3
}

export const enum DataDictionaryAggregationType {
  None = 0,
  Terms = 1,
  Histogram = 2
}

export function getIndexStatusDisplayName(indexStatus: DataDictionaryIndexStatus): string {
  switch (indexStatus) {
    case DataDictionaryIndexStatus.Unavailable:
      return "N/A - Cannot be Indexed";
    case DataDictionaryIndexStatus.NotIndexed:
      return "Not Indexed";
    case DataDictionaryIndexStatus.Indexing:
      return "Indexing";
    case DataDictionaryIndexStatus.Indexed:
      return "Indexed";
  }
}

export class ProgramInfo {
  Id: string;
  Name: string;
}

export class DataDictionaryField {
  AggregationType: DataDictionaryAggregationType;
  Label: string;
  LabelTransform?: string;
  Path?: string;
  ExportPath?: string;
  SearchPath?: string;
  ParentPath?: string;
  Description: string;
  ContactsFieldId?: string;
  FormId?: string;
  FormKey?: string;
  FormPath?: string;
  Category: DataDictionaryFieldCategory | string;
  IsTableSection: boolean;
  IsRoutable: boolean;
  IndexStatus: DataDictionaryIndexStatus;
  DataSource?: DataDictionaryFieldDataSource;
  DataType?: SystemFieldType | CustomFieldType | CalculatedFieldDataTypeCode | QuestionType | number;
  JsonDataType: DataDictionaryFieldType;
  Sort: string;
  TaskRequestId: string;

  Values?: string[];
  AllowsWriteInValue?: boolean;
  Validation?: string[];

  // empty means all
  ProgramsUsing?: ProgramInfo[];
  ProgramsUsingCount: number;
}
