import { inject, Navigator } from "fw";
import { dispatch } from "fw-state";

import { ActivityStore, StopActivityTrackerAction, ToggleActivityAlert } from "state/activity";
import { RefreshTokenAction, LogoutAction } from "state/actions";

import { dateDiff } from "helpers/date-diff";
import { ATS } from "network/ats";
import { RoutePathStore } from "state/route-path";
import { clearInterval, setInterval } from "worker-timers";

@inject
export class ActivityAlert {
  private intervalCountdown: any = null;
  private duration: number = 0;
  private currentPageTitle: string = "";
  private tokenExpireDate: string = "";

  constructor(
    private store: ActivityStore,
    private nav: Navigator,
    private network: ATS,
    private routePath: RoutePathStore
  ) {}

  attached() {
    window.addEventListener("storage", this.handleLogoutMultipleTab);
  }

  detached() {
    window.removeEventListener("storage", this.handleLogoutMultipleTab);
  }

  handleLogoutMultipleTab(event: StorageEvent) {
    if (event.key === "ats-bearer-token" && !event.newValue) {
      this.closeAndLogout(
        "You are automatically logged out because you logged out in one of your browser tabs."
      );
    }

    if (!this.store.state.showActivityAlert) {
      this.setDefaultTitle();
    }
  }

  private initCountdown(): void {
    this.currentPageTitle = document.title;
    this.tokenExpireDate = this.network.getTokenExpDate();
    this.duration = dateDiff(new Date(), this.tokenExpireDate, "s") || 0;
    this.intervalCountdown = setInterval(() => this.countdown(), 1000);
  }

  private clearCountdown(): void {
    if (this.intervalCountdown) {
      clearInterval(this.intervalCountdown);
      this.intervalCountdown = null;
    }
    this.duration = 0;
  }

  private get formattedDuration() {
    const mm = Math.floor(this.duration / 60);
    const s = this.duration % 60;
    const ss = s < 10 ? "0" + s : s;
    return `${mm}:${ss}`;
  }

  private countdown(): void {
    this.duration = dateDiff(new Date(), this.tokenExpireDate, "s") || 0;
    document.title = `${this.formattedDuration} until logout - Liaison Outcomes`;
    if (this.duration <= 0) {
      this.closeAndLogout(
        "You were automatically logged out due to inactivity."
      );
    }
  }

  private setDefaultTitle(): void {
    document.title = this.currentPageTitle;
  }

  public closeAndLogout(reason: string = null): void {
    this.clearCountdown();
    this.setDefaultTitle();
    dispatch(new StopActivityTrackerAction());
    dispatch(new LogoutAction(reason));
    const fullRoute = this.routePath.state.currentPath ?? "/";
    this.nav.navigate("/login", { redirect: fullRoute });
  }

  public get isActive(): boolean {
    if (this.store?.state?.showActivityAlert) {
      this.initCountdown();
    } else {
      this.clearCountdown();
    }

    return this.store?.state?.showActivityAlert;
  }

  public refresh() {
    this.clearCountdown();
    dispatch(new RefreshTokenAction());

    this.setDefaultTitle();
  }
}
