import { prop, inject, ComponentEventBus } from "fw";
import { Form } from "fw-model";
import { Completer } from "helpers/auto-complete";
import { CustomFieldType } from "models/contact-organization";
import { FunctionType } from "models/function";

@inject
export class TextareaFormField {
  @prop(null) public form!: Form;
  @prop(null) public field;
  @prop(null) public label!: string;
  @prop(null) public type!: "text";
  @prop("3") public rows;
  @prop(null) public setfocus;
  @prop(null) public readonly;
  @prop(false) public disabled;
  @prop(null) public floatingLabel;
  @prop(null) public placeholder;
  @prop(null) public completer!: Completer | Completer[];
  @prop(null) public contactType!: string;
  @prop(() => [
    CustomFieldType.address,
    CustomFieldType.date,
    CustomFieldType.dropdown,
    CustomFieldType.email,
    CustomFieldType.funnel,
    CustomFieldType.largestring,
    CustomFieldType.number,
    CustomFieldType.phone,
    CustomFieldType.string,
    CustomFieldType.user,
  ]) completionTypes: Array<CustomFieldType | FunctionType>;
  @prop(null) public maxlength!: number;
  @prop(false) public showCharactersRemaining!: boolean;

  private name = "";

  constructor(private ceb: ComponentEventBus) {}

  public attached() {
    this.formChanged();
  }

  private formChanged() {
    this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
  }

  private onChange() { this.ceb.dispatch("change"); }
  private onKeyUp() { this.ceb.dispatch("keyup"); }

  private onUpdateValue(value) {
    this.ceb.dispatch("update:value", value);
  }
}
