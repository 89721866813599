import { prop, needs, inject, ComponentEventBus } from "fw";
import { dispatch } from 'fw-state';
import { cloneOf } from "fw-model";

import { GroupFilter, Filter, getValidation, getFilterTerms, FilterTermsResult } from "models/filter-setup";
import { GroupFilter as GroupFilterComponent } from "./application-filters/group-filter";
import { PopoverService } from "service/popover";
import { AddFilter, AddFilterParams } from "./add-filter";
import { wait } from "wait";

import allFilters from "./filters-index";
import { SetCurrentSelectedContactTypeFilterAction } from 'state/current-user-settings';
import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";
import { NestedGroupFilter } from "models/contacts-filters";

@inject
@needs(...allFilters, GroupFilterComponent)
export class Filters {
  @prop(null) public filterContainer!: GroupFilter;
  @prop(false) public readonly!: boolean;
  @prop(true) private canAddGroup!: boolean;
  @prop("applications") public type!: "applications" | "contacts" | "activity" | "tasks" | "events" | "nested";
  @prop(null) private overrideContactType!: string;
  @prop(false) private suppressGlobalDispatch!: boolean;
  @prop(true) public showRuntimeFieldsWarning!: boolean;
  @prop("center") opens: "left" | "left" | "right" | "inline";

  public editingIndex: number = -1;
  public editingValidation = null;
  public editingGroup: Filter = null;
  public closingGroup: boolean = false;
  public openingGroup: boolean = false;
  public applicationGroup: Filter = null;
  public activityGroup: Filter = null;
  public nestedFieldsGroup: Filter = null;
  private oldState: GroupFilter = null;
  public groupApply: (validate?: boolean) => Promise<boolean> = null;
  public groupEdit: (index: number, clone?: boolean) => boolean = null;

  constructor(
    private ceb: ComponentEventBus,
    private dataDictionaryStore: DataDictionaryStore,
    private popoverService: PopoverService,
    private ffs: FeatureFlagService
  ) { }

  public async attached() {
    if (this.ffElectiveIndexing) {
      await dispatch(new EnsureDataDictionaryFieldsAction(false));
    }

    this.ceb.dispatch("apply-filters", this.apply);
    this.ceb.dispatch("edit", this.edit);
    this.validateFilters();
  }

  public async addFilter() {
    const res = await this.popoverService.open<Filter>(AddFilter, <AddFilterParams>{
      canAddGroup: this.canAddGroup,
      type: this.type,
      data: { id: this.selectedNestedFieldId },
      overrideContactType: this.overrideContactType
    });

    if (res.canceled) {
      return;
    }

    this.oldState = cloneOf(GroupFilter, this.filterContainer);
    this.filterContainer.filters.push(res.result);
    this.edit(this.filterContainer.filters.length - 1, false);
  }

  // TODO: figure out why filterContainer is null when creating segment
  public get filterContainerFilters() {
    return this.filterContainer?.filters || [];
  }

  private async changed() {
    if (!this.suppressGlobalDispatch && this.type == "contacts") {
      await dispatch(new SetCurrentSelectedContactTypeFilterAction(this.filterContainer));
    }

    this.ceb.dispatch("filter-changed", this.filterContainer);
  }

  // NOTE: This is called when the filterContainer prop is changed
  public filterContainerChanged() {
    if (this.filterContainer.filters.length == 0) {
      this.editingGroup = null;
      this.applicationGroup = null;
      this.activityGroup = null;
      this.nestedFieldsGroup = null;
      this.resetGroup();
    }
  }

  public async toggleOperation(): Promise<boolean> {
    if (this.readonly) {
      return true;
    }

    this.filterContainer.operation = this.filterContainer.operation == "AND" ? "OR" : "AND";
    await this.changed();

    return false;
  }

  public async removeFilter(filter: Filter) {
    const idx = this.filterContainer.filters.indexOf(filter);
    if (idx >= 0) {
      this.filterContainer.filters.splice(idx, 1);
      await this.apply(false);
    }
  }

  public edit(index: number, clone = true): boolean {
    if (this.readonly) {
      return true;
    }

    if (this.editingIndex >= 0) {
      return false;
    }

    if (clone) {
      this.oldState = cloneOf(GroupFilter, this.filterContainer);
    }

    const filterType = this.filterContainer.filters[index].type;
    if (filterType == "group-filter") {
      this.editingGroup = this.filterContainer.filters[index];
      setTimeout(() => this.openingGroup = true, 1);
    } else if (filterType == "contacts-application-group-filter") {
      this.applicationGroup = this.filterContainer.filters[index];
      setTimeout(() => this.openingGroup = true, 1);
    } else if (filterType == "contacts-activity-group-filter") {
      this.activityGroup = this.filterContainer.filters[index];
      setTimeout(() => this.openingGroup = true, 1);
    } else if (filterType == "nested-group-filter") {
      this.nestedFieldsGroup = this.filterContainer.filters[index];
      setTimeout(() => this.openingGroup = true, 1);
    } else {
      this.editingIndex = index;
      this.editingValidation = {};
      this.editing(true);
    }

    return false;
  }

  public get requiresIndexing(): boolean {
    return this.filterTerms().some(t => t.requiresIndexing);
  }

  public get requiresRuntimeFields(): boolean {
    return this.filterTerms().some(t => t.requiresRuntimeFields);
  }

  public filterRequiresIndexing(filter: Filter) {
    return this.filterTerms(filter).some(t => t.requiresIndexing);
  }

  public filterRequiresRuntimeFields(filter: Filter) {
    return this.filterTerms(filter).some(t => t.requiresRuntimeFields);
  }

  private filterTerms(filter?: Filter): FilterTermsResult[] {
    if (!this.ffElectiveIndexing) {
      return [];
    }

    const { fields } = this.dataDictionaryStore.state;
    return filter
      ? getFilterTerms(filter, { fields })
      : this.filterContainer?.toFilterTerms({ fields }) || [];
  }

  public validateFilters(): boolean {
    if (this.editingIndex >= 0) {
      const editingFilter = this.filterContainer.filters[this.editingIndex];

      const validation = getValidation(editingFilter);
      if (validation) {
        this.editingValidation = validation;
        return false;
      } else {
        this.editingValidation = {};
      }
    } else {
      // This is needed due to calling apply from external sources.
      for (let index = 0; index < this.filterContainer.filters.length; index++) {
        let validation;
        const filter = this.filterContainer.filters[index];
        switch (filter.type) {
          case "group-filter":
          case "contacts-application-group-filter":
          case "contacts-activity-group-filter":
          case "nested-group-filter":
            const group = <GroupFilter>filter.data;
            for (let groupIndex = 0; groupIndex < group.filters.length; groupIndex++) {
              validation = getValidation(group.filters[groupIndex]);
              if (validation) {
                this.edit(index); // open the group on this control.

                // We have to use setTimeout to run after the next digest cycle,
                // because the group filter control has not rendered yet.
                setTimeout(() => {
                  if (this.groupEdit) { // edit the groups filter;
                    this.groupEdit(groupIndex);
                  }

                  if (this.groupApply) { // apply validation to the group filter.
                    this.groupApply();
                  }
                });

                return false;
              }
            }
            break;

          default:
            validation = getValidation(filter);
            if (validation) {
              this.edit(index);
              this.editingValidation = validation;
              return false;
            }
        }
      }
    }

    return true;
  }

  public async apply(validate = true): Promise<boolean> {
    if (validate && !this.validateFilters()) {
      return false;
    }

    this.editingIndex = -1;
    this.editingGroup = null;
    this.applicationGroup = null;
    this.activityGroup = null;
    this.nestedFieldsGroup = null;
    this.oldState = null;
    this.editingValidation = {};
    this.editing(false);

    await this.changed();
    return true;
  }

  public cancel() {
    if (!this.validateFilters()) {
      return false;
    }

    this.ceb.dispatch("update:filterContainer", cloneOf(GroupFilter, this.oldState));
    this.oldState = null;

    this.editingIndex = -1;
    this.editingValidation = {};
    this.editing(false);
  }

  public async applyApplicationGroup() {
    if (this.applicationGroup.data.filters.length == 0) {
      // take the group outta here
      this.filterContainer.filters.splice(this.editingIndex, 1);
    }

    this.closingGroup = true;
    await wait(150);

    this.applicationGroup = null;
    this.resetGroup();
  }

  public async applyActivityGroup() {
    this.closingGroup = true;
    await wait(150);

    this.activityGroup = null;
    this.resetGroup();
  }

  public applyNestedFieldsGroup() {
    if (!this.validateFilters())
      return;
    this.closingGroup = true;
    this.nestedFieldsGroup = null;
    this.resetGroup();
  }

  public async applyGroup() {
    if (this.editingGroup.data.filters.length == 0) {
      // take the group outta here
      this.filterContainer.filters.splice(this.editingIndex, 1);
    }

    this.closingGroup = true;
    await wait(150);

    this.editingGroup = null;
    this.resetGroup();
  }

  private resetGroup() {
    this.editingIndex = -1;
    this.editingValidation = {};
    this.oldState = null;
    this.closingGroup = false;
    this.openingGroup = false;
    this.editing(false);
  }

  public groupChanged() {
    this.ceb.dispatch("filter-changed", this.filterContainer);
  }

  public get ffElectiveIndexing(): boolean {
    return this.ffs.isFeatureFlagEnabled("ElectiveIndexing");
  }

  public get hasFilterData(): boolean {
    return !this.editingGroup && !this.applicationGroup && !this.activityGroup && !this.nestedFieldsGroup;
  }

  public get ffContactNestedSearch(): boolean {
    return this.ffs.isFeatureFlagEnabled("ContactNestedSearch");
  }

  public get showNestedFieldsFilter(): boolean {
    return this.ffContactNestedSearch && this.nestedFieldsGroup !== null;
  }

  public get selectedNestedFilterLabel(): string {
    if (!this.nestedFieldsGroup) {
      return "Nested Fields Filters";
    }

    return `Edit ${this.nestedFieldsGroup.data.field.display_name} Filters`;
  }

  private get selectedNestedFieldId(): string {
    if (!this.filterContainer) {
      return null;
    }

    const { field } = (this.filterContainer as NestedGroupFilter);
    return field?.id || null;
  }

  private editing(isEditing: boolean): void {
    this.ceb.dispatch("editing", isEditing);
  }
}
