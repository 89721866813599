import { inject, Navigator, needs } from "fw";
import { dispatch } from "fw-state";

import { ResetPasswordForm } from "forms/resetpassword";
import { UserRepository } from "network/user-repository";
import { PasswordStrengthMeter } from "views/components/password-strength-meter";

@inject
@needs(PasswordStrengthMeter)
export class ResetPassword {
  private resetPasswordForm = new ResetPasswordForm();
  private isDone = false;
  private resetToken = null;

  constructor(private nav: Navigator, private userRepo: UserRepository) { }

  activate(params) {
    this.resetToken = params.token;
  }

  private async save() {
    try {
      this.resetPasswordForm.validate();
      await this.userRepo.resetPassword(this.resetPasswordForm.Password, this.resetToken);
      this.isDone = true;
    } catch (err) { }
  }

}
