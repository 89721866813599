import { inject, NetworkException } from "fw";
import { Store, handle } from "fw-state";

import { once } from "helpers/once";
import { StartAction, LogoutAction, SelectedContactTypeChangedAction, SelectedContactTypeFilterChangedAction } from "./actions";
import { GroupFilter, FilterContext } from "models/filter-setup";
import { MarkerPoint, DEFAULT_MAPSTATE, MapState, getPrecisionFromZoom, boundingBoxToElasticFilter } from "models/map-state";
import { ContactRepository } from "network/contact-repository";
import { ContactsFilter } from "service/contacts-filter";
import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryStore } from "./data-dictionary";

export type ContactMapPoint = {
  id: string;
  name: string;
  address: string;
};

interface ContactsGeoStoreShape {
  organizationId: string;
  selectedContactTypeKey: string;
  selectedContactTypeFilter: GroupFilter;

  markers: MarkerPoint<ContactMapPoint>[];
  mapState: MapState;
}

export class EnsureContactsGeoStoreAction {
  constructor() { }
}

export class UpdateContactsMapStateAction {
  constructor(public s: MapState) { }
}

@inject
export class ContactsGeoStore extends Store<ContactsGeoStoreShape> {
  constructor(
    private contactsRepo: ContactRepository,
    private contactsFilter: ContactsFilter,
    private ffs: FeatureFlagService,
    private dataDictionaryStore: DataDictionaryStore
  ) {
    super();
  }

  

  protected defaultState() {
    return {
      organizationId: null,
      selectedContactTypeKey: null,
      selectedContactTypeFilter: null,
      markers: [],
      mapState: DEFAULT_MAPSTATE,
    };
  }

  @handle(StartAction)
  private async handleStartAction(action: StartAction) {
    const organizationId = action.context.Organization.Id;
    const selectedContactTypeKey = action.context.UserSeasonSettings.Settings["contactsTypeSelected"];
    const selectedContactTypeFilter = this.contactsFilter.getFilterFor(organizationId, action.context.Me.Id, selectedContactTypeKey);
    this.setState(_ => ({
      ...this.defaultState(),
      organizationId: organizationId,
      selectedContactTypeKey: selectedContactTypeKey,
      selectedContactTypeFilter: selectedContactTypeFilter
    }));
  }

  @handle(EnsureContactsGeoStoreAction)
  private async handleEnsureContactsGeoStoreAction(action: EnsureContactsGeoStoreAction) {
    await once("ensure-contacts-geo", async () => {
      await this.update();
    });
  }

  @handle(LogoutAction)
  private handleLogoutAction(action: LogoutAction) {
    this.setState(state => this.defaultState());
  }

  @handle(SelectedContactTypeChangedAction)
  private async handleSelectedContactTypeChangedAction(action: SelectedContactTypeChangedAction) {
    this.setState(state => ({
      ...state,
      selectedContactTypeKey: action.type,
      selectedContactTypeFilter: action.filter
    }));
    await this.update();
  }

  @handle(SelectedContactTypeFilterChangedAction)
  private async handleSelectedContactTypeFilterChangedAction(action: SelectedContactTypeFilterChangedAction) {
    this.setState(state => ({
      ...state,
      selectedContactTypeKey: action.selectedContactTypeKey,
      selectedContactTypeFilter: action.filter
    }));
    await this.update();
  }

  private async update() {
    const points: MarkerPoint<any>[] = [];

    try {
      const filter = this.createFilter();
      const facet = this.createFacet();
      const contactType = this.state.selectedContactTypeKey;

      const aggregations = await this.contactsRepo.count(null,filter, facet, contactType);

      if (aggregations.total <= 100) {
        const contacts = await this.contactsRepo.list(null, filter, null, null, 1, 999999, contactType);

        for (const contact of contacts.results) {
          for (const geo of contact.geo_set) {
            const point: MarkerPoint<ContactMapPoint> = {
              data: {
                id: contact.id,
                name: contact.display_name,
                address: geo.full_address,
              },
              lat: geo.latitude,
              long: geo.longitude,
              total: 1,
            };

            points.push(point);
          }
        }

      } else {
        const aggs = aggregations.aggregations["geogrid_geo"];
        if (aggs) {
          for (const i of aggs.items) {
            points.push({ total: i.total, lat: i.aggregations.avg_lat.value, long: i.aggregations.avg_lon.value });
          }
        }
      }

    } catch (err) {
      if (err instanceof NetworkException && err.statusCode == 400) {
        console.warn(err.result.message);
      }
    }

    this.setState(state => ({
      ...state,
      markers: points,
    }));
  }

  private createFilter() {
    const geoFilter = '_exists_:geo';
    const filterContext: FilterContext = {
      contactType: this.state.selectedContactTypeKey,
      fields: this.dataDictionaryStore.state.fields
    };
    const userFilter = (this.state.selectedContactTypeFilter != null)
      ? this.state.selectedContactTypeFilter.toFilterString(filterContext)
      : null;
    const typeFilter = (this.state.selectedContactTypeKey != null)
      ? `type:${this.state.selectedContactTypeKey}`
      : null;
    let completeFilter = geoFilter;
    if (typeFilter != null) {
      completeFilter += ` ${typeFilter}`;
    }
    if (userFilter != null) {
      completeFilter += ` ${userFilter}`;
    }

    const bounding = this.state.mapState.bounding;
    if (bounding != null) {
      const bFilter = boundingBoxToElasticFilter(bounding);

      if (completeFilter.length > 0) {
        completeFilter = `(${completeFilter}) AND geo:${bFilter}`;
      } else {
        completeFilter = `geo:${bFilter}`;
      }
    }

    return completeFilter;
  }

  private createFacet() {
    return `geogrid:geo~${getPrecisionFromZoom(this.state.mapState.zoom)}`;
  }

  @handle(UpdateContactsMapStateAction)
  private async handleUpdateMapStateAction(action: UpdateContactsMapStateAction) {
    this.setState(state => ({
      ...state,
      mapState: action.s,
    }));

    this.update();
  }
}
