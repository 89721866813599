import { inject, prop, ComponentEventBus } from "@derekpitt/fw"
import { Question, FormAnswer, AnswerOption } from "../../models";

const WRITE_IN: string = "writeIn";

@inject
export class CheckboxType {
  @prop(null) question!: Question;
  @prop(null) answerOptions?: AnswerOption[];
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(true) showOptions!: boolean;
  @prop(null) ariaLabelledby: string;

  @prop(null) requestCeeb;
  @prop(null) fileHash;
  @prop(null) fileService;

  private value: string[] = [];
  private writeInValue: string = "";
  public writeInInputRef: HTMLInputElement;

  public constructor(private ecb: ComponentEventBus) { }

  public attached() {
    this.updateValue();
  }

  private updateValue() {
    const { FormAnswerOptions } = this.answer;

    if (FormAnswerOptions != null) {
      this.value = FormAnswerOptions.map(a => a.AnswerOptionId == null ? WRITE_IN : a.AnswerOptionId);

      const writeInOption = FormAnswerOptions.find(a => a.AnswerOptionId == null);
      if (writeInOption != null) {
        this.writeInValue = writeInOption.OptionText;
      }
    }
  }

  public answerChanged() {
    this.updateValue();
  }

  public isAnswer(answerOption: string): boolean {
    for (let answerValue of this.value) {
      if (answerOption == answerValue) {
        return true;
      }
    }
    return false;
  }

  public change() {
    const options = this.value.map(v => {
      const qo = this.question.AnswerOptions.find(o => o.Id == v);
      const isWriteIn = v == WRITE_IN;

      let OptionText = qo ? qo.Label : null;
      if (isWriteIn) {
        OptionText = this.writeInValue;
      }

      return { AnswerOptionId: isWriteIn ? null : v, OptionText };
    });

    this.answer.FormAnswerOptions = options;
    this.ecb.dispatch("answer-changed");
  }

  public writeInKeyUp() {
    this.enableWriteIn();
    this.change();
  }

  public enableWriteIn() {
    const hasWriteIn = this.value.includes(WRITE_IN);
    const hasWriteInValue = this.writeInValue.trim().length > 0;
    if (hasWriteIn && !hasWriteInValue) {
      const writeInIndex = this.value.indexOf(WRITE_IN);
      this.value.splice(writeInIndex, 1);
    } else if (!hasWriteIn && hasWriteInValue) {
      this.value.push(WRITE_IN);
    }
  }

  public writeInCheckboxClick(event: MouseEvent) {
    this.writeInInputRef.focus();

    const hasWriteIn = this.value.includes(WRITE_IN);
    const hasWriteInValue = this.writeInValue.trim().length > 0;
    if (hasWriteIn) {
      const writeInIndex = this.value.indexOf(WRITE_IN);
      this.value.splice(writeInIndex, 1);
    } else if (hasWriteInValue) {
      this.value.push(WRITE_IN);
    }

    if (!hasWriteInValue) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.change();
  }
}
