import { prop, inject, ComponentEventBus } from "fw";
import { generateDataId } from "helpers/data-id-gen";

const palette = [
  // "#E1BEE7",
  // "#D1C4E9",
  // "#C5CAE9",
  // "#BBDEFB",
  // "#B2EBF2",
  // "#B2DFDB",
  // "#C8E6C9",
  // "#C5E1A5",
  // "#E6EE9C",
  // "#FFF59D",
  "rgba(31, 119, 180, .4)",
  "rgba(174, 199, 232, .4)",
  "rgba(255, 127, 14, .4)",
  "rgba(255, 187, 120, .4)",
  "rgba(44, 160, 44, .4)",
  "rgba(152, 223, 138, .4)",
  "rgba(214, 39, 40, .4)",
  "rgba(255, 152, 150, .4)",
  "rgba(148, 103, 189, .4)",
  "rgba(197, 176, 213, .4)",
  "rgba(140, 86, 75, .4)",
  "rgba(196, 156, 148, .4)",
  "rgba(227, 119, 194, .4)",
  "rgba(247, 182, 210, .4)",
  "rgba(127, 127, 127, .4)",
  "rgba(188, 189, 34, .4)",
  "rgba(219, 219, 141, .4)",
  "rgba(23, 190, 207, .4)",
  "rgba(158, 218, 229, .4)",
];

@inject
export class TagPill {
  @prop(null) tag;
  @prop(null) backgroundColor: string;
  @prop(true) randomizeColor!: boolean;
  @prop(false) showRemove!: boolean;

  constructor(private ceb: ComponentEventBus) {}

  remove() {
    this.ceb.dispatch("remove", this.tag);
  }

  get randomBackgroundColor(): string {
    if (!this.randomizeColor) {
      return null;
    }

    if (this.tag == null) return palette[0];

    let num = 0;
    for (let i = 0; i < this.tag.length; i++) {
      num += this.tag.charCodeAt(i)
    }

    // const hue = (num + 80) % 310 + 20;
    // return `hsl(${hue}, 80%, 80%)`;

    return palette[num % palette.length];
  }

  get color(): string {
    if (!this.randomizeColor) {
      return null;
    }

    return "#000";
  }

  public genDataId(prefix: string, val: string): string {
    return generateDataId(prefix, val);
  }
}
