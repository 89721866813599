import { FormForType, formFor } from "fw-model";

import { ApplicationSource, ApplicationSourceJavascriptTransformation } from "models/application-source";

import { eventNotificationFormCreator } from "forms/scheduled-export";

export type ApplicationSourceFormType = FormForType<ApplicationSource>;

export class UpdateApplicationSourceAction {
  constructor(public form: ApplicationSourceFormType) {}
}

export class AddApplicationSourceAction {
  constructor(public form: ApplicationSourceFormType) {}
}


const applicationSourceJavascriptTransformationFormCreator = formFor(ApplicationSourceJavascriptTransformation, s => {
  s.field(u => u.IncludeFunctionIds, "Include Functions");
  s.requiredField(u => u.Function, "function (data, filename)");
});

export const applicationSourceFormCreator = formFor(ApplicationSource, s => {
  s.requiredField(u => u.Name, "Name");
  s.requiredField(a => a.FileSourceId, "File Source");
  s.field(a => a.ProgramId, "Program");
  s.field(a => a.Path, "Path");
  s.field(a => a.Active, "Active");

  s.requiredField(a => a.FileCountType, "Expected File Record Count");
  s.formArray(u => u.Notifications, "Notifications", eventNotificationFormCreator);

  s.field(a => a.FileMask, "File Mask");
  s.form(a => a.JavascriptTransformation, "Transform", applicationSourceJavascriptTransformationFormCreator);
});
