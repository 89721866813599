import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";

const anythingFilled = (...strings: string[]) => {
  for (const str of strings) {
    if (str == null) continue;
    if (str.trim().length > 0) return true;
  }

  return false;
};

@inject
export class NameType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledby;

  constructor(private ecb: ComponentEventBus) {}

  private getAnswerField(field: string) {
    if (this.answer != null && this.answer.Data != null) {
      return this.answer.Data[field] || null;
    }

    return null;
  }

  private setAnswerField(field: string, value: string) {
    if (this.answer == null) return;
    if (this.answer.Data == null) {
      this.answer.Data = {
        title: null,
        given: null,
        middle: null,
        family: null,
        suffix: null,
      };
    }

    this.answer.Data[field] = value;
    this.ecb.dispatch("answer-changed");
  }

  get title() { return this.getAnswerField("title"); }
  set title(str: string) { this.setAnswerField("title", str); }

  get given() { return this.getAnswerField("given"); }
  set given(str: string) { this.setAnswerField("given", str); }

  get middle() { return this.getAnswerField("middle"); }
  set middle(str: string) { this.setAnswerField("middle", str); }

  get family() { return this.getAnswerField("family"); }
  set family(str: string) { this.setAnswerField("family", str); }

  get suffix() { return this.getAnswerField("suffix"); }
  set suffix(str: string) { this.setAnswerField("suffix", str); }
}
