import { AppRepository } from "network/app-repository";
import { SessionStorageCache } from "caching";
import { ContainerInstance } from "fw";

export const getPortalContext = async () => {
  const appRepo = ContainerInstance.get(AppRepository);
  const cache = ContainerInstance.get(SessionStorageCache);

  let host = window.location.hostname == "localhost" ? "ats.slideroom-auto.net" : window.location.host;

  const lastOrganizationPortalUrl = cache.get<string>("lastOrganizationPortalUrl");

  if (lastOrganizationPortalUrl != null) {
    host = lastOrganizationPortalUrl;
  }

  if (localStorage && localStorage["organizationPortalUrl"]) {
    host = localStorage["organizationPortalUrl"];
  }

  const context = await appRepo.organizationPortalContext(host);
  if (context) {
    context.OrganizationPortal.Domain = host;
  }

  return context;
};
