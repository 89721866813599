import { prop, inject, ComponentEventBus } from "fw";
import { Form } from "fw-model";
import { Completer } from "helpers/auto-complete";
import { CustomFieldType } from "models/contact-organization";
import { FunctionType } from "models/function";

@inject
export class TextFormFieldAdorned {
  @prop(null) public adornment!: string;
  @prop(null) public form!: Form;
  @prop(null) public field;
  @prop(null) public label!: string;
  @prop(false) public hideLabel!: boolean;
  @prop(null) public ariaLabel!: string;
  @prop(null) public type!: "date" | "email" | "tel" | "text" | "password" | "number" | "url";
  @prop(null) public setfocus;
  @prop(null) public readonly;
  @prop(null) public maxlength;
  @prop(false) public disabled;
  @prop(false) public floatingLabel!: boolean;
  @prop(null) public autocomplete;
  @prop(null) public placeholder;
  @prop(null) public required: boolean;
  @prop(null) public completer!: Completer | Completer[];
  @prop(null) public contactType!: string;
  @prop(null) public helpText;
  @prop(() => [
    CustomFieldType.address,
    CustomFieldType.date,
    CustomFieldType.dropdown,
    CustomFieldType.email,
    CustomFieldType.funnel,
    CustomFieldType.largestring,
    CustomFieldType.number,
    CustomFieldType.phone,
    CustomFieldType.string,
    CustomFieldType.user,
  ])
  completionTypes: Array<CustomFieldType | FunctionType>;

  private name = "";
  private $refs: any;

  constructor(private ceb: ComponentEventBus) {}

  public attached() {
    this.formChanged();

    this.setInputPadding();
  }

  private setInputPadding() {
    const adornmentEl = this.$refs.adornmentEl;
    const textFieldEl = this.$refs.textFieldEl?.$el.querySelector(".mdl-textfield__input");
    textFieldEl.style.paddingRight = `${adornmentEl?.offsetWidth + 10}px`;
  }

  private formChanged() {
    this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
  }

  private onChange() {
    this.ceb.dispatch("change");
  }

  private onKeyUp() {
    this.ceb.dispatch("keyup");
  }

  private onKeyDown() {
    this.ceb.dispatch("keydown");
  }

  private onUpdateValue(value) {
    this.ceb.dispatch("update:value", value);
  }
}
