import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";

@inject
export class LongTextType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledby;

  @prop(null) requestCeeb;
  @prop(null) fileHash;
  @prop(null) fileService;
  @prop(null) showOptions;
  @prop(null) decryptData;

  constructor(private ecb: ComponentEventBus) { }

  change() {
    this.ecb.dispatch("answer-changed");
  }
}
