import { inject, prop } from "fw";

/*
 * the purpose of this component is to be a draggable item or row that needs to be sortable and keyboard accessible
 * each item is keyboard navigable (tab key), then users can sort items (up/down arrows)
 * changes are also asserted to screen reader
 * pages with multiple or nested draggable components should pass a unique `className` to differentiate
 */

@inject
export class DraggableItem {
  @prop(null) public tag!: string;
  @prop(null) public list!: any[];
  @prop(null) public ariaLabel: string;
  @prop("draggable-item") public className: string;
  @prop(-1) public index: number;
  @prop(true) public reorderList: Function | boolean;
  @prop(null) public fixedIndices: number[];

  public assistiveText: string = "";

  public async onKeyEvent(e: KeyboardEvent) {
    if (this.disabled) return;

    const target = e.target as HTMLElement;

    if (!target.classList.contains(this.className) || !["ArrowDown", "ArrowUp"].includes(e.key)) return;

    e.preventDefault();

    const oldIndex = this.index;
    let verb: string = null;
    let newIndex: number = oldIndex;

    if (e.key === "ArrowUp" && oldIndex > 0) {
      verb = "up";
      newIndex--;
    } else if (e.key === "ArrowDown" && oldIndex < this.list.length - 1) {
      verb = "down";
      newIndex++;
    }

    // don't do anything if it didn't move or if the new index is a fixed (non-movable) item
    if (
      oldIndex === newIndex ||
      (this.fixedIndices && this.fixedIndices.includes(newIndex))
    ) {
      return;
    }

    if (typeof this.reorderList === 'function') {
      await this.reorderList(newIndex, oldIndex);
    } else if (this.reorderList === true && this.list) {
      // must await or can get a race-condition with the focus switching to old DOM element before it's swapped
      await this.reorder(this.list, newIndex, oldIndex);
    }

    const element = document.getElementsByClassName(this.className)[newIndex] as HTMLElement;
    element.focus();

    this.assistiveText = `Item moved ${verb} to row ${newIndex + 1}`;
  }

  public get disabled(): boolean {
    return this.fixedIndices && this.fixedIndices.includes(this.index);
  }

  private async reorder(list: any[], newIndex: number, oldIndex: number): Promise<void> {
    list.splice(
      newIndex,
      0,
      list.splice(oldIndex, 1)[0]
    );
  }
}
