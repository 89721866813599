import { inject, Navigator } from "fw";
import { dispatch } from "fw-state";

import { PopoverController } from "service/popover";
import { CurrentUserStore } from "state/current-user";
import {
  CurrentOrganizationStore,
  SetIsLoading
} from "state/current-organization";

import { LogoutAction } from "state/actions";
import { DialogService } from "fw-dialog";
import { Notification } from "service/notification";
import { copy } from "helpers/copy";

@inject
export class UserPopover {
  constructor(
    private nav: Navigator,
    private currentUserStore: CurrentUserStore,
    private currentOrgStore: CurrentOrganizationStore,
    private controller: PopoverController<any>,
    private dialogService: DialogService,
    private notify: Notification,
  ) {}

  private logout() {
    dispatch(new SetIsLoading(true));
    dispatch(new LogoutAction());

    setTimeout(() => {
      if (this.currentOrgStore.state.portalContext == null) {
        this.nav.navigate("/login");
        return;
      }

      const {
        OrganizationPortal: { AllowLocalLogin },
        IdentityProviders,
      } = this.currentOrgStore.state.portalContext;

      if (AllowLocalLogin == false && IdentityProviders.length == 1) {
        // we don't want to redirect them to login cause it will just log them back in :)
        this.nav.navigate("/logged-out");
      } else {
        this.nav.navigate("/login");
      }
    }, 100);
  }

  get nonImpersonatedUserAvailable() {
    return (
      this.currentUserStore.state.loggedIn &&
      this.currentUserStore.state.user != null &&
      this.currentUserStore.state.impersonating == false
    );
  }

  get canEditAccount() {
    return (
      !this.currentUserStore.state.impersonating &&
      !this.currentUserStore.state.isGlobalPrincipal &&
      this.currentUserStore.state.user != null
    );
  }

  get isGlobal() {
    return (
      this.nonImpersonatedUserAvailable &&
      this.currentUserStore.state.isGlobalPrincipal
    );
  }

  async openAudits() {
    this.controller.close();

    const { AuditsDialog } = await import("views/global-admin/audits-dialog");
    this.dialogService.open(AuditsDialog);
  }

  navTo(where: string) {
    this.nav.navigate(where);
    this.controller.cancel();
  }

  get organization() {
    return this.currentOrgStore.state.organization;
  }

  get isImpersonating() {
    return this.currentUserStore.state.impersonating;
  }

  async stopImpersonating() {
    this.navTo("stop-impersonating");
  }

  async copyEmailAddress(e: Event) {
    try {
      const target = e.target as Element
      await copy(target.innerHTML)
      this.notify.notify("Email address successfully copied to clipboard");
    } catch (err) {
      console.error("Failed to copy email address: ", err);
    }
  }
}
