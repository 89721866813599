import { ComponentEventBus, inject, prop } from "fw";
import { ApplicationRestriction, getFormName } from "models/role";
import { FeatureFlagService } from "service/feature-flag";
import { FormService } from "service/form";

class FormRestrictionNameData {
  type: string;
  form: string;
  section: string;
  question: string;
  isValid: boolean;
}

@inject
export class FormRestriction {
  @prop(null) restriction!: ApplicationRestriction;

  public names: FormRestrictionNameData[] = [];

  // Restriction form updates watcher
  public get restrictionUpdate() {
    this.restrictionChanged()
    return this.restriction ? 'restriction' : 'restriction';
  }

  constructor(
    private ceb: ComponentEventBus,
    private formService: FormService,
    private ffs: FeatureFlagService
  ) { }

  public async attached() {
    await this.restrictionChanged();
  }

  public async restrictionChanged() {
    if (!this.restriction || !this.restriction.Paths || this.restriction.Paths.length === 0) {
      this.names = [];
      return;
    }

    let names: FormRestrictionNameData[] = [];
    for (const path of this.restriction.Paths) {
      const pathResult = await this.formService.getFormPathResult(path);
      if (!pathResult) {
        continue;
      }

      const name: FormRestrictionNameData = { type: null, form: null, section: null, question: null, isValid: false };
      name.isValid = pathResult.IsValid;
      name.type = pathResult.FormTypeName || getFormName(this.restriction.Type);
      name.form = pathResult.FormName || pathResult.FormKey;
      name.section = pathResult.SectionTitle || pathResult.SectionKey;
      name.question = pathResult.QuestionKey;

      names.push(name);
    }

    this.names = names;
  }

  async deletePath(index) {
    this.ceb.dispatch("delete-path", { restriction: this.restriction, index: index });
    await this.restrictionChanged();
  }
}
