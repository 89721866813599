import { inject } from "fw";
import {
  ContactTypeDefinition,
  type ICustomFieldDefinition,
  CustomFieldType,
  isSortable,
  isPathExcluded,
} from "models/contact-organization";
import { FieldError } from "models/contact";
import { ITestScoreContainer } from "models/testscore";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { Category, PathAndLabel } from "views/components/path-chooser";
import { Contact, ContactMetaData, IContactFieldData } from "models/contact";
import { nameof } from "helpers/nameof";
import { keyBy, sumBy } from "lodash-es";
import { DataPolicyService } from "./data-policy";

export interface IFieldContext {
  definition: ICustomFieldDefinition;
  isRestricted: boolean;
  value: unknown;
  path: string;
  meta: IContactFieldData;
  error: FieldError;
}

@inject
export class ContactsFieldPaths {
  constructor(
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private dataPolicy: DataPolicyService
  ) {}

  public getFieldsByCategory(contactType: ContactTypeDefinition = null): Category[] {
    const all: Category = {
      category: "All Contacts",
      paths: [
        {
          Label: "Name",
          Path: "display_name",
          Sort: contactType
            ? contactType.use_full_name
              ? "company"
              : "last first"
            : "name",
          Type: "String",
        },
        {
          Label: "Email",
          Path: nameof<Contact>("email_address"),
          Sort: "email",
          Type: "String",
        },
        { Label: "Tags", Path: "tags", Sort: "tags", Type: "Object" },
        { Label: "Type", Path: "type", Sort: "type", Type: "String" },
        {
          Label: "Engagement Score",
          Path: "engagement_score",
          Sort: "score",
          Type: "String",
        },
      ],
    };

    if (this.currentContactOrganizationStore.state.organization.enable_contact_number_sequencing) {
      all.paths.push({
        Label: "Contact Number",
        Path: "contact_number",
        Sort: "contact_number",
        Type: "String",
      });
    }

    if (contactType) {
      if (contactType.use_full_name === false) {
        all.paths.push(
          {
            Label: "First Name",
            Path: "first_name",
            Sort: "first",
            Type: "String",
          },
          {
            Label: "Last Name",
            Path: "last_name",
            Sort: "last",
            Type: "String",
          },
          {
            Label: "Company Name",
            Path: "company_name",
            Sort: "company",
            Type: "String",
          }
        );
      }
    } else if (
      this.contactTypes.findIndex((ct) => ct.use_full_name === false) >= 0
    ) {
      all.paths.push(
        {
          Label: "First Name",
          Path: "first_name",
          Sort: "first",
          Type: "String",
        },
        { Label: "Last Name", Path: "last_name", Sort: "last", Type: "String" }
      );
    }

    const fieldsByContactType: {
      [key: string]: PathAndLabel[];
    } = this.getFieldsByContactType(contactType);

    if (contactType) {
      const paths: PathAndLabel[] = fieldsByContactType[contactType.key];
      return paths && paths.length > 0
        ? [all, { category: contactType.plural_name, paths: paths }]
        : [all];
    }

    let categories: Category[] = [all];
    this.currentContactOrganizationStore.state.organization.contact_types.forEach(
      (ct) => {
        const paths: PathAndLabel[] = fieldsByContactType[ct.key];
        if (paths && paths.length) {
          categories.push({
            category: ct.plural_name,
            paths: paths,
          });
        }
      }
    );

    return categories;
  }

  private get organization() {
    return this.currentContactOrganizationStore.state.organization;
  }

  private get contactTypes() {
    return this.currentContactOrganizationStore.state.organization
      .contact_types;
  }

  private shouldUseFullName(contact: Contact) {
    const definition = this.contactTypes.find((ct) => ct.key === contact.type);
    return definition ? definition.use_full_name : false;
  }

  private getFieldsByContactType(contactType: ContactTypeDefinition) {
    let fieldsByContactType: { [key: string]: PathAndLabel[] } = {};
    this.currentContactOrganizationStore.state.organization.fields.forEach(
      (f) => {
        if (f.is_system_field || contactType?.key !== f.contact_type) return;

        // excluded types (for now... maybe always)
        if (isPathExcluded(f.type))
          return;

        if (!fieldsByContactType[f.contact_type]) {
          fieldsByContactType[f.contact_type] = [];
        }

        this.addFieldsByType(fieldsByContactType, f);
      }
    );

    return fieldsByContactType;
  }

  private addFieldsByType(
    fieldsByContactType: { [key: string]: PathAndLabel[] },
    definition: ICustomFieldDefinition
  ) {
    let pl: PathAndLabel = {
      Label: definition.display_name,
      Path: definition.name,
      Sort: definition.search_field,
      Type: "String",
    };
    switch (definition.type) {
      case CustomFieldType.address:
        pl.Sort = `${definition.search_field}.country ${definition.search_field}.state ${definition.search_field}.city ${definition.search_field}.postal_code`;
        break;

      case CustomFieldType.link:
        pl.Sort = `${definition.search_field}.name ${definition.search_field}.url`;
        break;

      case CustomFieldType.country:
        pl.Sort = `${definition.search_field}.country`;
        break;
      
      case CustomFieldType.postalcode:
        pl.Sort = `${definition.search_field}.postal_code`;
        break;

      default:
        if (isSortable(definition.type) === false)
          pl.Sort = null;
        break;
    }

    fieldsByContactType[definition.contact_type].push(pl);
  }

  public getSourcesCount(field: IFieldContext) {
    return sumBy(field.meta?.values || [], (v) =>
      v.data_source_key === "system" ? 0 : 1
    );
  }

  public getContactPropertyDefinitions(
    contactType: string
  ): ICustomFieldDefinition[] {
    const propertyDefinitions = this.organization.fields.filter(
      (f) => f.contact_type === contactType
    );

    return propertyDefinitions;
  }

  private filterEmptyProperties(
    properties: ICustomFieldDefinition[],
    contact: Contact
  ) {
    const useFullName = this.shouldUseFullName(contact);
    const populatedProperties = properties.filter((f) => {
      switch (f.name) {
        case "FirstName":
          return !useFullName || !!contact.first_name;
        case "LastName":
          return !useFullName || !!contact.last_name;
        case "CompanyName":
          return useFullName || !!contact.company_name;
        case "EmailAddress":
          return !!contact.email_address;
        case "Tags":
          return contact.tags && contact.tags.length > 0;
        default:
          const data = contact.data[f.name];
          if (data === undefined) {
            return false;
          }

          if (f.type === CustomFieldType.testscore) {
            const container: ITestScoreContainer = data;
            return container.CalculatedScore && container.Scores;
          }

          return true;
      }
    });

    return populatedProperties;
  }

  private getPropertyValueAndPath(
    field: ICustomFieldDefinition,
    contact: Contact
  ) {
    let valueAndPath = [];
    switch (field.name) {
      case "FirstName":
        valueAndPath = [contact.first_name, "/first_name"];
        break;
      case "LastName":
        valueAndPath = [contact.last_name, "/last_name"];
        break;
      case "EmailAddress":
        valueAndPath = [contact.email_address, "/email_address"];
        break;
      case "CompanyName":
        valueAndPath = [contact.company_name, "/company_name"];
        break;
      case "Tags":
        valueAndPath = [contact.tags, "/tags"];
        break;
      default:
        valueAndPath = [contact.data[field.name], `/data/${field.name}`];
        break;
    }
    return valueAndPath;
  }

  public getContactProperties(
    contact: Contact,
    contactMeta: ContactMetaData,
    filterEmtpy: boolean = false
  ): IFieldContext[] {
    if (!contact) {
      return [];
    }

    let propertyDefinitions = this.getContactPropertyDefinitions(contact.type);

    if (filterEmtpy) {
      propertyDefinitions = this.filterEmptyProperties(
        propertyDefinitions,
        contact
      );
    }

    const fieldMetaHash = keyBy(contactMeta.fields, (f) => f.field_id);
    const errors = keyBy(contact.field_errors, (fe) => fe.field);
    const properties = propertyDefinitions.map((field) => {
      const valueAndPath = this.getPropertyValueAndPath(field, contact);
      const isRestricted = this.dataPolicy.isContactFieldRestricted(
        contact.type,
        field.id
      );

      return {
        definition: field,
        meta: fieldMetaHash[field.id],
        error: errors[field.name],
        value: valueAndPath[0],
        path: valueAndPath[1],
        isRestricted: isRestricted,
      };
    });

    return properties;
  }

  public getContactProperty(
    field: ICustomFieldDefinition,
    contact: Contact,
    contactMeta: ContactMetaData
  ): IFieldContext {
    const meta = contactMeta.fields.find((f) => f.field_id === field.id);
    const error = contact.field_errors.find((f) => f.field === field.name);
    const valueAndPath = this.getPropertyValueAndPath(field, contact);
    const isRestricted = this.dataPolicy.isContactFieldRestricted(
      contact.type,
      field.id
    );

    return {
      definition: field,
      meta: meta,
      error: error,
      value: valueAndPath[0],
      path: valueAndPath[1],
      isRestricted: isRestricted,
    };
  }
}
