import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { ATS, IListResults } from "./ats";

import {
  CalendarEvent,
  UpdateCalendarEventModel,
  getSeriesAndInstanceFromId,
} from "models/calendar-event";

export interface CalendarEventSelection {
  calendar_event_series_id: string;
  instance_key: string;
  calendar_event_type_id: string;
}

@inject
export class CalendarEventRepository {
  constructor(private network: ATS) { }

  async list(
    from: string | Date = null,
    to: string | Date = null,
    timezone: string = null,
    f: string = null,
    q: string = null,
    sort: string = null,
    page: number = 1,
    limit: number = 10
  ): Promise<IListResults<CalendarEvent>> {
    from = from instanceof Date ? from.toISOString() : from;
    to = to instanceof Date ? to.toISOString() : to;
    const params: any = {
      from,
      to,
      timezone,
      page,
      limit: limit == null ? 10 : limit
    };

    if (sort != null) params.sort = sort;
    if (f != null) params.f = f;
    if (q != null) params.q = q;

    const res = await this.network.contacts.get<IListResults<CalendarEvent>>("calendar-events", params);

    const results: IListResults<CalendarEvent> = {
      aggregations: res.body.aggregations,
      results: createFromArray(CalendarEvent, res.body.results),
      total: parseInt(res.headers["x-result-count"], 10)
    };
    return results;
  }

  async getById(calendarEventId: string) {
    const { calendarEventSeriesId, instanceKey } = getSeriesAndInstanceFromId(calendarEventId);
    return await this.get(calendarEventSeriesId, instanceKey);
  }

  async get(seriesId: string, instanceKey: string) {
    const path = `calendar-events/${seriesId}/${instanceKey}`;
    const res = await this.network.contacts.get<CalendarEvent>(path);
    return createFrom(CalendarEvent, res.body);
  }

  async listByIds(ids: string[], organizationId: string): Promise<CalendarEvent[]> {
    const res = await this.network.contacts.post<CalendarEvent[]>("calendar-events/list", ids, {
      organizationId: organizationId
    });

    return createFromArray(CalendarEvent, res.body);
  }

  async put(event: UpdateCalendarEventModel) {
    const path = `calendar-events/${event.calendar_event_series_id}/${event.instance_key}`;
    const res = await this.network.contacts.put<CalendarEvent>(path, event);
    return createFrom(CalendarEvent, res.body);
  }

  async delete(seriesId: string, instanceKey: string) {
    const path = `calendar-events/${seriesId}/${instanceKey}`;
    await this.network.contacts.delete(path);
  }

  public async deleteSelection(selections: CalendarEventSelection[]) {
    if (selections == null || selections.length === 0) {
      return;
    }
    await this.network.contacts.post<void>("calendar-events/delete", selections);
  }

  public async getEventDatesList(
    from: string | Date = null,
    to: string | Date = null,
    timezone: string = null
  ) {
    from = from instanceof Date ? from.toISOString() : from;
    to = to instanceof Date ? to.toISOString() : to;

    const params: any = {};
    if (from != null) params.from = from;
    if (to != null) params.to = to;
    if (timezone != null) params.timezone = timezone;

    const res = await this.network.contacts.get<string[]>("calendar-events/calendar-dates", params);
    return res.body;
  }

  async getTags() {
    const res = await this.network.contacts.get<string[]>("calendar-events/tags");
    return res.body;
  }

}
