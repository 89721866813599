import { inject } from "fw";
import { ApplicationSettingsStore } from "state/application-settings";

import { AggregateCalculatedField } from "models/calculated-field";
import { ApplicationProperty } from "models/application-settings";
import { EvaluationPhasesStore } from "state/evaluation-phases";
import { FormStore } from "state/forms";
import { Form, Question, QuestionType } from "models/form";
import { DataDictionaryField } from "models/data-dictionary";
import { StepGroup } from "views/settings/program/step-group";

type NoResult = {
  kind: "none";
};

export type ValuePrimitiveType = null | "string" | "number" | "array" | "boolean";

type FormPathResult = {
  kind: "form";
  formKey: string;
  key: string;
  subKeys: string[];
  question: Question;
  primitiveType: ValuePrimitiveType;
};

type TagPathResult = {
  kind: "tag";
}

type PhasePathResult = {
  kind: "phase";
}

type StagePathResult = {
  kind: "stage";
}

type DecisionPathResult = {
  kind: "decision";
}


type UserAssignmentPathResult = {
  kind: "user-assignment";
  phase: string;
}

type UserEvaluatedPathResult = {
  kind: "user-evaluated";
  phase: string;
}

type PhaseCalculatedValueResult = {
  kind: "phase-calculated";
  phase: string;
  field: AggregateCalculatedField;
}

type TeamAssignmentPathResult = {
  kind: "team-assignment";
  phase: string;
}

type ApplicationPropertyPathResult = {
  kind: "application";
  key: string;
  subKeys: string[];
  property: ApplicationProperty;
}

type ApplicationMeta = {
  kind: "application-meta";
  type: "date-started" | "date-submitted";
}

type ApplicantIdentityPathResult = {
  kind: "applicant-identity";
  identifier: string;
}

type ApplicantPropertyPathResult = {
  kind: "applicant-property";
  key: string;
}

export type ContactPathResult = {
  kind: "contact";
  key: string;
  field: DataDictionaryField;
}

export type ProgramPathResult = {
  kind: "program";
  key: string;
}

export type StepGroupPathResult = {
  kind: "step-group";
  key: string;
  field: string;
}

export type PathResult = NoResult
  | FormPathResult
  | ApplicationPropertyPathResult
  | ApplicationMeta
  | UserAssignmentPathResult
  | TeamAssignmentPathResult
  | UserEvaluatedPathResult
  | PhasePathResult
  | PhaseCalculatedValueResult
  | StagePathResult
  | DecisionPathResult
  | TagPathResult
  | ApplicantIdentityPathResult
  | ApplicantPropertyPathResult
  | ContactPathResult
  | ProgramPathResult
  | StepGroupPathResult;

@inject
export class PathInspector {
  constructor(
    private applicationSettingsStore: ApplicationSettingsStore,
    private phasesStore: EvaluationPhasesStore,
    private formsStore: FormStore,
  ) { }

  inspect(path: string): PathResult {
    if (path == null || path.trim().length == 0) {
      return { kind: "none" };
    }

    const [firstPath, secondPath, thirdPath, ...others] = path.split(".");

    switch (firstPath) {
      case "forms":
        //try to get the question
        let question: Question = null;
        let pt: ValuePrimitiveType = null;

        const form = this.formsStore.state.forms.find(a => a.Key == secondPath);
        if (form != null) {
          // do we need to look at section key for the thirdPath?
          question = this.findQuestion(form, thirdPath);
          if (question) {
            switch (question.Type) {
              case QuestionType.URL:
              case QuestionType.LongText:
              case QuestionType.DropDown:
              case QuestionType.ShortText:
              case QuestionType.PhoneNumber:
              case QuestionType.RadioButton:
              case QuestionType.EmailAddress:
                pt = "string";
                break;

              case QuestionType.Number:
                pt = "number";
                break;

              case QuestionType.CheckBoxList:
                pt = "array";
                break;
            }
          }
        }

        return {
          kind: "form",
          formKey: secondPath,
          key: thirdPath,
          subKeys: others,
          question,
          primitiveType: pt,
        };

      case "phases":
        switch (thirdPath) {
          case "assigned_users":
            return {
              kind: "user-assignment",
              phase: secondPath,
            };

          case "evals_completed_by":
            return {
              kind: "user-evaluated",
              phase: secondPath,
            };

          case "assigned_team_id":
            return {
              kind: "team-assignment",
              phase: secondPath,
            };

          default:
            const phase = this.phasesStore.state.phases.find(p => p.Key == secondPath);
            if (phase == null) return null;
            const calc = phase.CalculatedFields.find(c => c.Key == thirdPath);
            if (calc == null) return null;

            return {
              kind: "phase-calculated",
              field: calc,
              phase: phase.Key,
            };
        }
        break;

      case "tags":
        return {
          kind: "tag",
        };

      case "program": {
        return {
          kind: "program",
          key: secondPath,
        };
      }

      case "stage":
        return {
          kind: "stage",
        };

      case "decision":
        return {
          kind: "decision",
        };
      case "phase":
        return {
          kind: "phase",
        };

      case "application":
        if (secondPath == "metaData") {
          switch (thirdPath) {
            case "dateStartedUTC":
              return {
                kind: "application-meta",
                type: "date-started",
              };

            case "dateSubmittedUTC":
              return {
                kind: "application-meta",
                type: "date-submitted",
              };
          }
        }

      case "applicant": {
        switch (secondPath) {
          case "name":
          case "emailAddress":
          case "givenName":
          case "familyName":
            return {
              kind: "applicant-identity",
              identifier: secondPath,
            };
          default:
            return {
              kind: "applicant-property",
              key: secondPath,
            };
        }
      }

      case "stepGroups": {
        return {
          kind: "step-group",
          key: `${firstPath}.${secondPath}`,
          field: thirdPath,
        };
      }
    }

    for (const appProp of this.applicationSettingsStore.state.applicationSettings.ApplicationProperties) {
      if (appProp.Key == firstPath) {
        return {
          kind: "application",
          key: firstPath,
          subKeys: [secondPath],
          property: appProp,
        };
      } else if (appProp.Key == secondPath && firstPath == "applicant") {
        return {
          kind: "application",
          key: secondPath,
          subKeys: [thirdPath],
          property: appProp,
        };
      }
    }

    return { kind: "none" };
  }

  findQuestion(form:Form, questionKey:string):Question
  {
    for (const section of form.Sections) {
      let question = section.Questions.find(q => q.Key == questionKey);
      if (question) return question;
    }
    return null;
  }
}
