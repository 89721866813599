import { IListResults } from "network/ats";
import type { ICustomFieldDefinition } from './contact-organization';
import { MarketingOutreachArchiveType } from './marketing-outreach-archive-content';

export interface PersistentContactData extends ContactData {
  id: string;
  contact_number: string;
  organization_id: string;
  parent_contact_id: string;
  display_name: string;
  profile_image_url?: string;
  engagement_score: number;
  created_by_data_source_instance_name: string;
  data_source_contributors: IDataSourceContributorData[];
}

export class UnrestrictedContact {
  id?: string;
  type?: string;
  first_name?: string;
  last_name?: string;
  company_name?: string;
  display_name?: string;
  email_address?: string;
  profile_image_url?: string;

  get displayName() {
    return this.display_name;
  }
}

// TODO:  ugh... nullables are problematic now that they don't have to be included in the implements
export interface ContactData {
  type?: string;
  profile_image_path?: string;
  profile_initials?: string;
  profile_color?: string;
  first_name?: string;
  last_name?: string;
  company_name?: string;
  email_address?: string;
  tags?: string[];
  attachments?: IAttachmentData[];

  version?: string;

  data?: { [key: string]: any };
}

export interface FieldError {
  field: string;
  message: string;
}

export interface IContactEventRegistraction {
  registered: string[];
  cancelled: string[];
  attended: string[];
  wait_listed: string[];
}

export interface IContactMessage {
  campaign_id: string,
  track_id: string,
  outreach_id: string,
  outreach_type: 'applications' | 'campaign' | 'conversation' | 'direct' | 'event' | 'quick' | 'system',
  archive_id: string,
  outreach_name: string,
  message_type: MarketingOutreachArchiveType,
  status: 'in-queue' | 'sending' | 'sent' | 'error',
  description: string,
  subject: string,
  sent_utc: string,
  opened_utc: string
  clicked_utc: string
  created_utc: string,
  updated_utc: string,
  data: { [key: string]: any },
  is_quick_message: boolean,
  deliver_to?: string,
  conversation_id: string,
  application_id?: string,
  resends: IContactMessageResend[],
}

export interface IContactMessageResend {
  unique_hash: string,
  outreach_sent_id: string,
  archive_id: string,
  deliver_to: string,
  sent_utc: string,
}

export class Contact implements PersistentContactData {
  id: string = null;
  type: string = null;
  contact_number: string = null;
  organization_id: string = null;
  parent_contact_id: string = null;
  display_name: string = null;
  profile_image_url: string = null;
  profile_initials: string = null;
  profile_color: string = null;
  engagement_score: number = null;
  salutation: string = null;
  first_name: string = null;
  last_name: string = null;
  company_name: string = null;
  company_position: string = null;
  notes: string = null;
  created_by_data_source_instance_name: string = null;
  data_source_contributors: IDataSourceContributorData[] = [];
  email_address: string = null;
  default_email_address: string = null;
  geo_set: IGeoData[] = [];
  attachments: IAttachmentData[] = [];
  event_registrations: IContactEventRegistraction = null;
  tags: string[] = [];
  data: { [key: string]: any } = null;
  created_utc: Date = null;
  updated_utc: Date = null;
  is_deleted: boolean = false;
  version: string = null;
  field_errors: FieldError[] = [];
  field_error_count: number;
  messages: IContactMessage[] = [];

  // TODO(derek): when updating aurelia, use declarePropertyDependencies, should use it for all getters.., and then when TS updates to have annotations, use those!
  // a computed column so that we can get live updates in the ui
  get displayName() {
    if (this.display_name)
      return this.display_name;
    const hasFirst = this.first_name != null && this.first_name.length > 0;
    //const hasMiddle = this.middle_name != null && this.middle_name.length > 0;
    const hasLast = this.last_name != null && this.last_name.length > 0;

    if (hasFirst || hasLast) {
      return `${this.first_name || ""} ${this.last_name || ""}`;
    }

    const email = this.email_address || this.default_email_address;
    return this.company_name || (email?.length == 0 ? null : email) || "";
  }

  get Id() {
    return this.id;
  }

  addTag(tag: string) {
    if (this.tags.indexOf(tag) == -1) this.tags.push(tag);
  }

  unTag(tag: string) {
    let idx = this.tags.indexOf(tag);

    if (idx == -1) return;
    this.tags.splice(idx, 1);
  }
}

export class ContactMetaData {
  id: string = null;
  contact_id: string = null;
  organization_id: string = null;
  created_by_data_source_id: string = null;
  created_by_data_source_instance_id: string = null;
  last_activity_utc: Date = null;
  data_source_contributors: IDataSourceContributorData[] = [];
  fields: IContactFieldData[] = [];
  created_utc: Date = null;
  updated_utc: Date = null;
  version: string = null;
}

export interface ContactInfo {
  contact_id: string;
  data: object;
  organization_id: string;
  display_name: string;
  phoneNumber: string;
  email?: string;
}

export interface IContactFieldData {
  field_id: string;
  field_name: string;
  pinned_to_data_source_instance: string;
  values: IContactFieldValueData[];
  created_utc: Date;
  updated_utc: Date;
  is_deleted: boolean;
}

export interface IContactFieldValueData {
  id: string;
  data_source_id: string;
  data_source_key: string;
  data_source_instance_id: string;
  identifier: string;
  value: any;
  resolved_value: IResolvedValueData;
  is_manually_selected: boolean;
  is_primary_value: boolean;
  is_primary_identity?: boolean; // Undefined unless there's more than one value per DataSourceInstanceId
  created_utc: Date;
  updated_utc: Date;
  is_deleted: boolean;
}

export interface IResolvedValueData {
  value: any;
  display_value: string;
  value_id: any;
  is_valid: boolean;
  validation_message: string;
  idx_value: any;
  error_message: string;
  // exception: any;
  has_organization_changed: boolean;
}

export enum EmailType {
  Email = "email",
  Personal = "personal",
  Work = "work"
}

export interface ILinkInfo {
  name?: string;
  url: string
}

export interface IGeoData {
  geo?: string;
  full_address?: string;
  geo_locality?: string;
  geo_level1?: string;
  latitude?: number;
  longitude?: number;
}

export interface IDataSourceContributorData {
  data_source_id: string;
  data_source_key: string;
  instances: IDataSourceInstanceContributorData[];
  created_utc: Date;
  updated_utc: Date;
  touch_count: number;
}

export interface IDataSourceInstanceContributorData {
  data_source_instance_id: string;
  data_source_instance_name: string;
  link: string;
  created_utc: Date;
  updated_utc: Date;
  touch_count: number;
}

export interface IAddressData {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
  geo?: string;
  geo_hash?: string;
  normalized_geo_hash?: string;
  geo_country?: string;
  geo_level1?: string;
  geo_level2?: string;
  geo_locality?: string;
  latitude?: number;
  longitude?: number;
  full_address?: string;
}

export interface IAttachmentData {
  id?: string;
  key?: string;
  url?: string;
  file_name?: string;
  mime_type?: string;
  size?: number;
  container?: string;
  writeable?: boolean;
  path?: string;
  created_utc?: Date;
  data?: { [key: string]: any };
}

export interface IFieldAttachmentData {
  display_name?: string
  path?: string;
  created_utc?: Date;
  updated_utc?: Date;
}

export interface IRelationship {
  id?: string,
  display_name?: string
}

export interface IContactRelationshipGroupModel {
  loading: boolean;
  primary: boolean;
  type: string;
  display_name: string;
  field: ICustomFieldDefinition;
  contacts: IListResults<Contact> | IListResults<UnrestrictedContact> | IListResults<IRelationship>;
}

export interface IUserRelationshipGroupModel {
  loading: boolean;
  type: string;
  display_name: string;
  field: ICustomFieldDefinition;
  isManuallySelected: boolean;
  userId: string;
}

export interface IFieldWarning {
  show: boolean;
  title: string;
  type: "warning" | "error";
}

export interface ISlideRoomApplication {
  application: string;
  organization: string;
  program: string;
  source: string;
  status: string;
  submitted: string;
}

export class SelectCustomFieldOption {
  name: string = null;
  aliases: string[] = [];
  _selected: boolean = false;
  _showAliases: boolean = false;

  constructor(name: string = null, aliases: string[] = [], selected: boolean = false) {
    this.name = name;
    this.aliases = aliases;
    this._selected = selected;
  }
}

export enum OptionValueChangeType {
  Unknown = 0,
  ReplaceValue = 1,
  RemoveValue = 2,
  AliasToOption = 3,
  OptionToAlias = 4,
  AliasToAlias = 5
}

export interface IOptionValueChange {
  change_type: OptionValueChangeType;
  original_value: string;
  current_value: string;
}

export interface IFieldOptionChanges {
  organization_id: string;
  field_id: string;
  changes: IOptionValueChange[];
}

export interface IAttachmentPolicyResult {
  policy: string;
  signature: string;
}

export class DecryptedValue {
  public value: string = null;
}

export interface IDuplicateContactResult {
  field: string;
  matching_key: string;
  display_name: string;
  confidence: number;
  total: number;
}

export class DuplicateContactResult implements IDuplicateContactResult {
  field: string;
  matching_key: string;
  display_name: string;
  confidence: number;
  total: number;

  constructor(duplicate: IDuplicateContactResult, selected: boolean = false) {
    if (!duplicate)
      return;

    this.field = duplicate.field;
    this.matching_key = duplicate.matching_key;
    this.display_name = duplicate.display_name;
    this.confidence = duplicate.confidence;
    this.total = duplicate.total;
  }
}

export class ActivityActor {
  public id: string = null;
  public name: string = null;
  public type: string = null;
}

export class Activity {
  id: string;
  organization_id: string;

  contact_id: string;
  contact_display_name: string;
  profile_image_url: string;

  business_value: number;
  monetary_value: number;
  data_source_id: string;
  data_source_instance_id: string;
  data_source_instance_name: string;
  type: string;
  data_source_icon_url: string;
  data_source_name: string;
  data_source_key: string;
  title?: string;
  description: string;
  created_utc: Date;
  data: { [key: string]: any; };

  display_name: string;

  actor: ActivityActor;

  user_id: string;
  user_full_name: string;
  user_profile_image_url: string;

  public get sourceName(): string {
    return this.user_full_name || this.data_source_name || "Unnamed Source";
  }

  public get hasWorkflowActor() {
    return this.actor && this.actor.type === "AdmissionsWorkflow";
  }
}
