import { ContainerInstance } from "fw";
import { FormForType, formFor, Validators } from "fw-model";

import { isValidDomain, isValidSendingEmail } from "forms/validators";
import { MarketingClient } from "models/marketing-client";
import { FeatureFlagService } from "service/feature-flag";

export type FormForMarketingClient = FormForType<MarketingClient>;
const { wrap, isEmail, isUrl } = Validators;

export class UpdateMarketingClientAction {
  constructor(public form: FormForMarketingClient) {}
}

let dmarcSendingDomainFF: boolean = false;
const isDmarcSendingDomainFF = (): boolean => {
  if (!!dmarcSendingDomainFF) return true;
  dmarcSendingDomainFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("MarketingSendingDomainsDmarcOUT2435Feb2024");
  return dmarcSendingDomainFF;
}

export const updateMarketingClientFormCreator = formFor(MarketingClient, s => {
  s.requiredField(f => f.Id, "Id");
  s.requiredField(f => f.AtsOrgId, "AtsOrgId");
  if (isDmarcSendingDomainFF()) {
    s.requiredField(f => f.SendingDomain, "Sending Domain", wrap(isValidDomain));
    s.requiredField(f => f.SendingEmailAddress, "Sending Email Address", wrap(isValidSendingEmail));
    s.requiredField(f => f.SendingLabel, "Sending Email Label");
    s.requiredField(f => f.MainEmailAddress, "Main Email Address", wrap(isValidSendingEmail));
    s.requiredField(f => f.MainLabel, "Main Email Label");
  } else {
    s.field(f => f.SendingDomain, "Sending Domain", wrap(isValidDomain));
    s.field(f => f.SendingEmailAddress, "Sending Email Address", wrap(isEmail));
    s.field(f => f.SendingLabel, "Sending Email Label");
    s.field(f => f.MainEmailAddress, "Main Email Address", wrap(isEmail));
    s.field(f => f.MainLabel, "Main Email Label");
  }
  s.field(f => f.SendingPhoneNumber, "Sending Phone Number");
  s.field(f => f.MailingAddress, "Mailing Address");
  s.field(f => f.WebsiteAddress, "Website Address", wrap(isUrl({ allowedProtocols: ["http", "https"], requireProtocol: true, allowPath: true })));
  s.field(f => f.MainPhoneNumber, "Main Phone Number");
  s.field(f => f.PrivacyPolicyLink, "Privacy Policy Link", wrap(isUrl({ allowedProtocols: ["http", "https"], requireProtocol: true, allowPath: true })));
  s.field(f => f.AutoAssign, "Auto Assign");
});
