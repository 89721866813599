import { ComponentEventBus, inject, prop } from "fw";
import { ApplicationRestriction } from "models/role";

@inject
export class ApplicantIdentityRestriction {
  @prop(null) restriction!: ApplicationRestriction;
  
  constructor(
    private ceb: ComponentEventBus
  ) { }

  deletePath(index) {
    this.ceb.dispatch("delete-path", { restriction: this.restriction, index: index });
  }

}
