import { prop, ComponentEventBus, inject } from "fw";

@inject
export class ActionBar {
  @prop(null) public value!: string[];
  @prop(null) public totalSelected!: number;
  @prop(false) public reversed!: boolean;

  constructor(private ceb: ComponentEventBus) { }

  public unCheckAll() {
    this.ceb.updateModel([]);
    this.ceb.dispatch("unchecked");
  }

  public get total(): number {
    if (this.totalSelected > 0) {
      return this.totalSelected;
    }

    return this.value && this.value.length;
  }
}
