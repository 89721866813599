import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { createFrom } from "fw-model";

import { debounce } from "lodash-es";
import moment from "moment";

import { GroupFilter } from "models/filter-setup";
import { CalendarEvent } from "models/calendar-event";
import { CalendarEventRepository, CalendarEventSelection } from "network/calendar-event-repository";
import { StartAction, LogoutAction } from "./actions";
import { CalendarEventSeriesUpdatedAction } from "./current-calendar-event";
import { ContactRegisteredAction, ContactRegistrationStatusUpdatedAction } from "./current-calendar-event-registrations";

import {
  EntityChanged,
  WebSocketMessageAction,
  filterWebsocketMessage
} from './filter-websocket-message';

const DEFAULT_PAGE_SIZE = 20;
const MAX_SKIP = 2000;

interface CalendarEventShape {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  events: CalendarEvent[];
  selectedIds: string[];
  dates: string[];
  totalCount: number;
  from: string;
  to: string;
  timezone: string;
  filter: GroupFilter;
  search: string;
  sort: string;

  pageSize: number;
  currentPage: number;
  lastPage: number;
  pageStartNumber: number;
  pageEndNumber: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  canAdvancePage: boolean;
}

export class CalendarEventsDeleteAction {
  constructor(public ids: string[]) { }
}

export class CalendarEventsFetchAction {
  constructor(public refresh: boolean = false) { }
}

export class CalendarEventsUpdateDateRangeAction {
  constructor(public from: string, public to: string) { }
}

export class CalendarEventsGetEventDatesAction { }

export class CalendarEventsSetFilterAction {
  constructor(public filter: GroupFilter, public clearOthers = false) { }
}

export class CalendarEventsSetSearchTerm {
  constructor(public searchTerm: string) { }
}

export class CalendarEventsNextPage { }

export class CalendarEventsPreviousPage { }

export class CalendarEventIdsSelectedAction {
  constructor(public ids: string[]) { }
}

@inject
export class CalendarEventStore extends Store<CalendarEventShape>  {
  private debouncedLoadDates = async () => { };
  constructor(private calendarEventRepo: CalendarEventRepository) {
    super();
    this.debouncedLoadDates = debounce(() => this.loadDates(), 2000, { maxWait: 5000 });
  }

  defaultState() {
    const emptyFilterContainer = createFrom(GroupFilter, {
      operation: "AND",
      filters: [],
    });

    return {
      loaded: false,
      loading: false,
      error: false,
      events: [] as Array<CalendarEvent>,
      dates: [] as Array<string>,
      totalCount: 0,
      from: null,
      to: null,
      timezone: null,
      filter: emptyFilterContainer,
      search: "",
      sort: "date",
      selectedIds: [],
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      lastPage: 1,
      pageStartNumber: 1,
      pageEndNumber: DEFAULT_PAGE_SIZE,
      hasPreviousPage: false,
      hasNextPage: false,
      canAdvancePage: false
    };
  }

  @handle(WebSocketMessageAction, filterWebsocketMessage("EntityChanged"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    const { loaded } = this.state;
    if (!loaded)
      return;

    switch (action.data.type) {
      case "CalendarEvent":
      case "CalendarEventSeries":
        await this.debouncedLoadDates();
        break;
    }
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    const state = this.defaultState();
    state.timezone = action.context.Organization.Timezone;
    state.from = moment().startOf("month").format("YYYY-MM-DD hh:mm");
    state.to = moment().endOf("month").format("YYYY-MM-DD hh:mm");
    this.setState(() => state);
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(state => this.defaultState());
  }

  @handle(CalendarEventsFetchAction)
  private async handleFetchCalendarEvents(action: CalendarEventsFetchAction) {
    if (this.state.loading)
      return;

    if (this.state.loaded && !action.refresh)
      return;

    await this.loadEvents();
  }

  @handle(ContactRegisteredAction)
  private async handleContactRegistered(action: ContactRegisteredAction) {
    if (!action.registrationResponse) return;
    
    const { calendar_event_series_id, instance_key } = action.registrationResponse;
    const { events } = this.state;

    const calendarEventId = `${instance_key}_${calendar_event_series_id}`;

    if (events.findIndex(e => e.id === calendarEventId) !== -1) {
      await dispatch(new CalendarEventsFetchAction(true));
    }
  }

  @handle(ContactRegistrationStatusUpdatedAction)
  private async handleContactRegistrationStatusUpdated(action: ContactRegistrationStatusUpdatedAction) {
    if (!action.updated) return;
    
    const { calendar_event_id } = action.updated;
    const { events } = this.state;

    if (events.findIndex(e => e.id === calendar_event_id) !== -1) {
      await dispatch(new CalendarEventsFetchAction(true));
    }
  }

  @handle(CalendarEventsUpdateDateRangeAction)
  private async handleCalendarEventsSetDateRange(action: CalendarEventsUpdateDateRangeAction) {
    if (!action.from || !action.to)
      return;

    const { from, to } = this.state;
    if (from == action.from && to == action.to)
      return;

    this.setState(state => ({
      ...state,
      events: [],
      currentPage: 1,
      from: action.from,
      to: action.to
    }));

    await this.loadEvents();
    this.loadDates();
  }

  @handle(CalendarEventsGetEventDatesAction)
  private async handleCalendarEventsGetEventDatesAction(action: CalendarEventsGetEventDatesAction) {
    await this.loadDates();
  }

  async loadDates() {
    const { from, to, timezone } = this.state;

    const fromDate = moment(from).startOf("month").subtract(2, "month").toISOString();
    const toDate = moment(to).endOf("month").add(6, "month").toISOString();

    const res = await this.calendarEventRepo.getEventDatesList(fromDate, toDate, timezone);

    this.setState(state => ({
      ...state,
      dates: res
    }));
  }

  @handle(CalendarEventsSetFilterAction)
  private async handleCalendarEventsSetFilterAction(action: CalendarEventsSetFilterAction) {
    this.setState(state => ({
      ...state,
      currentPage: 1,
      filter: action.filter,
      search: action.clearOthers ? "" : state.search
    }));

    await this.loadEvents();
  }

  @handle(CalendarEventsSetSearchTerm)
  private async handleCalendarEventsSetSearchTerm(action: CalendarEventsSetSearchTerm) {
    this.setState(state => ({
      ...state,
      currentPage: 1,
      search: action.searchTerm
    }));

    await this.loadEvents();
  }

  @handle(CalendarEventsNextPage)
  private async handleCalendarEventsNextPage() {
    const currentPage = this.state.currentPage + 1;

    this.setState(state => ({
      ...state,
      currentPage
    }));

    await this.loadEvents();
  }

  @handle(CalendarEventsPreviousPage)
  private async handleCalendarEventsPreviousPage() {
    let currentPage = this.state.currentPage - 1;
    if (currentPage < 0) currentPage = 0;

    this.setState(state => ({
      ...state,
      currentPage
    }));

    await this.loadEvents();
  }

  @handle(CalendarEventSeriesUpdatedAction)
  private async handleUpdateCalendarEventSeries(action: CalendarEventSeriesUpdatedAction) {
    const updated = this.state.events.find(
      t => t.id == action.updated.id,
    );
    if (updated == null) return;

    Object.assign(updated, action.updated);

    this.setState(s => s);
  }

  @handle(CalendarEventsDeleteAction)
  private async handleDeleteCalendarEvents(action: CalendarEventsDeleteAction) {
    const idsArr: string[] = Array.isArray(action.ids) ? action.ids : [action.ids];
    const items = this.state.events.filter(i => idsArr.indexOf(i.id) !== -1);
    if (items.length !== idsArr.length) return;

    try {
      const selections: CalendarEventSelection[] = [];
      for (let i of items) {
        selections.push({
          calendar_event_series_id: i.calendar_event_series_id,
          calendar_event_type_id: i.calendar_event_type_id,
          instance_key: i.instance_key
        });
      }
      if (selections.length > 0) {
        await this.calendarEventRepo.deleteSelection(selections);
      }
    } catch (e) {
      console.error(e);
      let msg = "Encountered an error trying to delete.";
      if (e.result?.message?.length) {
        msg = e.result.message;
      }
      throw new Error(msg);
    }
    finally {
      await this.loadEvents();
    }
  }

  @handle(CalendarEventIdsSelectedAction)
  handleUserTaskIdsSelectedAction(action: CalendarEventIdsSelectedAction) {
    this.setState(state => ({
      ...state,
      selectedIds: action.ids
    }));
  }

  private async loadEvents() {
    if (this.state.loading) return;

    const { from, to, timezone, filter, search, sort, pageSize } = this.state;

    let { currentPage } = this.state;

    this.setState(state => ({
      ...state,
      loading: true
    }));

    try {
      const res = await this.calendarEventRepo.list(
        from, to, timezone, filter.toFilterString(), search, sort, currentPage, pageSize
      );
      const totalCount = res.total;

      const lastPage = Math.ceil(totalCount / pageSize);
      currentPage = Math.min(currentPage, lastPage);

      const pageStartNumber = ((currentPage - 1) * pageSize) + 1;
      const pageEndNumber = pageStartNumber + (pageSize - 1);
      const hasNextPage = pageEndNumber < totalCount;
      const hasPreviousPage = pageStartNumber > 1;
      const canAdvancePage = pageEndNumber + 1 < MAX_SKIP;

      this.setState(state => ({
        ...state,
        loaded: true,
        loading: false,
        error: false,
        events: res.results,
        totalCount,
        currentPage,
        pageStartNumber: Math.max(pageStartNumber, 0),
        pageEndNumber: Math.min(pageEndNumber, totalCount),
        hasNextPage,
        hasPreviousPage,
        canAdvancePage,
        lastPage
      }));
    } catch {
      this.setState(state => ({
        ...state,
        loaded: true,
        loading: false,
        error: true
      }));
    }
  }
}
